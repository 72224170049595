import React from 'react';
import { renderReactComponent } from '../utils/template.utils';
import ProductIntegrationMaintenanceBanner from './ProductIntegrationMaintenanceBanner';
import { getChannelSetting } from '../utils/conf.utils';
import { l } from '../i18n/l';

const BANNER_OFFSET = 48;
const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

export default class ProductIntegrationBannerContainer {
	constructor() {
		return this;
	}

	load() {
		if (!getChannelSetting('integrationConfigurationReadOnly') && !getChannelSetting('productReadOnly')) {
			return;
		}
		const impactedPathnames = [
			'/cms/create',
			'/cms/editApp',
			'/cms/products',
			'/channel/editApp',
			'/channel/integration',
			'/channel/products',
			'/superuser/editApp',
			'/superuser/support'];
		if (impactedPathnames.map(impactedPathname => window.location.pathname.startsWith(impactedPathname)).filter(yes => yes).length === 0) {
			return;
		}
		this.render();
	}

	render() {
		const nodes = document.getElementsByClassName('ProductIntegrationMaintenanceBanner');
		const maintenanceContainer = document.createElement('div');
		maintenanceContainer.setAttribute('class', 'product-integration-maintenance-banner-container');

		const body = document.getElementsByTagName('body')[0];
		const universalHeader = document.getElementById('universalHeader') ||
			document.getElementById('header') ||
			(document.getElementsByTagName('header') || [])[0];
		const root = universalHeader || body;
		const fixedElements = [...root.getElementsByTagName('*')].filter(
			el => getComputedStyle(el, null).getPropertyValue('position') === 'fixed' && el.offsetTop <= BANNER_OFFSET
		);
		if (fixedElements.length) {
			const [topFixedElement] = fixedElements;
			const { parentElement } = topFixedElement;
			if (!nodes[0]) {
				fixedElements.forEach((el) => { el.style.top = `${el.offsetTop + BANNER_OFFSET}px`; });
				parentElement.style.height = `${isIE11 ? topFixedElement.offsetTop : topFixedElement.scrollHeight}px`;
				parentElement.style.position = 'relative';
			}
		}

		body.insertBefore(maintenanceContainer, body.firstChild);

		while (nodes[0]) {
			nodes[0].remove();
		}

		renderReactComponent('.product-integration-maintenance-banner-container', <ProductIntegrationMaintenanceBanner {...{ l: window.l || l }} />);
	}
}
