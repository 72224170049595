import React from 'react';
import Cookies from 'js-cookie';
import { renderInPlaceOfNode } from '../utils/template.utils';
import { isMarketplacePage } from '../utils/base.utils';
import MobileBanner from './MobileBanner';
import { l } from '../i18n/l';

export default class MobileBannerContainer {
	constructor() {
		return this;
	}

	verifyDesktopCookie() {
		return Cookies.get('AppDirect-Desktop-Version') === '1';
	}

	load() {
		if (this.verifyDesktopCookie() && isMarketplacePage()) {
			this.render();
		}
	}
	render() {
		const bannerContainer = document.createElement('div');
		const body = document.getElementsByTagName('body')[0];
		bannerContainer.setAttribute('class', 'ad-mobile-banner-desktop');
		body.insertBefore(bannerContainer, body.firstChild);
		[document.querySelectorAll('.ad-mobile-banner-desktop')].forEach((node) => {
			renderInPlaceOfNode(
				<MobileBanner
					label={l('return.to.mobile.website')}
				/>, node[0]);
		});
	}
}
