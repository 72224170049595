import 'whatwg-fetch';
import Cookies from 'js-cookie';
import urlUtils from './url.utils';

import {
	getAuthHeader,
	getCredentialsHeader,
} from './base.utils';

const triggerHeaderClearStorage = () => document.dispatchEvent(new CustomEvent('universalnav:call', {
	detail: {
		widget: 'Header',
		method: 'clearStorage',
	},
}));

const triggerCurrencyChange = currency => document.dispatchEvent(new CustomEvent('currency:change', {
	detail: {
		currency,
	},
}));

export const changeLanguage = (locale) => {
	triggerHeaderClearStorage();
	Cookies.set('locale-language', locale);

	// check if URL contains wicket ID -> [?21213213]
	const regex = /\?\d+&?/;
	if (window.location.href.match(regex)) {
		// This regex will clear the wicket ID by changing [URL?21213213] to [URL?]
		window.location.replace(window.location.href.replace(regex, '?'));
	} else {
		urlUtils.navigateTo(urlUtils.getStorefrontUrl(locale));
	}
};

export const changeCompanyContext = (userId, companyUuid) => {
	triggerHeaderClearStorage();
	fetch(`/api/account/v2/users/${userId}/memberships/${companyUuid}/current`, {
		method: 'PUT',
		mode: 'cors',
		headers: getAuthHeader(),
		credentials: getCredentialsHeader(),
		cache: 'no-store',
	}).then(() => {
		window.location = '/home';
	}).catch((e) => {
		console.warn(`Change Company error: ${e}`); // eslint-disable-line
	});
};

export const changeCurrency = (user, currency) => {
	if (user) {
		fetch(`/api/account/v2/users/${user.user_id}`, {
			method: 'PATCH',
			headers: {
				...getAuthHeader(),
				'content-type': 'application/json',
			},
			body: JSON.stringify({
				currency,
			}),
			credentials: getCredentialsHeader(),
			mode: 'cors',
			cache: 'no-store',
		}).then(() => {
			triggerCurrencyChange(currency);
		}).catch((e) => {
			console.warn(`Change Currency error: ${e}`); // eslint-disable-line
		});
	} else {
		triggerCurrencyChange(currency);
	}
	triggerHeaderClearStorage();
};
