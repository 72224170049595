import React from 'react';
import { renderReactComponent } from './template.utils';
import urlUtils from './url.utils';
import { getUserInfo } from './conf.utils';
import IconUserImage from '../components/IconUserImage';

const getIconUserImage = () => React.createElement(IconUserImage, {
	isVisible: !!getUserInfo('profilePic'),
	userImg: urlUtils.getUserImg(),
});

const renderAllIconComponents = () => {
	// render User Icon Image
	renderReactComponent('.component-icon-user-img', getIconUserImage());
};

export default {
	getIconUserImage,
	renderAllIconComponents,
};
