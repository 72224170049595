// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".search-widget__adsearch-universal___doPaA{line-height:22px !important}\n", "",{"version":3,"sources":["webpack://./src/widgets/search/search.widget.scss"],"names":[],"mappings":"AAAA,2CACC,2BAA2B","sourcesContent":[".adsearch-universal {\n\tline-height: 22px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adsearch-universal": "search-widget__adsearch-universal___doPaA"
};
module.exports = ___CSS_LOADER_EXPORT___;
