import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { l } from '../i18n/l';
import languages from '../i18n/languages';
import { renderReactComponent } from './template.utils';
import { changeLanguage, changeCompanyContext, changeCurrency } from './event.utils';
import listItemUtils from './list.item.utils';
import { getConfigs } from '../confs/store.conf';
import { getChannelSetting, getCurrentLocale } from './conf.utils';
import { convertToBcp47Locale } from '../utils/locale';
import Link from '../components/Link';
import ListItem from '../components/ListItem';
import List from '../components/List';
import SelectItem from '../components/SelectItem';
import Select from '../components/Select';

const IS_VISIBLE = true;
const ICON_DROPDOWN = 'M512 96l-192 288h384l-192-288zM512 928l192-288h-384l192 288z';

const DropdownIcon = () => (
	<svg width="17" height="17" viewBox="0 0 1024 1024">
		<path fill="#999" d={ICON_DROPDOWN} />
	</svg>
  );

const getUserListItems = () => [
	listItemUtils.getSubscriptionListItem(),
	listItemUtils.getProfileListItem(),
	listItemUtils.getCompanyListItem(),
	listItemUtils.getInternalReportsListItem(),
	listItemUtils.getSettingsListItem(),
	listItemUtils.getLogoutListItem(),
];

const getUserList = () => (
	<List
		isVisible={IS_VISIBLE}
		className="ad-component_list--user-list"
		listItems={getUserListItems()}
		alwaysWrapInUl
	/>
);

const getManageListItems = () => [
	listItemUtils.getAccountListItem(),
	listItemUtils.getChannelListItem(),
	listItemUtils.getCorporateListItem(),
	listItemUtils.getDeveloperListItem(),
	listItemUtils.getResellerListItem(),
	listItemUtils.getSuperUserListItem(),
	listItemUtils.getAddonsListItem(),
	listItemUtils.getConnectorsListItem(),
];

// There are 2 versions of manage list component(MANAGE_LIST (new component) & MANAGE_LINKS (old- deprecated component))
const getManageList = (isNewComponent = true) => (
	<List
		isVisible={IS_VISIBLE}
		className="ad-component_list--manage-list manage-links"
		listItems={getManageListItems()}
		alwaysWrapInUl={!isNewComponent}
	/>
);

const getLanguageListItems = () => {
	const locales = getChannelSetting('localeTags') || [];
	const currentBcp47Locale = convertToBcp47Locale(getCurrentLocale());

	return locales.map((locale) => {
		const languageLink = React.createElement(Link, {
			isVisible: IS_VISIBLE,
			label: l(languages[locale]),
			value: locale,
			onClickHandler: (event) => {
				event.preventDefault();
				changeLanguage(locale);
			},
		});
		const selectedClass = classNames({ selected: currentBcp47Locale === locale });
		return <ListItem key={locale} className={selectedClass} linkComponent={languageLink} />;
	});
};

const getLanguageSelectItems = () => {
	const locales = getChannelSetting('localeTags') || [];
	const currentBcp47Locale = convertToBcp47Locale(getCurrentLocale());

	return locales.map(locale =>
		<SelectItem
			key={locale}
			label={l(languages[locale])}
			value={locale}
			selected={currentBcp47Locale === locale ? 'selected' : ''}
		/>
	);
};

const getLanguageList = () => {
	const locales = getChannelSetting('localeTags') || [];
	return (
		<List
			isVisible={locales.length > 1}
			className="ad-component_list--language-list language-links"
			listItems={getLanguageListItems()}
		/>
	);
};

const getLanguageSelect = () => (<LanguageSelectComp />);
const getWrappedLanguageSelect = () => (<WrappedLanguageSelectComp />);

const LanguageSelectComp = () => {
	const locales = getChannelSetting('localeTags') || [];
	const currentBcp47Locale = convertToBcp47Locale(getCurrentLocale());

	return (
		<Select
			isVisible={locales.length > 1}
			className="ad-component_list--language-select language-select"
			selectItems={getLanguageSelectItems()}
			onChangeHandler={changeLanguage}
			value={currentBcp47Locale}
		/>
	);
};

const LanguageSelectLabel = ({ label = '' }) => (
	<div className="ad-dropdown-unav-label">{ label }</div>
);

const WrappedLanguageSelectComp = () => {
	const currentBcp47Locale = convertToBcp47Locale(getCurrentLocale());
	const label = l(languages[currentBcp47Locale]);

	return (
		<div className="ad-dropdown-unav">
			<LanguageSelectLabel label={label} />
			<DropdownIcon />
			<div className="ad-dropdown-unav-dropdown">
				<LanguageSelectComp />
			</div>
		</div>
	);
};

const getCompanyListItems = () => {
	const user = getConfigs('bootstrap', 'UserInfo') || {};
	const memberships = getConfigs('bootstrap', 'memberships') || [];
	return memberships.map((membership) => {
		const company = (membership && membership.company) || {};
		const companyLink = React.createElement(Link, {
			isVisible: IS_VISIBLE,
			label: l(company.name),
			value: company.uuid,
			onClickHandler: (event) => {
				event.preventDefault();
				changeCompanyContext(user.user_id, company.uuid);
			},
		});
		const selectedClass = classNames({ selected: company.uuid === user.company_id });
		return <ListItem key={company.uuid} className={selectedClass} linkComponent={companyLink} />;
	});
};

const getCompanyList = () => {
	const user = getConfigs('bootstrap', 'UserInfo');
	const memberships = getConfigs('bootstrap', 'memberships') || [];
	return (
		<List
			isVisible={user && memberships.length > 1}
			className="ad-component_list--company-list company-links"
			listItems={getCompanyListItems()}
		/>
	);
};

const findClosestByClassName = (el, className) => {
	while (el && !(` ${el.className} `.includes(` ${className} `))) {
		el = el.parentNode;
	}
	return el;
};

const currencyClickHandler = (event, user, currency) => {
	event.preventDefault();
	changeCurrency(user, currency);
	// This is needed as there is development going on for SAP mobile version which is casuing 2 currency dropdown
	// in header template with visibility hidden for one. we need select nearest currency dropdown
	const currencyDropDown = findClosestByClassName(event.currentTarget, 'currency-dropdown');
	if (!currencyDropDown) {
		return;
	}
	const dropDownAnchorNode = currencyDropDown.querySelector('.currency-dropdown>a');
	const oldSelectedListItem = currencyDropDown.querySelector('.currency-dropdown .ad-component_list-item.selected');
	if (dropDownAnchorNode) {
		dropDownAnchorNode.innerText = currency;
	}
	if (oldSelectedListItem) {
		oldSelectedListItem.classList.remove('selected');
	}
	if (event.currentTarget && event.currentTarget.parentNode) {
		event.currentTarget.parentNode.classList.add('selected');
	}
};

const getCurrencyListItems = () => {
	const currencies = getChannelSetting('currencies') || [];
	const defaultCurrency = getConfigs('bootstrap', 'defaultCurrency');
	const user = getConfigs('bootstrap', 'UserInfo');
	return currencies.map((currency, index) => {
		const currencyLink = React.createElement(Link, {
			isVisible: IS_VISIBLE,
			label: l(currency),
			value: currency,
			onClickHandler: event => currencyClickHandler(event, user, currency),
		});
		const selectedClass = classNames({ selected: defaultCurrency === currency });
		return <ListItem key={`${currency}_${index}`} className={selectedClass} linkComponent={currencyLink} />;
	});
};

const getCurrencyList = () => <List isVisible={IS_VISIBLE} className="ad-component_list--currency-list currency-links" listItems={getCurrencyListItems()} />;

const renderAllLists = () => {
	renderReactComponent('.component-language-links', getLanguageList());
	renderReactComponent('.component-company-list', getCompanyList());
	renderReactComponent('.component-user-list', getUserList());
	renderReactComponent('.component-manage-list', getManageList());
	renderReactComponent('.component-manage-list-old', getManageList(false));
	renderReactComponent('.component-language-select', getLanguageSelect());
	renderReactComponent('.component-language-select-wrapped', getWrappedLanguageSelect());
};

LanguageSelectLabel.propTypes = {
	label: PropTypes.string,
};


export default {
	getLanguageList,
	getCompanyList,
	getUserList,
	getManageList,
	getCurrencyList,
	getLanguageSelect,
	getWrappedLanguageSelect,
	LanguageSelectComp,
	WrappedLanguageSelectComp,
	renderAllLists,
};

