import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import { l } from '../i18n/l';
import IconUserImage from './IconUserImage';
import ListItem from './ListItem';
import Link from './Link';
import { CloseIcon, BurgerIcon, ArrowIconRight, ArrowIconLeft } from './Icons';

import { getConfigs } from '../confs/store.conf';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { userHasRole } from '../utils/conf.utils';
import urlUtils from '../utils/url.utils';
import { convertToBcp47Locale } from '../utils/locale'

const MENU_STATE = {
	default: "default",
	browse: "browse",
	account: "account"
}

const BackToMainMenu = ({handleClick}) => (
	<li className="ad-component_list-item">
		<a className="ad-component--link ad-component--link-icon-first" href="#" onClick={handleClick}>
			<div className="ad-component_list-item--icon-first">
				<ArrowIconLeft />
			</div>
			{l('back.to.main.menu')}
		</a>
	</li>
);

BackToMainMenu.propTypes = {
	handleClick: PropTypes.func
};

class MobileMenu extends Component {
	constructor(props = {}) {
		super(props);
		this.showHideMenu = this.showHideMenu.bind(this);
		const { marketUrl, isDev } = props;
		this.baseUrl = !isDev ? marketUrl : "";
		this.state = {
			isDropdownActive: false,
			activeMenu: MENU_STATE.default,
			categories: [],
			productLines: []
		};
	}

	componentDidMount() {
		const configs = getConfigs();
		const navigatorApiUrl = `${configs.marketUrlApi}/api/marketplace/v1/navigator`;
		const productLinesApiUrl = `${configs.marketUrlApi}/api/marketplace/v1/productlines`;
		this.getData(navigatorApiUrl).then(data => {
			const categories = [];
			if (data.categories && data.categories.length) {
				data.categories.forEach(cat => categories.push({
					name: cat.name,
					url: `${this.baseUrl}${urlUtils.getCategoryUrl(cat)}`
				}))
			}
			this.setState({
				categories
			});
		});
		this.getData(productLinesApiUrl).then(data => {
			const productLines = [];
			const { locale = "en-US" } = getConfigs();
			const bcp47Locale = convertToBcp47Locale(locale)
			// data can be empty {}
			if (data?.length) {
				data.forEach(line => {
					if (line.showOnNavigation) {
						productLines.push({
							name: line.name,
							href: `/${bcp47Locale}/listing?pl=${line.id}`
						})
					}
				})
			}
			this.setState({
				productLines
			});
		});
	}

	showHideMenu(e) {
		e.nativeEvent.stopImmediatePropagation();
		this.setState({
			isDropdownActive: !this.state.isDropdownActive,
			userImg: urlUtils.getUserImg(),
			userName: urlUtils.getUserName()
		});
	}

	showBrowse = (e) => {
		e.nativeEvent.stopImmediatePropagation();
		this.setState({ activeMenu: MENU_STATE.browse });
	}

	showAccount = (e) => {
		e.nativeEvent.stopImmediatePropagation();
		this.setState({ activeMenu: MENU_STATE.account });
	}

	showDefault = (e) => {
		e.nativeEvent.stopImmediatePropagation();
		this.setState({ activeMenu: MENU_STATE.default });
	}

	getData(apiUrl) {
		return fetch(apiUrl, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store', headers: getAuthHeader() })
			.then(resp => resp.ok ? resp.json() : {})
	}

	renderDefaultMenu() {
		const { userImg, userName, productLines } = this.state;
		const footer = this.renderFooterItems();

		return (<ul className="ad-submenu ad-submenu--active" data-auto-container="submenu:default">
			{ userName &&
				<li className="ad-component_list-item" data-auto-action="show:usermenu" onClick={this.showAccount}>
					<a className="ad-component--link ad-component--link-user" href="#">
						<IconUserImage userImg={userImg} isVisible name={userName} />
						{userName}
					</a>
					<div className="ad-component_list-item--icon">
						<ArrowIconRight />
					</div>
				</li>
			}
			<li className="ad-component_list-item ad-component_list-item--browse" onClick={this.showBrowse}>
				<a className="ad-component--link " href="#">{l('browse.apps')}</a>
				<div className="ad-component_list-item--icon">
					<ArrowIconRight />
				</div>
			</li>
			{productLines.map((productLine, index) => (
				<ListItem
				key={`${productLine.name}_${index}`}
				isVisible={true}
				className="ad-component_list-item--no-border"
				linkComponent={
					<Link
						isVisible={true}
						isRelative={true}
						href={`${this.baseUrl}${productLine.href}`}
						label={productLine.name}
					/>}
				/>
			))}

			<ListItem
				isVisible={true}
				className="ad-component_list-item--no-border"
				linkComponent={<Link
					isVisible={true}
					isRelative={true}
					href={`${this.baseUrl}${urlUtils.getListingUrl()}`}
					label={l('all.applications')}
				/>}
			/>
			{footer}
		</ul>)
	}

	renderFooterItems() {
		const supportUrl = urlUtils.getSupportUrl();
		const ppUrl = urlUtils.getPrivacyPolicyUrl();
		const termsUrl = urlUtils.getTermsUrl();
		const supportEmail = urlUtils.getSupportEmail();
		return (
			<span>
				<li className="ad-component_list-item--spacer"></li>
				{ supportUrl &&
					<ListItem
						isVisible={true}
						className="ad-component_list-item--no-border"
						linkComponent={<Link
							isVisible={true}
							href={supportUrl}
							label={l('help.center')}
						/>}
					/>
				}
				{ ppUrl &&
					<ListItem
						isVisible={true}
						className="ad-component_list-item--no-border"
						linkComponent={<Link
							isVisible={true}
							href={ppUrl}
							label={l('privacy.policy')}
						/>}
					/>
				}
				{ termsUrl &&
					<ListItem
						isVisible={true}
						className="ad-component_list-item--no-border"
						linkComponent={<Link
							isVisible={true}
							href={termsUrl}
							label={l('terms.and.conditions')}
						/>}
					/>
				}
				{ supportEmail &&
					<ListItem
						isVisible={true}
						className="ad-component_list-item--no-border"
						linkComponent={<Link
							isVisible={true}
							href={`mailto:${supportEmail}`}
							label={l('Contact')}
						/>}
					/>
				}
			</span>
		)
	}

	renderCategories() {
		const { categories = [] } = this.state;
		return (<ul className="ad-submenu ad-submenu--active" data-auto-container="submenu:browse">
			<BackToMainMenu handleClick={this.showDefault} />
			<ListItem
				isVisible={true}
				linkComponent={<Link
					isVisible={true}
					isRelative={true}
					href={`${this.baseUrl}${urlUtils.getListingUrl()}`}
					label={l('View.All')}
				/>}
			/>
			{categories.map(cat => (
				<ListItem
					className="ad-component_list-item--no-border"
					key={`cat_${cat.name}`}
					linkComponent={<Link
						isVisible={true}
						href={cat.url}
						isRelative={true}
						label={cat.name}
						autoAction="link:category"
					/>}
				/>
			))}
		</ul>)
	}

	renderAccountMenu() {
		const { marketUrl } = this.props;
		return (<ul className="ad-submenu ad-submenu--account  ad-submenu--active" data-auto-container="submenu:account">
			<BackToMainMenu handleClick={this.showDefault} />
			<ListItem
				isVisible={true}
				linkComponent={<Link
					isVisible={true}
					href={`${marketUrl}${urlUtils.getProfileUrl()}`}
					label={l('My.Profile')}
				/>}
			/>
			{userHasRole('INTERNAL_REPORTS_USER') && (
				<ListItem
					isVisible={true}
					className="ad-component_list-item--no-border"
					data-testid="submenu:reports"
					linkComponent={<Link
						isVisible={true}
						href={`${marketUrl}${urlUtils.getInternalReportsUrl()}`}
						label={l('my.reports')}
					/>}
				/>
			)}
			<ListItem
				isVisible={true}
				className="ad-component_list-item--no-border"
				linkComponent={<Link
					isVisible={true}
					href={`${marketUrl}${urlUtils.getSettingsUrl()}`}
					label={l('my.settings')}
				/>}
			/>
			<ListItem
				isVisible={true}
				className="ad-component_list-item--no-border"
				linkComponent={<Link
					isVisible={true}
					href={`${marketUrl}${urlUtils.getCompanyUrl()}`}
					label={l('My.Company')}
				/>}
			/>
			<ListItem
				isVisible={true}
				className="ad-component_list-item--no-border"
				linkComponent={<Link
					isVisible={true}
					href={`${marketUrl}${urlUtils.getLogOutUrl()}`}
					label={l('logout')}
				/>}
			/>
		</ul>);
	}

	render() {
		const { isDropdownActive, activeMenu } = this.state;
		const mobileMenuClasses = classNames({
			'ad-universalnav-icons ad-component_dropdown-react unav-icon-mobile': true,
			'js-dropdown-active': isDropdownActive,
		});
		return (
			<div
				className={mobileMenuClasses}
			>
				<a className="ad-icon-link" onClick={this.showHideMenu}>
					{isDropdownActive ? <CloseIcon /> : <BurgerIcon />}
				</a>
				<div className="ad-component_list ad-component_list-full">
					<div className="ad-component_list--top-hover-shim" />
					{activeMenu === MENU_STATE.default &&
						this.renderDefaultMenu()
					}
					{activeMenu === MENU_STATE.browse &&
						this.renderCategories()
					}
					{activeMenu === MENU_STATE.account &&
						this.renderAccountMenu()
					}
				</div>
			</div>
		);
	}
}

MobileMenu.propTypes = {
	marketUrl: PropTypes.string.isRequired,
	isDev: PropTypes.bool
};

export default MobileMenu;
