import { isObject, get } from './base.utils';
import { getConfigs } from '../confs/store.conf';

import { themeStylesToUnavStylesKeyMapping, themeStylesToUnavStyleParsers } from '../utils/styles-mapping';

// see sfb-theme-components schemaComponentTypes
const ACCEPTED_FALSY_INPUT_TYPES = ['TOGGLE'];

// FIXME: remove when units are consolidated with the theme MP-34875
export const addPxUnitIfNeeded = (value) => {
	const numValue = Number((value || '').toString());
	return !isNaN(numValue) && numValue !== 0 ? `${value}px` : value;
};

const getStyleValue = (style) => {
	const globalStyleValue = style ? style.value : null;
	const globalStyleType = style ? style.type : null;
	if (!globalStyleValue && (!globalStyleType || !ACCEPTED_FALSY_INPUT_TYPES.includes(globalStyleType))) return undefined;

	return globalStyleValue;
};

// rename storefront style keys to unav styles variables using key map
// properties not in key map are just added
const mapStorefrontStylesToUnavStyles = (storefrontStyles, getStorefrontStylingCSS, currentGlobalStyles = []) => {
	let storefrontStylingCSS = '';

	Object.entries(storefrontStyles).forEach(([key, style]) => {
		const globalStyleValue = getStyleValue(style);
		if (globalStyleValue === undefined || currentGlobalStyles.indexOf(key) !== -1 ) return;

		const unavCssVarKey = themeStylesToUnavStylesKeyMapping[key];
		if (unavCssVarKey) {
			key = unavCssVarKey;
		}
		storefrontStylingCSS += getStorefrontStylingCSS(key, globalStyleValue);
	});

	return storefrontStylingCSS;
};

// generate unav styles from storefront styles using parser functions
// e.g.
// - homeBarTextSize = 'medium' -> headerNavItemFontSizeOffset = '2px'
// - homeBarBackgroundColor = '#2d2a27' -> headerNavItemBgHover = '#3e3b38'
const generateAdditionalUnavStyles = (storefrontStyles, getStorefrontStylingCSS, currentGlobalStyles = []) => {
	let storefrontStylingCSS = '';

	Object.entries(themeStylesToUnavStyleParsers).forEach(([key, parserFunc]) => {
		const style = storefrontStyles[key];
		const globalStyleValue = getStyleValue(style);
		if (globalStyleValue === undefined || currentGlobalStyles.indexOf(key) !== -1) return;

		const unavKeyValues = parserFunc(globalStyleValue);

		unavKeyValues.forEach((unavKeyValue) => {
			const { unavKey, unavValue } = unavKeyValue;

			if (unavKey && unavValue) {
				storefrontStylingCSS += getStorefrontStylingCSS(unavKey, unavValue);
			}
		});
	});

	return storefrontStylingCSS;
};

const extractGlobalCSSVarNames = (styleId) => {
	// Find the <style> element with the specified id
	const styleElement = document.querySelector(`style[id="${styleId}"]`);
	if (!styleElement) {
		console.warn('Style element not found.');
		return [];
	}
	const styleContent = styleElement.innerText;
	// Regular expression to match CSS variables (assumes variables are well-formed)
	const cssVarRegex = /--[\w-]+(?=:)/g;
	const matches = styleContent.match(cssVarRegex);
  
	if (!matches) {
		console.warn('No CSS variables found.');
		return [];
	}
	// Remove the -- prefix and return the variable names
	return matches.map(varName => varName.slice(2));
  }
  

const parseGlobalStyling = (storefrontStyling = {}) => {
	const IMAGE_UPLOADER = 'IMAGE_UPLOADER';
	let storefrontStylingCSS = '';
	// SFB create global styles, but it's missing css variables for the header
	// we should not couple header styles with global styles, but here we are
	// In the future, refactor this so that header styles are not dependent on global styles
	// for now we add missing styles based on the global styles, this should not happen often
	const currentGlobalStyles = extractGlobalCSSVarNames("globalCSS");
	const getVariablePair = (key, value) => (
		'--' + key + ': ' + addPxUnitIfNeeded(value) + ';\n' // eslint-disable-line prefer-template
	);

	const getVariableUrlPair = (key, value) => (
		'--' + key + ': url(' + value + ');\n' // eslint-disable-line prefer-template
	);

	const getObjectStyling = (key, value) => {
		try {
			switch (value.type) {
			case IMAGE_UPLOADER:
				return getVariableUrlPair(key, value.url);
			default:
				return '';
			}
		} catch (e) {
			return '';
		}
	};

	const getStorefrontStylingCSS = (key, value) => {
		if (isObject(value)) {
			return getObjectStyling(key, value);
		}
		return getVariablePair(key, value);
	};

	storefrontStylingCSS += mapStorefrontStylesToUnavStyles(storefrontStyling, getStorefrontStylingCSS, currentGlobalStyles);
	storefrontStylingCSS += generateAdditionalUnavStyles(storefrontStyling, getStorefrontStylingCSS, currentGlobalStyles);
	return storefrontStylingCSS;
};

export const addGlobalStyling = (globalStyling = {}) => {
	try {
		const globalStylingCSS = parseGlobalStyling(globalStyling);
		if (globalStylingCSS && !document.querySelector('style[id="globalCSSHeader"]')) {
			const head = document.head || document.getElementsByTagName('head')[0];
			const style = document.createElement('style');
			head.appendChild(style);

			style.type = 'text/css';
			style.id = 'globalCSSHeader';
			const globalCSS = `
				:root {
					${globalStylingCSS}
				}
			`;
 
			style.appendChild(document.createTextNode(globalCSS));
		}
	} catch (err) {
		// console.warn(`Unable to add global styling. ${err}`);
	}
};

export const getGlobalStylesPropValue = (key, valueProp = 'value') => get(getConfigs('globalStyles', key), valueProp);
