import CSSOM from 'cssom';
import './polyfill/dom.remove';
import { getConfigs } from '../confs/store.conf';

export function domReady(callback) {
	if (document.readyState === 'complete' || document.readyState === 'loaded' || document.readyState === 'interactive') {
		callback();
	} else {
		document.addEventListener('DOMContentLoaded', callback);
	}
}

export function deepExtend(destination, source) {
	for (var property in source) { // eslint-disable-line
		if (source[property] && source[property].constructor && source[property].constructor === Object) {
			destination[property] = destination[property] || {};
			arguments.callee(destination[property], source[property]); // eslint-disable-line
		} else {
			destination[property] = source[property];
		}
	}
	return destination;
}

export function isErrorPage() {
	const { pathname } = window.location;
	return pathname.startsWith('/http-status');
}

export function isUniversal() {
	return getConfigs('theme') === 'universal' || getConfigs('theme') === 'appdirectapp';
}

export function isExternalApplication() {
	return (getConfigs('sub') === 'appwise' || getConfigs('sub') === 'appinsights');
}

export function isSFBToolkit() {
	return (getConfigs('isSFBenabled') === true && getConfigs('isDev') === true);
}

export function getXsrfToken() {
	const cookie = document.cookie || '';
	const startIndex = cookie.indexOf('XSRF-TOKEN');
	if (startIndex > -1) {
		const endIndex = cookie.indexOf(';', startIndex);
		return (endIndex > 0) ? cookie.substring(startIndex + 11, endIndex) : cookie.substring(startIndex + 11);
	}
	return '';
}

export function getAuthHeader() {
	var headers = {};

	// Only SFB enabled applications need access token.
	// Otherwise use jessionid
	if (getConfigs('token') && isSFBToolkit()) {
		headers.Authorization = `Bearer ${getConfigs('token')}`;
	}
	return headers;
}

export function getCredentialsHeader() {
	if (isSFBToolkit()) {
		return 'same-origin';
	}
	return 'include';
}
// only used with storefront builder versions
export function getPreviewVersion(isFirst) {
	const previewData = getConfigs('builderPreviewData') || {};
	if (getConfigs('mode') === 'preview' && previewData.themeId) {
		const themeId = previewData.themeId;
		const versionId = previewData.versionId;
		const separator = isFirst ? '?' : '&';
		return `${separator}versionId=${versionId}&themeId=${themeId}`;
	}
	return '';
}

export function attachEvent(event, els, func, preventDefault = true) {
	[].forEach.call(document.querySelectorAll(els), (el) => {
		el.addEventListener(event, (evt) => {
			if (preventDefault) {
				evt.preventDefault();
			}
			func(evt, el);
		}, false);
	});
}

export function isMarketplacePage() {
	return getConfigs('isMarketplace');
}

export function parseProperties(str, obj = {}) {
	// replace tags with the parent attribute of the same name (if it exists), or with the default value, or with a fallback
	if (Object.keys(obj).length === 0) {
		return str;
	}
	return str.replace(/\"\$\{ *(?:\w+\.)?(\w+) *\}\"/g, (match, key, def) => obj[key] || def || ' '); // eslint-disable-line
}

export function appendCSS(content, id, parseHeader) {
	var parsedCSS;
	var css;
	var body = document.body;
	var style = document.createElement('style');

	if (parseHeader) {
		parsedCSS = CSSOM.parse(content);
		parsedCSS.cssRules.map((item) => {
			item.selectorText = `.ad-universalnav ${item.selectorText}`.replace(',', ', .ad-universalnav ');
			if (item.cssRules && item.cssRules.length) {
				item.cssRules.map((subItem) => {
					subItem.selectorText = `.ad-universalnav ${subItem.selectorText}`.replace(',', ', .ad-universalnav ');
					return subItem;
				});
			}
			return item;
		});
	}
	css = parseHeader ? parsedCSS.toString() : content;
	style.type = 'text/css';
	style.id = id;

	var oldStyle = document.getElementById(id);

	if (style.styleSheet) {
		style.styleSheet.cssText = css;
	} else {
		style.appendChild(document.createTextNode(css));
	}
	body.appendChild(style);
	if (oldStyle) {
		oldStyle.remove();
	}
	return css;
}

function nodeName(elem, name) {
	return elem.nodeName && elem.nodeName.toUpperCase() === name.toUpperCase();
}

function evalScript(elem) {
	var data = (elem.text || elem.textContent || elem.innerHTML || '');
	var src = elem.getAttribute('src');
	var head = document.getElementsByTagName('head')[0] || document.documentElement;
	var script = document.createElement('script');
	script.type = 'text/javascript';
	if (src) {
		script.src = src;
	}
	script.appendChild(document.createTextNode(data));
	head.insertBefore(script, head.firstChild);
	head.removeChild(script);
	if (elem.parentNode) {
		elem.parentNode.removeChild(elem);
	}
}

export function getUrlParameter(name) {
	const param = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]'); // eslint-disable-line
	const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
	const results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function executeScript(scriptContainer) {
	var scripts = [];
	if (scriptContainer) {
		var items = scriptContainer.querySelectorAll('*');
		if (items.length) {
			[...items].forEach((el) => {
				if (scripts && nodeName(el, 'script') && (!el.type || el.type.toLowerCase() === 'text/javascript')) {
					scripts.push(el.parentNode ? el.parentNode.removeChild(el) : el);
				}
			});
			scripts.forEach((currentScript) => {
				evalScript(currentScript);
			});
		}
	}
}

export function isObject(value) {
	return Object.prototype.toString.call(value) === '[object Object]';
}

// equivalent to lodash/get
// from https://youmightnotneed.com/lodash
export function get(obj, path, defValue) {
  // If path is not defined or it has false value
	if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
	const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
	const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
	return result === undefined ? defValue : result;
}

export function isJQueryUsed(template) {
	// $. or $( or jQuery. or jQuery(
	const regex = new RegExp(/(\$[.(])|(jQuery[.(])/g);
	return regex.test(template);
}

export function isJQueryNeeded(footerTemplate) {
	return (window.$ === undefined && isJQueryUsed(footerTemplate));
}
