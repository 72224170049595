import 'whatwg-fetch';
import { getConfigs, setConfigs } from './store.conf';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { getJSONCachedItem } from '../utils/cache.utils';

function getUnavConfigurations() {
	var configs = getConfigs();
	var url = `${configs.marketUrlApi}/api/v3/universal-nav/configuration`;
	return fetch(
		url,
		{
			mode: 'cors',
			credentials: getCredentialsHeader(),
			cache: 'no-store',
			headers: getAuthHeader(),
		}
	).then((resp) => {
		if (resp.ok) {
			return resp.json();
		} else if (!resp.ok && resp.status === 401) {
			document.dispatchEvent(new CustomEvent('universalnav:401'));
		}
		return {};
	});
}

export function setupUnavConfigurations() {
	const addonStoreConfiguration = getJSONCachedItem('header', 'addonStoreConfiguration');
	return new Promise((resolve) => {
		if (addonStoreConfiguration) {
			setConfigs({
				addonStoreConfiguration,
			});
			resolve();
		} else {
			getUnavConfigurations().then((data) => {
				setConfigs({
					addonStoreConfiguration: data.addonStoreConfiguration,
				});
				resolve();
			});
		}
	});
}
