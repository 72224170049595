import React from 'react';
import PropTypes from 'prop-types';

const MyApp = ({ imageUrl, launchUrl, appName }) => {
	const onClickHandler = (e) => {
		e.preventDefault();
		window.open(launchUrl);
	};
	return (
		<a  href={launchUrl} className="ad-stack-apps--item" onClick={onClickHandler}>
			<div className="ad-stack-apps--item-img-container">
			{
				imageUrl ?
					<img src={imageUrl} alt="" className="ad-stack-apps--item-img" />
				:
					<i className="ad-icon-star"></i>
			}
			</div>
			<div className="ad-stack-apps--item-text">{appName}</div>
		</a>
	);
};

MyApp.propTypes = {
	launchUrl: PropTypes.string.isRequired,
	appName: PropTypes.string.isRequired,
	imageUrl: PropTypes.string,
};

export default MyApp;
