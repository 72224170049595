import 'whatwg-fetch';
import { getConfigs } from './store.conf';
import loadScript from '../utils/load-script';

// zendesk chat sdk
var zChat;
// HeaderNotifications from header.notifications.js
var notifications;
// timestamp of message last seen by the user
var lastRead;
// is zchat initialized?
var loaded;
// log of all messages, indexed by timestamp
const history = {};

const getZendeskCredentials = (marketURL) => {
	const appwiseTeamsUrl = `${marketURL}/api/appwise/v1/teams`;
	const zendeskTokenApiUrl = `${appwiseTeamsUrl}/api/zendesk/token`;
	const config = {
		mode: 'cors',
		cache: 'no-store',
	};
	return fetch(zendeskTokenApiUrl, config).then(resp => resp.ok ? resp.json() : { content: [] });
};


const checkUnreadMessages = () => {
	if (lastRead) {
		const missedCount = Object.values(history).reduce((count, timestamp) => {
			if (timestamp > lastRead) {
				return count + 1;
			}
			return count;
		}, 0);
		notifications.setZenUnread(missedCount);
	}
};

const handleChat = (data) => {
	switch (data.type) {
	case 'chat.file':
	case 'chat.msg':
		// ignore our own messages
		if (!data.nick.startsWith('visitor')) {
			history[data.timestamp] = new Date(data.timestamp * 1000);
		}
		break;
	case 'last_read':
		lastRead = new Date(data.timestamp * 1000);
		break;
	default:
		return;
	}
	checkUnreadMessages();
};

const subscribe = () => {
	zChat.on('connection_update', (data) => {
		if (data === 'connected') {
			checkUnreadMessages();
		}
	});
	zChat.on('chat', handleChat);
};

const connectZenChat = (zendeskAccount, zendeskToken) => {
	subscribe();

	zChat.init({
		account_key: zendeskAccount,
		authentication: {
			jwt_fn(callback) {
				callback(zendeskToken);
			},
		},
	});
};

const initZenChatSDK = (marketURL, onDone) => {
	loadScript(`${marketURL}/universal-header/zendesk-web-sdk-1.11.2.js`, () => {
		zChat = window.zChat;
		onDone();
	});
};

const needsZenLoad = () => getConfigs('sub') !== 'appwise';

export function setupZendesk(notificationsComponent) {
	notifications = notificationsComponent;
	if (needsZenLoad()) {
		const marketURL = getConfigs('marketUrl');
		getZendeskCredentials(marketURL).then((data) => {
			if (data.zendeskAccount) {
				initZenChatSDK(marketURL, () => {
					if (!loaded) {
						connectZenChat(data.zendeskAccount, data.zendeskToken);
						loaded = true;
					}
				});
			}
		});
	} else {
		document.addEventListener('universalnav:zenchat:count', missedCount => notifications.setZenUnread(missedCount));
	}
}
