import React from 'react';
import PropTypes from 'prop-types';

const ICONS = {
    burger: "M128 224h768v96h-768v-96zM128 480h768v96h-768v-96zM128 736h768v96h-768v-96z",
    circleSolid: "M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z",
    x: "M704 352l-64-64-160 160-160-160-64 64 160 160-160 160 64 64 160-160 160 160 64-64-160-160z",
    arrowRight: "M799.936 512l-447.936 447.936-160-160 288-287.936-288-288 160-160 447.936 448z",
    arrowLeft: "M224 512l448 447.936 160-160-288.064-287.936 288.064-288-160-160-448 448z",
    addon: "M938.667 540.444c47.128 0 85.333 38.205 85.333 85.333v312.889c0 47.128-38.205 85.333-85.333 85.333h-312.889c-47.128 0-85.333-38.205-85.333-85.333v-312.889c0-47.128 38.205-85.333 85.333-85.333h312.889zM398.222 540.444c47.128 0 85.333 38.205 85.333 85.333v312.889c0 47.128-38.205 85.333-85.333 85.333h-199.111c-109.966 0-199.111-89.145-199.111-199.111v-199.111c0-47.128 38.205-85.333 85.333-85.333h312.889zM782.222 597.333c-23.564 0-42.667 19.103-42.667 42.667v0l-0.014 99.541-99.541 0.014c-23.564 0-42.667 19.103-42.667 42.667s19.103 42.667 42.667 42.667v0l99.541-0.004 0.014 99.559c0 23.564 19.103 42.667 42.667 42.667s42.667-19.103 42.667-42.667v0l-0.004-99.559 99.559 0.004c23.564 0 42.667-19.103 42.667-42.667s-19.103-42.667-42.667-42.667v0l-99.559-0.014 0.004-99.541c0-23.564-19.103-42.667-42.667-42.667zM398.222 625.778h-312.889v199.111c0 62.838 50.94 113.778 113.778 113.778h199.111v-312.889zM824.889 0c109.966 0 199.111 89.145 199.111 199.111v199.111c0 47.128-38.205 85.333-85.333 85.333h-312.889c-47.128 0-85.333-38.205-85.333-85.333v-312.889c0-47.128 38.205-85.333 85.333-85.333h199.111zM398.222 0c47.128 0 85.333 38.205 85.333 85.333v312.889c0 47.128-38.205 85.333-85.333 85.333h-312.889c-47.128 0-85.333-38.205-85.333-85.333v-199.111c0-109.966 89.145-199.111 199.111-199.111h199.111zM824.889 85.333h-199.111v312.889h312.889v-199.111c0-62.838-50.94-113.778-113.778-113.778zM398.222 85.333h-199.111c-62.838 0-113.778 50.94-113.778 113.778v199.111h312.889v-312.889z",
    error: "M1017.032 1022.998h-935.891c-57.7 0-81.809-40.871-54.027-91.225l470.55-871.78c27.848-50.354 72.86-50.354 100.641 0l472.687 871.78c27.782 50.354 3.673 91.225-53.96 91.225v0 0zM617.806 357.51h-133.632v333.913h133.565v-333.913h0.067zM617.806 758.205h-133.632v133.565h133.565v-133.565h0.067z",
    success: "M512 1024c-282.752 0-512-229.248-512-512s229.248-512 512-512c282.752 0 512 229.248 512 512s-229.248 512-512 512v0 0zM721.088 302.016l-267.968 265.92-134.080-133.888-96 96 230.016 230.016 363.968-361.984-95.936-96.064z",
    product: "M19.964 11.91h9.676c0.994 0 1.406 1.27 0.604 1.854l-7.83 5.688 2.99 9.202c0.308 0.944-0.772 1.73-1.576 1.146l-7.828-5.688-7.828 5.688c-0.804 0.584-1.884-0.202-1.576-1.146l2.99-9.202-7.828-5.688c-0.804-0.584-0.392-1.854 0.602-1.854h9.676l2.99-9.202c0.306-0.944 1.642-0.944 1.948 0l2.99 9.202z",
};

const IconPropTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    viewBox: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.string,
};

export const Icon = ({
    width = "24",
    height = "24",
    color = "#fff",
    viewBox = "0 0 1024 1024",
    className = "",
    icon
}) => (
    <svg className={className} width={width} height={height} viewBox={viewBox}>
        <path fill={color} d={icon}></path>
    </svg>
);
Icon.propTypes = IconPropTypes;

export const CloseIcon = ({ width = "34", height = "34", color = "#9b9b9b" }) =>
    <Icon
        className="closeIcon"
        width={width}
        height={height}
        icon={ICONS.x}
        color={color}
    />;
CloseIcon.propTypes = IconPropTypes;

export const BurgerIcon = ({ color = "#fff" }) =>
    <Icon
        icon={ICONS.burger}
        color={color}
    />;
BurgerIcon.propTypes = IconPropTypes;

export const CircleSolid = ({ width = "10", height = "10", viewBox = "-16 -21 60 60" }) =>
    <Icon
        icon={ICONS.circleSolid}
        width={width}
        height={height}
        viewBox={viewBox}
    />;
CircleSolid.propTypes = IconPropTypes;

export const ArrowIconRight = ({ width = "18", height = "18", color = "#c3c3c3" }) =>
    <Icon
        icon={ICONS.arrowRight}
        width={width}
        height={height}
        color={color}
    />;
ArrowIconRight.propTypes = IconPropTypes;

export const ArrowIconLeft = ({ width = "18", height = "18", color = "#c3c3c3" }) =>
    <Icon
        icon={ICONS.arrowLeft}
        width={width}
        height={height}
        color={color}
    />;
ArrowIconLeft.propTypes = IconPropTypes;

export const AddonIcon = ({ width = "32", height = "32", color = "#c7c7c7" }) =>
    <Icon
        className="icon-img"
        icon={ICONS.addon}
        width={width}
        height={height}
        color={color}
    />;
AddonIcon.propTypes = IconPropTypes;

export const ProductIcon = ({ width = "32", height = "32", color = "#c7c7c7", viewBox = "-16 -16 64 64" }) =>
    <Icon
        className="icon-img"
        icon={ICONS.product}
        width={width}
        height={height}
        color={color}
        viewBox={viewBox}
    />;
ProductIcon.propTypes = IconPropTypes;

export const ErrorIcon = ({ width = "28", height = "24", color = "#C13445" }) =>
    <Icon
        className="error-img"
        icon={ICONS.error}
        width={width}
        height={height}
        color={color}
    />;
ErrorIcon.propTypes = IconPropTypes;

export const SuccessIcon = ({ width = "24", height = "24", color = "#64A11D" }) =>
    <Icon
        className="success-img"
        icon={ICONS.success}
        width={width}
        height={height}
        color={color}
    />;
SuccessIcon.propTypes = IconPropTypes;
