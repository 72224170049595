import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DropDown = (props) => {
	const dropDownClasses = classNames('ad-component_list--item', 'ad-component_dropdown', props.className);
	const linkClasses = classNames('ad-component--link', 'ad-component_dropdown--trigger', props.linkClassName);
	if (!props.isVisible || !props.listComponent || !props.listComponent.props.isVisible) {
		return null;
	}
	// Wrap inside dropdown tag if list has more than one item
	const Label = ({ ellipsizeLabel, label }) => ellipsizeLabel ? 
		<span
			className={'ellipsize-overflow-text'} style={{
				display: 'inline-block',
				maxWidth: '12ex',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			}}
		>{label}</span> : label;
	const listItems = props.listComponent.props.listItems;
	return listItems && listItems.length && listItems.filter(item => item.props.linkComponent.props.isVisible).length > 1
		? <li className={dropDownClasses} data-testid={"unav:Manage_menu"}>
			<a className={linkClasses} href="javascript:;">
				<Label ellipsizeLabel={props.ellipsizeLabel} label={props.label} />
			</a>
			{props.listComponent}
		</li>
		: props.listComponent;
};

DropDown.propTypes = {
	className: PropTypes.string,
	linkClassName: PropTypes.string,
	label: PropTypes.string,
	listComponent: PropTypes.element,
	isVisible: PropTypes.bool,
	ellipsizeLabel: PropTypes.bool,
};

DropDown.defaultProps = {
	ellipsizeLabel: false,
};

export default DropDown;
