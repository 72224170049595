import { getConfigs } from '../confs/store.conf';
import { MOCK_USER_ID, MOCK_COMPANY_ID } from './constants.utils';

export function getAssignmentsEndpoint(returnMockData) {
	const { marketUrlApi, companyInfo, userUUID } = getConfigs();
	const { uuid = MOCK_COMPANY_ID } = companyInfo;

	const mockAssignmentsURL = `${marketUrlApi}/api/v3/universal-nav/assignments/${MOCK_COMPANY_ID}/${MOCK_USER_ID}?limitNbApps=9`;
	const assignmentsURL = `${marketUrlApi}/api/v3/universal-nav/assignments/${uuid}/${userUUID}?limitNbApps=9`;
	return returnMockData ? mockAssignmentsURL : assignmentsURL;
}

export function getMembershipsEndpoint(returnMockData) {
	const { marketUrlApi, userUUID = MOCK_USER_ID } = getConfigs();
	const membershipURL = `${marketUrlApi}/api/account/v2/users/${userUUID}/memberships`; // eslint-disable-line
	const mockMembershipURL = `${marketUrlApi}/api/account/v2/users/${MOCK_USER_ID}/memberships`;
	return returnMockData ? mockMembershipURL : membershipURL;
}
