import 'whatwg-fetch';
import 'location-origin';
import { getConfigs, setConfigs } from './store.conf';
import defaultProps from '../widgets/header/css-map-default';
import { getCredentialsHeader, getPreviewVersion } from '../utils/base.utils';

function getProperties() {
	var configs = getConfigs();
	var url = `${configs.marketUrlApi}/api/channel/v1/styles/${configs.partner}/theme/${configs.mode}${getPreviewVersion(true)}`;
	return fetch(url, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store' }).then(resp => resp.json());
}

function getDefaultProps() {
	var props = defaultProps();
	if (getConfigs('sub').toLowerCase() === 'appwise') {
		props.headerNavDropdownMarginRight = '25px';
	}
	return props;
}

export function setupCSSproperties() {
	var cssProps = getDefaultProps();
	return new Promise((resolve) => {
		var defaultDesignProps = getConfigs('defaultDesignProps');
		if (defaultDesignProps) {
			setConfigs({ cssProperties: Object.assign({}, cssProps, defaultDesignProps) });
			resolve(defaultDesignProps);
		} else if (getConfigs('static') || getConfigs('forceDefaultTemplates')) {
			// we don't get or use properties when using a custom template
			setConfigs({ cssProperties: cssProps });
			resolve(cssProps);
		} else {
			getProperties().then((data = {}) => {
				var props = Object.keys(data).length ? data : cssProps;
				setConfigs({ cssProperties: Object.assign({}, cssProps, props) });
				resolve(props);
			});
		}
	});
}
