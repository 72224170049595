import React from 'react';
import { getConfigs } from '../confs/store.conf';
import { isAppSmart } from '../utils/appsmart-identity';
import { renderInPlaceOfNode } from '../utils/template.utils';
import { getAppInsightsApp, userHasRole } from '../utils/conf.utils';
import { isMarketplacePage, isExternalApplication } from '../utils/base.utils';
import IconLink from './IconLink';
import MyApps from './MyApps';
import { l } from '../i18n/l';

const MARKETPLACE_ROUTE = 'home';
export default class IconsContainer {
	init() {
		this.listenEvents();
		this.render();
	}

	listenEvents() {
		document.addEventListener('universalnav:signals:count', ({ detail }) => this.setIconCount(detail));
		document.addEventListener('universalnav:icon:active', ({ detail }) => this.setIconActive(detail.icon));
	}

	setIconsDefault() {
		var icons = getConfigs('reactComponents', 'icons') || {};
		Object.keys(icons).forEach((icon) => {
			icons[icon].setIconDefault();
		});
	}

	setIconCount({ counts }) {
		var icons = getConfigs('reactComponents', 'icons') || {};
		Object.keys(icons).forEach((icon) => {
			icons[icon].setIconCount(counts);
		});
	}

	setIconActive(iconName) {
		var icons = getConfigs('reactComponents', 'icons') || {};
		this.setIconsDefault();
		Object.keys(icons).forEach((icon) => {
			icons[icon].setIconActive(iconName);
		});
	}

	render() {
		/** myapps icon is contained in it's own components */
		const { marketUrl, myapps, header, reactComponents, userUUID } = getConfigs();
		const homeLabel = isAppSmart() ? 'Activity Feed' : 'Notifications';

		/** My Apps */
		[...document.querySelectorAll('.ad-component__myapps')].forEach((node, i) => {
			renderInPlaceOfNode(React.createElement(MyApps, {
				appsCollection: myapps.content,
				userUUID,
				marketUrl,
				appsToDisplay: 9,
				openInNewTab: isExternalApplication(),
				ref: (component) => { reactComponents.general[`myapps${i}`] = component; },
			}), node);
		});

		/** Feeds Icon */
		[...document.querySelectorAll('.unav-icon-home')].forEach((node, i) => {
			renderInPlaceOfNode(
				<IconLink
					href="/appwise"
					links={header.links}
					iconClass="ad-icon-home"
					marketUrl={marketUrl}
					countType="totalUpdatedCount"
					allowNotifications
					type="home-link"
					label={l(homeLabel)}
					ref={(icon) => { reactComponents.icons[`home${i}`] = icon; }}
				/>, node);
		});

		/** Messages Icon */
		[...document.querySelectorAll('.unav-icon-appwise')].forEach((node, i) => {
			const messengerLabel = isAppSmart() && userHasRole('RESELLER') ? 'Sales and Tech Support' : 'Messenger';
			renderInPlaceOfNode(
				<IconLink
					href="/appwise/messages"
					links={header.links}
					marketUrl={marketUrl}
					iconClass="ad-icon-group"
					countType="totalUnreadCount"
					allowNotifications
					type="wise-link"
					label={l(messengerLabel)}
					ref={(icon) => { reactComponents.icons[`messenger${i}`] = icon; }}
				/>, node);
		});

		/** Marketplace Icon */
		[...document.querySelectorAll('.unav-icon-marketplace')].forEach((node, i) => {
			renderInPlaceOfNode(
				<IconLink
					href={`${marketUrl}/${MARKETPLACE_ROUTE}`}
					route={MARKETPLACE_ROUTE}
					marketUrl={marketUrl}
					iconClass="ad-icon-cart"
					id="shop"
					type="marketplace-link"
					isActive={isMarketplacePage()}
					label={l('Marketplace')}
					ref={(icon) => { reactComponents.icons[`marketplace${i}`] = icon; }}
					openInNewTab={isExternalApplication()}
				/>, node);
		});

		/** AppInsights Icon */
		[...document.querySelectorAll('.unav-icon-appinsight')].forEach((node, i) => {
			renderInPlaceOfNode(
				<IconLink
					href={getAppInsightsApp().launchUrl}
					iconClass="ad-icon-bar-graph"
					type="insight-link"
					links={header.links}
					marketUrl={marketUrl}
					label={l('Insights')}
					ref={(icon) => { reactComponents.icons[`insight${i}`] = icon; }}
					openInNewTab
				/>, node);
		});
	}
}
