import React from 'react';

const SEARCHFORM_INPUT_ID = 'searchforminput';

const SearchForm = () => (
	<form className="ad-component_search" name="searchForm" id="search-form" method="get" action="/listing">
		<div className="ad-component_search--input">
			<label htmlFor={SEARCHFORM_INPUT_ID}>Search: </label>
			<input id={SEARCHFORM_INPUT_ID} type="text" maxLength="200" value="" name="q" placeholder="" />
		</div>
		<button className="ad-component_search--button" type="submit" aria-label="Search">
			<i className="adb-icon__search"></i>
		</button>
	</form>
);

export default SearchForm;
