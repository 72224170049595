import { getConfigs, setConfigs } from './store.conf';  // eslint-disable-line
import { setJSONCachedItem } from '../utils/cache.utils';  // eslint-disable-line

// use jQuery instead of fetch to avoid too many redirects error
const getToken = (CLIENT_ID) => {  // eslint-disable-line
	const url = getConfigs('marketUrlApi');
	const responseType = 'token%20id_token';
	const redirectUri = '/myapps';
	const scope = 'openid%20profile%20company';

	return $.get(`${url}/oauth/authorize?response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&client_id=${CLIENT_ID}`);
};

// TODO: Remove the need for this function/file in the future, we no longer fetch tokens
export function setupToken() {
	return Promise.resolve();
}
