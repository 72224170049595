import React from 'react';
import { renderReactComponent } from '../utils/template.utils';
import ImpersonateBanner from './ImpersonateBanner';
import { getUserInfo, getCurrentUserInfo } from '../utils/conf.utils';
import { l } from '../i18n/l';

const BANNER_OFFSET = 48;

export default class ImpersonateBannerContainer {
	constructor() {
		return this;
	}

	removeExistingBanner() {
		const nodes = document.getElementsByClassName('ImpersonateBanner');

		while (nodes[0]) {
			nodes[0].remove();
		}
	}

	addContainer(root) {
		const bannerContainer = document.createElement('div');
		bannerContainer.setAttribute('class', 'impersonate-banner-container');
		root.insertBefore(bannerContainer, root.firstChild);
	}

	adjustTopFixedElements(root, trial = 0) {
		const fixedElements = [...root.getElementsByTagName('*')].filter(
			el => getComputedStyle(el, null).getPropertyValue('position') === 'fixed'
				&& !el.parentElement.className.includes('ImpersonateBanner')
				&& el.offsetTop < BANNER_OFFSET
		);
		if (fixedElements.length) {
			fixedElements.forEach((el) => { el.style.top = `${el.offsetTop + BANNER_OFFSET}px`; });
		}

		if (trial < 5) {
			setTimeout(() => this.adjustTopFixedElements(root, trial + 1), 400);
		} else {
			document.addEventListener('universalnav:header:loaded', () => {
				const nodes = document.getElementsByClassName('ImpersonateBanner');
				const isRendered = !!(nodes && nodes.length);
				if (isRendered) {
					this.adjustTopFixedElements(root);
				}
			}, { once: true });
		}
	}

	load() {
		setTimeout(() => {
			if (getCurrentUserInfo('impersonated') || getCurrentUserInfo('isImpersonated')) {
				if (!sessionStorage.getItem('ImpersonationOriginalState')) {
					sessionStorage.setItem('ImpersonationOriginalState', document.referrer);
				}
				this.render(document.getElementsByTagName('body')[0]);
			}
		}, 300);
	}

	render(root) {
		this.removeExistingBanner();
		this.adjustTopFixedElements(root);
		this.addContainer(root);

		const name = getUserInfo('name');
		const email = getUserInfo('email');
		renderReactComponent('.impersonate-banner-container', <ImpersonateBanner {...{ name, email, l }} />);
	}
}
