import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const List = (props) => {
	if (!props.isVisible || !props.listItems || !props.listItems.length) {
		return null;
	}
	const visibleListItems = props.listItems.filter(item => item.props.linkComponent.props.isVisible);
	return visibleListItems.length === 1 && !props.alwaysWrapInUl
		? visibleListItems[0] // Do not wrap inside <ul>, if list has only one visible element and alwaysWrapInUl is false
		: <ul className={classNames('ad-component_list', props.className)}> {props.listItems} </ul>
};

List.defaultProps = {
	alwaysWrapInUl: false
};

List.propTypes = {
	isVisible: PropTypes.bool,
	className: PropTypes.string,
	alwaysWrapInUl: PropTypes.bool,
	listItems: PropTypes.arrayOf(PropTypes.element)
};

export default List;
