import 'whatwg-fetch';
import { getConfigs, setConfigs } from './store.conf';
import { getJSONCachedItem } from '../utils/cache.utils';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { getCurrentLocale } from '../utils/conf.utils';

export default class {
	getBootstrapData() {
		var domain = getConfigs('marketUrlApi');
		const url = `${domain}/api/session/v1/bootstrap-context`;
		return fetch(url, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store', headers: getAuthHeader() }).then((resp) => {
			if (resp.ok) {
				return resp.json();
			} else if (!resp.ok && resp.status === 401) {
				document.dispatchEvent(new CustomEvent('universalnav:401'));
			}
			return {};
		});
	}

	getCachedBootstrapData() {
		let cachedData;
		if (getConfigs('defaultBootData')) {
			cachedData = { bootstrap: getConfigs('defaultBootData') };
		} else if (getConfigs('cache') && window.bootstrapData) {
			cachedData = window.bootstrapData;
		} else if ((getConfigs('userUUID') || getConfigs('isSFBenabled')) && getJSONCachedItem('header', 'bootstrap')) {
			cachedData = { bootstrap: getJSONCachedItem('header', 'bootstrap') };
		}
		return cachedData;
	}

	setRegistry() {
		if (window._populateBootstrapRegistry) {
			window._populateBootstrapRegistry({
				register: (item, data) => {
					setConfigs({ registeryContent: { [item]: data } });
					if (item === 'memberships') {
						if (data && data.content) {
							setConfigs({ memberships: data.content }, 'bootstrap');
						}
					}
				},
			});
		}
	}

	setUserStatus(bootData) {
		const userUUID = bootData.CurrentUser && bootData.CurrentUser.uuid;
		if (userUUID) {
			setConfigs({ userState: 'loggedin' });
		} else if (getConfigs('userUUID') && getConfigs('userUUID') !== userUUID) {
			setConfigs({ userState: 'static:loggedin' });
		} else {
			setConfigs({ userState: 'loggedout' });
		}
	}

	setStaticData() {
		if (getConfigs('userState') === 'static:loggedin') {
			const myapps = getJSONCachedItem('header', 'myapps');
			const bootstrap = getJSONCachedItem('header', 'bootstrap');
			const locale = getJSONCachedItem('header', 'locale');
			if (myapps) {
				setConfigs({ myapps });
				setConfigs({ myapps }, 'bootstrap');
			}
			if (bootstrap) {
				setConfigs({ bootstrap });
			}
			if (locale) {
				setConfigs({ locale });
			}
		}
	}

	setBootData(bootData) {
		// userUUID needs to be set first to access localStorage data
		setConfigs({ userUUID: bootData.CurrentUser && bootData.CurrentUser.uuid ? bootData.CurrentUser.uuid : getConfigs('userUUID') });
		this.setUserStatus(bootData);
		bootData.myapps = getJSONCachedItem('header', 'myapps') || { content: [] };
		if (getConfigs('currency')) {
			bootData.defaultCurrency = getConfigs('currency');
		}
		const bootstrapCache = getJSONCachedItem('header', 'bootstrap');
		setConfigs({
			partner: bootData.CHANNEL_SETTINGS.partner,
			capabilities: bootData.CHANNEL_SETTINGS.capabilities || [],
			theme: bootData.theme,
			companyId: bootData.CompanyInfo && bootData.CompanyInfo.uuid,
			companyInfo: bootData.CompanyInfo || {},
			CHANNEL_SETTINGS: bootData.CHANNEL_SETTINGS || {},
			channel: bootData.CHANNEL_SETTINGS.marketplaceName,
			storefrontBuilderEnabled: bootData.CHANNEL_SETTINGS.storefrontBuilderPublishedThemeEnabled,
			templates: bootData.templates || getConfigs('templates'),
			templatesFromBootData: !!bootData.templates,
			locale: getCurrentLocale(bootData.locale),
			token: getConfigs('token') || getJSONCachedItem('header', 'token'),
			failedToken: getConfigs('failedToken') || getJSONCachedItem('header', 'failedToken'),
			myapps: getJSONCachedItem('header', 'myapps'),
			flags: getJSONCachedItem('header', 'flags'),
			bootstrap: {
				...bootData,
				memberships: bootstrapCache ? bootstrapCache.memberships : [],
			},
			signalsSocketEndpoint: bootData.universalHeaderSignalsSocketEndpoint,
			universalHeaderOAuthClientId: bootData.universalHeaderOAuthClientId,
			useUniversalThemeOnSub: bootData.CHANNEL_SETTINGS.universalHeaderForBusinessUnitsEnabled,
			addonStoreConfiguration: getJSONCachedItem('header', 'addonStoreConfiguration'),
			translations: getJSONCachedItem('header', 'translations') || {},
			globalStyles: getJSONCachedItem('header', 'globalStyles') || {},
			themeHeaderSettings: getJSONCachedItem('header', 'themeHeaderSettings') || {},
		});
		if (getConfigs('sub') !== 'appdirect') {
			setConfigs({ jQueryDisabled: true }, 'bootstrap');
		}
		// setting the marketUrl in bootstrapData for use by ad-theme-customizer-client
		setConfigs({ marketUrl: getConfigs('marketUrl') }, 'bootstrap');
		this.setRegistry();
		this.setStaticData();
	}

	setTemplateData(bootData) {
		setConfigs({
			themeStatus: bootData.themeStatus || getConfigs('mode'),
			templates: bootData.templates || {},
			templatesFromBootData: !!(window.bootstrapData && window.bootstrapData.templates),
			templatesStatus: bootData.templatesStatus || getConfigs('mode'),
		});
	}

	setupBootData() {
		return new Promise((resolve) => {
			const cachedBootData = this.getCachedBootstrapData();
			// Used when bootstrap data is specified during intialization
			if (getConfigs('static')) {
				this.setBootData(getConfigs('staticData'));
				resolve();
			// Used in the monolith since it has bootstrap data in the page
			} else if (cachedBootData && cachedBootData.bootstrap && cachedBootData.bootstrap.CHANNEL_SETTINGS) {
				this.setBootData(cachedBootData.bootstrap);
				this.setTemplateData(cachedBootData);
				if (!window.bootstrapData) {
					this.updateBootData();
				}
				resolve();
			// Used when there's no bootstrap data on the page (appwise, appinsight)
			} else {
				this.updateBootData(resolve);
			}
		});
	}

	updateBootData(waitingForData) {
		this.getBootstrapData().then((data) => {
			if (waitingForData) {
				this.setBootData(data);
				this.setTemplateData(data);
				waitingForData();
			// only update data to be stored in localstorage
			} else {
				setConfigs({ bootstrap: {
					...getConfigs('bootstrap'),
					...data,
				} });
			}
		});
	}
}
