import React from 'react';
import SearchForm from '../components/SearchForm';
import SearchBox from '../components/SearchBox';
import { renderReactComponent } from './template.utils';

const renderAllSearchItems = () => {
	// render search form
	renderReactComponent('.component-search-form', <SearchForm />);
	// render search box
	renderReactComponent('.component-search-box', <SearchBox />);
};

export default {
	renderAllSearchItems,
};
