import { getConfigs } from '../confs/store.conf';
import { FLAGS } from '../confs/flags.conf';
import { getFlag } from './conf.utils';
import { isErrorPage } from './base.utils';
import loadScript from './load-script';
import { needsMerchantNav, loadMerchantNav } from './merchant-nav';

if (
	window.location.hostname === 'appsmartsandbox.byappdirect.com' ||
	window.location.hostname === 'testappsmart.appdirect.com' ||
	window.location.hostname === 'marketplace.appsmart.com' ||
	window.location.hostname === 'catalog.appdirect.com' ||
	window.location.hostname === 'catalogsandbox.byappdirect.com' ||
	window.location.hostname === 'appsmart.cyanopica.appdirect-test.io' ||
	window.location.hostname === 'testcatalog.appdirect.com' ||
	window.location.hostname === 'des.test.devappdirect.me' ||
	window.location.hostname === 'dataops.test.devappdirect.me'
) {
	if (!isErrorPage() && !(window.location.pathname.includes('v2/quotes/versions/') && !!getFlag(FLAGS.QUOTE_VERSION_DETAILS_NEW_DESIGN_FLAG))) {
		loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true)
	}
}

export const loadExternalNav = () => {
	const configs = getConfigs();

	if (
		(/^[0-9a-z-_]([0-9a-z-_]{0,250})\.test\.devappdirect\.me$/gi.test(window.location.hostname) && configs.CHANNEL_SETTINGS?.partner === 'APPSMART') ||
		(/^[0-9a-z-_]([0-9a-z-_]{0,250})\.testcatalog\.appdirect\.com$/gi.test(window.location.hostname) && getConfigs()?.CHANNEL_SETTINGS?.partner === 'APPSMART')
	) {
		if (!isErrorPage()) {
			loadScript(`${location.origin}/appsmart-nav/appsmart-nav.js`, null, true)
		}
	}

	if (
		configs.CHANNEL_SETTINGS.partner === 'APPSMART' ||
		configs.CHANNEL_SETTINGS.partner === 'APPSMARTSANDBOX'
	) {
		return true;
	}
	if (needsMerchantNav()) {
		loadMerchantNav();
		return true;
	}
	return false;
};
