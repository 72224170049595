import React from 'react';
import { renderReactComponent } from '../utils/template.utils';
import ListItem from '../components/ListItem';
import linkUtils from './link.utils';

const getAccountListItem = () => <ListItem className="ad-component_list-item--account" key="account" linkComponent={linkUtils.getAccountLink()} e2eId="unav:manage:account" />;

const getChannelListItem = () => <ListItem className="ad-component_list-item--channel" key="channel" linkComponent={linkUtils.getChannelLink()} e2eId="unav:manage:channel" />;

const getCorporateListItem = () => <ListItem className="ad-component_list-item--corporate" key="corporate" linkComponent={linkUtils.getCorporateLink()} e2eId="unav:manage:corporate" />;

const getDeveloperListItem = () => <ListItem className="ad-component_list-item--developer" key="developer" linkComponent={linkUtils.getDeveloperLink()} e2eId="unav:manage:developer" />;

const getResellerListItem = () => <ListItem className="ad-component_list-item--reseller" key="reseller" linkComponent={linkUtils.getResellerLink()} e2eId="unav:manage:reseller" />;

const getSuperUserListItem = () => <ListItem className="ad-component_list-item--superuser" key="superuser" linkComponent={linkUtils.getSuperUserLink()} e2eId="unav:manage:superuser" />;

const getHomeListItem = () => <ListItem className="ad-component_list-item--home" key="home" linkComponent={linkUtils.getHomeLink()} e2eId="unav:home" />;

const getLoginListItem = () => <ListItem className="ad-component_list-item--login" key="login" linkComponent={linkUtils.getLoginLink()} e2eId="unav:login" />;

const getLogoutListItem = () => <ListItem className="ad-component_list-item--logout" key="logout" linkComponent={linkUtils.getLogoutLink()} e2eId="unav:user:logout" />;

const getMyAppsListItem = () => <ListItem className="ad-component_list-item--myapps" key="myapps" linkComponent={linkUtils.getMyAppsLink()} e2eId="unav:myapps" />;

const getSignUpListItem = () => <ListItem className="ad-component_list-item--signup" key="signup" linkComponent={linkUtils.getSignUpLink()} e2eId="unav:signup" />;

const getSubscriptionListItem = () => <ListItem className="ad-component_list-item--subscription" key="subscription" linkComponent={linkUtils.getSubscriptionLink()} e2eId="unav:subscription" />;

const getCompanyListItem = () => <ListItem className="ad-component_list-item--company" key="company" linkComponent={linkUtils.getCompanyLink()} e2eId="unav:user:my_company" />;

const getProfileListItem = () => <ListItem className="ad-component_list-item--profile" key="profile" linkComponent={linkUtils.getProfileLink()} e2eId="unav:user:my_profile" />;

const getInternalReportsListItem = () => <ListItem className="ad-component_list-item--reports" key="internalReports" linkComponent={linkUtils.getInternalReportsLink()} e2eId="unav:internalReports" />;

const getSettingsListItem = () => <ListItem className="ad-component_list-item--settings" key="settings" linkComponent={linkUtils.getSettingsLink()} e2eId="unav:user:my_settings" />;

const getMobileSwitchListItem = () => <ListItem className="ad-component_list-item--mobile-switch" key="mobile-switch" linkComponent={linkUtils.getMobileSwitchLink()} e2eId="unav:mobile-switch" />;

const getAddonsListItem = () => <ListItem className="ad-component_list-item--addons" key="addons" linkComponent={linkUtils.getAddonsLink()} e2eId="unav:addons" />;

const getConnectorsListItem = () => <ListItem className="ad-component_list-item--connectors" key="connectors" linkComponent={linkUtils.getConnectorsLink()} e2eId="unav:connectors" />;

const renderAllListItems = () => {
	renderReactComponent('.component-account-list-item', getAccountListItem());
	renderReactComponent('.component-channel-list-item', getChannelListItem());
	renderReactComponent('.component-corporate-list-item', getCorporateListItem());
	renderReactComponent('.component-developer-list-item', getDeveloperListItem());
	renderReactComponent('.component-reseller-list-item', getResellerListItem());
	renderReactComponent('.component-superuser-list-item', getSuperUserListItem());
	renderReactComponent('.component-addons-list-item', getAddonsListItem());
	renderReactComponent('.component-connectors-list-item', getConnectorsListItem());

	renderReactComponent('.component-home-list-item', getHomeListItem());
	renderReactComponent('.component-login-list-item', getLoginListItem());
	renderReactComponent('.component-logout-list-item', getLogoutListItem());
	renderReactComponent('.component-myapps-list-item', getMyAppsListItem());
	renderReactComponent('.component-signup-list-item', getSignUpListItem());

	renderReactComponent('.component-subscription-list-item', getSubscriptionListItem());
	renderReactComponent('.component-company-list-item', getCompanyListItem());
	renderReactComponent('.component-profile-list-item', getProfileListItem());
	renderReactComponent('.component-reports-list-item', getInternalReportsListItem());
	renderReactComponent('.component-settings-list-item', getSettingsListItem());
	renderReactComponent('.component-mobile-switch-list-item', getMobileSwitchListItem());
};

export default {
	getAccountListItem,
	getChannelListItem,
	getCorporateListItem,
	getDeveloperListItem,
	getResellerListItem,
	getSuperUserListItem,
	getHomeListItem,
	getLoginListItem,
	getLogoutListItem,
	getMyAppsListItem,
	getSignUpListItem,
	getSubscriptionListItem,
	getCompanyListItem,
	getProfileListItem,
	getInternalReportsListItem,
	getSettingsListItem,
	getMobileSwitchListItem,
	getAddonsListItem,
	getConnectorsListItem,
	renderAllListItems,
};
