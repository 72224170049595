import React from 'react';
import ReactDOM from 'react-dom';
import UniversalSearchBar from 'appdirect-universal-search';
import { isMarketplacePage } from '../../utils/base.utils';
import { getAppWiseApp, getAppInsightsApp } from '../../utils/conf.utils';
import defaultConfigs from '../../confs/widgets/universal-search.conf';
import { setupToken } from '../../confs/token.conf';
import { l } from '../../i18n/l';
import { getConfigs } from '../../confs/store.conf';
import { getGlobalStylesPropValue } from '../../utils/globalstyles.utils';
import './search.widget.scss';

export function hasSearchWidget() {
	return document.querySelector('[data-adb-component="appdirectUniversalSearch"]');
}

let previousContainer;

function setPreviousContainer(container) {
	previousContainer = container;
}

class SearchWidget {
	constructor(el) {
		this.appdirectHeaderContainer = el || document.querySelector('[data-adb-component="appdirectUniversalSearch"]');
		if (this.appdirectHeaderContainer) {
			this.load();
		}

		// Unmounts previous rendering of this bundle.
		if (previousContainer) {
			ReactDOM.unmountComponentAtNode(previousContainer);
		}

		this.onSearchQuery = this.onSearchQuery.bind(this);
		this.onSearchMarketplace = this.onSearchMarketplace.bind(this);
		this.onSearchApps = this.onSearchApps.bind(this);
	}

	load() {
		return setupToken().then(() => {
			this.domRender();
			this.onMarketplaceChangeUrl();
			this.addTestCSS();
		});
	}

	getCapabilities() {
		var capabilities = [];
		if (getAppWiseApp()) {
			capabilities.push('appwise');
		}
		if (getAppInsightsApp()) {
			capabilities.push('appinsight');
		}
		return capabilities;
	}

	getConfig() {
		const options = {
			config: Object.assign({},
				defaultConfigs,
				{
					marketUrl: getConfigs('marketUrl'),
					capabilities: this.getCapabilities(),
					defaultFilter: this.getDefaultFilter(),
				}
			),
		};
		return options;
	}

	domRender() {
		ReactDOM.render(
			this.renderUniversalSearchBar(this.getConfig()),
			this.appdirectHeaderContainer,
			() => { setPreviousContainer(this.appdirectHeaderContainer); }
		);
	}

	onMarketplaceChangeUrl() {
		if (isMarketplacePage()) {
			window.onpopstate = () => {
				this.domRender();
				this.addTestCSS();
			};
		}
	}

	onSearchMarketplace({ detail }) {
		if (getConfigs('onSearchMarketplace')) {
			getConfigs('onSearchMarketplace')({ detail });
			return;
		}
		if (isMarketplacePage() && /^\/(productLines|listing)/.test(window.location.pathname) && !getConfigs('isSFBenabled')) {
			window.location.hash = `#!/list/page/1/search=${encodeURIComponent(detail)}`;
		} else if (getConfigs('isSFBenabled')) {
			let queryParams = [];
			let searchExists = false;

			// keep existing query parameters on the listing page only
			if (/\/listing/.test(window.location.pathname)) {
				queryParams = window.location.search
					.replace(/^[?]/, '')
					.split('&')
					// remove page
					.filter(query => !/page=[0-9]*/.test(query))
					// remove product line
					.filter(query => !/pl=[0-9]*/.test(query))
					// replace existing searched word
					.map((query) => {
						if (/^q=[\S]*/.test(query)) {
							searchExists = true;
							return `q=${encodeURIComponent(detail)}`;
						}
						return query;
					});
			}

			// if we did not previously have a search string, we need to add it
			if (!searchExists) {
				queryParams.push(`q=${encodeURIComponent(detail)}`);
			}

			window.location.assign(`${getConfigs('listingEndpoint')}?${queryParams.join('&')}`);
		} else {
			window.location.assign(`${getConfigs('marketUrl')}/listing?q=${encodeURIComponent(detail)}#!/list/page/1/search=${encodeURIComponent(detail)}`);
		}
	}

	onSearchQuery({ detail }) {
		if (getConfigs('onSearchQuery')) {
			getConfigs('onSearchQuery')({ detail });
			return;
		}

		window.location.assign(`${getConfigs('marketUrl')}/appwise/search/${encodeURIComponent(detail)}`);
	}

	onSearchApps(searchContent) {
		if (getConfigs('onSearchApps')) {
			getConfigs('onSearchApps')(searchContent);
			return;
		}
		this.onSearchQuery(searchContent);
	}

	getProductLineName() {
		var id = location.pathname.split('/')[2];
		var productLines = getConfigs('registeryContent') && getConfigs('registeryContent', 'productLines');
		if (id && productLines && productLines.length) {
			var selectedProduct = productLines.find(product => parseInt(product.id, 10) === parseInt(id, 10));
			if (selectedProduct) {
				return selectedProduct.name;
			}
		}
		return undefined;
	}

	getPlaceholder() {
		const themeSearchPlaceholder = getGlobalStylesPropValue('searchPlaceholder');
		var placeholder = themeSearchPlaceholder || l('search');
		if (/^\/(listing)/.test(window.location.pathname)) {
			placeholder = l('find.applications');
		} else if (/^\/(productLines)/.test(window.location.pathname) && this.getProductLineName()) {
			placeholder = l('find.product.line.applications', this.getProductLineName());
		}
		return placeholder;
	}

	getDefaultValue() {
		if (getConfigs('customSearchQuery')) {
			return unescape(decodeURI(getConfigs('customSearchQuery')()));
		}
		return this.getSearchQuery();
	}

	getDefaultFilter() {
		return isMarketplacePage() ? 'marketplace' : undefined;
	}

	getSearchQuery() {
		var query;
		if (/list\/page\/1\/search/.test(window.location.hash)) {
			var searchValue = window.location.hash.match(/search=([^&]*)/);
			if (searchValue) {
				query = decodeURI(searchValue[1]);
			}
		}
		return query;
	}

	addTestCSS() {
		var searchButton = document.querySelector('.adsearch-universal button');
		if (searchButton) {
			// Added for AppDirect smoke test
			searchButton.classList.add('search_field');
		}
	}

	renderUniversalSearchBar(props) {
		return (
			<UniversalSearchBar
				{...props}
				customSearchQuery={this.getDefaultValue()}
				onSearchQuery={this.onSearchQuery}
				onSearchMarketplace={this.onSearchMarketplace}
				onSearchApps={this.onSearchApps}
				placeholder={this.getPlaceholder()}
			/>
		);
	}
}

export default SearchWidget;
