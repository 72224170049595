const IDENTITY_SUBSTRING_TEST = 'testappsmart.appdirect.com';
const IDENTITY_SUBSTRING_PROD = 'marketplace.appsmart.com';

function isProduction() {
	return (window.location.href && (window.location.href.includes(IDENTITY_SUBSTRING_PROD)));
}

export function isAppSmart() {
	return (window.location.href && (window.location.href.includes(IDENTITY_SUBSTRING_TEST)
        || isProduction()));
}
