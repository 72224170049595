import React from 'react';
import PropTypes from 'prop-types';

const Link = (props) => {
	const {
		isVisible,
		isRelative,
		className = '',
		href,
		id,
		value,
		onClickHandler,
		label,
		autoAction,
		...otherProps
	} = props;
	return (
		isVisible ?
			<a
				className={`ad-component--link ${className}`}
				id={id}
				href={href}
				data-relative={isRelative}
				attr-value={value}
				onClick={onClickHandler}
				data-auto-action={autoAction}
				{...otherProps}
			>
				{label}
			</a>
			: null
	);
};

Link.propTypes = {
	isVisible: PropTypes.bool,
	isRelative: PropTypes.bool,
	className: PropTypes.string,
	autoAction: PropTypes.string,
	href: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	onClickHandler: PropTypes.func,
	label: PropTypes.string.isRequired,
};

export default Link;
