import { getConfigs } from '../../confs/store.conf';
import { executeScript } from '../../utils/base.utils';

export default class HeaderNotifications {

	constructor() {
		return this;
	}

	insert(templates = {}) {
		const el = getConfigs('footer');
		if (el && templates.footer) {
			el.classList.add('ad-universalnav');
			el.innerHTML = templates.footer;
			// This is needed as we need to execute footer scripts after TC5K react components are instatiated
			// There are few footer templates in customizer-themes which have scripts that manipulate those components
			// e.g. hiding a active element in dropdown
			document.addEventListener('universalnav:reactcomponent:instantiated', () => {
				executeScript(el);
			});
		}
		this.triggerLoadedEvent();
	}

	triggerLoadedEvent() {
		document.dispatchEvent(new CustomEvent('universalnav:footer:loaded', {
			detail: {},
		}));
	}
}
