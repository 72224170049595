import React from 'react';
import { getConfigs } from '../confs/store.conf';
import MPLogo from '../components/MPLogo';
import Cart from '../components/Cart';
import IconsContainer from '../components/IconsContainer';
import MobileMenu from '../components/MobileMenu';
import linkUtils from '../utils/link.utils';
import listItemUtils from '../utils/list.item.utils';
import SearchUtils from '../utils/search.utils';
import IconUtils from '../utils/icon.utils';
import listUtils from '../utils/list.utils';
import dropDownUtils from '../utils/dropdown.utils';
import { renderInPlaceOfNode } from '../utils/template.utils';

const triggerReactComponentInstatiatedEvent = () => {
	document.dispatchEvent(new CustomEvent('universalnav:reactcomponent:instantiated', {
		detail: {},
	}));
};

export function instanceReactComponents() {
	const { capabilities, marketUrl, marketUrlApi, reactComponents, userUUID, isDev, locale } = getConfigs();

	/** AD LOGO */
	[...document.querySelectorAll('.unav-mp-logo')].forEach((node) => {
		const redirectHome = node.classList.contains('redirect-home');
		renderInPlaceOfNode(React.createElement(MPLogo, { capabilities, marketUrl, userUUID, redirectHome }), node);
	});

	[...document.querySelectorAll('.unav-icon-mobile')].forEach((node) => {
		renderInPlaceOfNode(React.createElement(MobileMenu, { capabilities, marketUrl, userUUID, isDev }), node);
	});

	[...document.querySelectorAll('.ad-component__cart')].forEach((node, i) => {
		renderInPlaceOfNode(React.createElement(Cart, {
			marketUrl,
			marketUrlApi,
			locale,
			ref: (component) => { reactComponents.general[`cart${i}`] = component; },
		}), node);
	});

	linkUtils.renderAllLinks();
	listItemUtils.renderAllListItems();
	SearchUtils.renderAllSearchItems();
	IconUtils.renderAllIconComponents();
	listUtils.renderAllLists();
	dropDownUtils.renderAllDropDowns();

	const iconsContainer = new IconsContainer();
	triggerReactComponentInstatiatedEvent();
	iconsContainer.init();
}
