import { getConfigs } from '../confs/store.conf';

export const getChannelSetting = (key) => {
	const channelSettings = getConfigs('bootstrap', 'CHANNEL_SETTINGS') || {};
	return key in channelSettings ? channelSettings[key] : '';
};

export const getUserInfo = (key) => {
	const userInfo = getConfigs('bootstrap', 'UserInfo') || {};
	return key in userInfo ? userInfo[key] : '';
};

export const getFlag = (key) => {
	const flags = getConfigs('flags') || {};
	return key in flags ? flags[key] : false;
};

export const getCurrentUserInfo = (key) => {
	const currentUser = getConfigs('bootstrap', 'CurrentUser') || {};
	return key in currentUser ? currentUser[key] : '';
};

export const getCompanyInfo = (key) => {
	const companyInfo = getConfigs('bootstrap', 'CompanyInfo') || {};
	return key in companyInfo ? companyInfo[key] : '';
};

export const userHasRole = (role) => {
	const userInfo = getConfigs('bootstrap', 'UserInfo') || {};
	const roles = userInfo.roles || [];
	return roles.includes(role);
};
/**
 * Returns a boolean value indicating if currently logged in user has any of the roles passed as param
 * @param {Array} roles
 */
export const userHasOneOfRoles = (roles) => {
	const userInfo = getConfigs('bootstrap', 'UserInfo') || {};
	const userRoles = userInfo.roles || [];
	return userRoles.some(role => roles.includes(role));
};

export const channelHasCapability = (capability) => {
	const channelSettings = getConfigs('bootstrap', 'CHANNEL_SETTINGS') || {};
	const capabilities = channelSettings.capabilities || [];
	return capabilities.includes(capability);
};

export const getAppWiseApp = () => {
	// hide app for new marketplaces that have merchantNavEnabled enabled
	if (getChannelSetting('merchantNavEnabled')) {
		return false;
	}

	const myAppsData = (getConfigs('myapps') && getConfigs('myapps').content) || [];
	return myAppsData.find(item => item.universalNavigatorProductType === 'APPWISE');
};

export const getAppInsightsApp = () => {
	// hide app for new marketplaces that have merchantNavEnabled enabled
	if (getChannelSetting('merchantNavEnabled')) {
		return false;
	}

	const myAppsData = (getConfigs('myapps') && getConfigs('myapps').content) || [];
	return myAppsData.find(item => item.universalNavigatorProductType === 'APPINSIGHT');
};

export const getCurrentLocale = (defaultLocale = 'en-US') => {
	const localeFromBootstrap = getConfigs('bootstrap', 'locale');
	const localeFromConfig = getConfigs('locale');

	return localeFromConfig || localeFromBootstrap || defaultLocale;
};

export const hideMySubscriptionLink = () => {
	const isSelfServe = getChannelSetting('selfserveMarketplace');
	const hideLink = channelHasCapability('SELF_SERVE_APPDIRECT_SUBSCRIPTION_MANAGEMENT');
	return isSelfServe && hideLink;
};

export const isSelfServeMarketplace = () => !!getChannelSetting('selfserveMarketplace');

