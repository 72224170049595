import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProductIntegrationMaintenanceBanner extends Component {

	render() {
		const { l } = this.props;
		return (
			<div className="ProductIntegrationMaintenanceBanner"
				style={{
					width: '100%',
					minWidth: '880px',
					height: '48px',
					position: 'relative'
				}}>
				<div
					style={{
						width: '100%',
						minWidth: '880px',
						height: '48px',
						lineHeight: '48px',
						backgroundColor: '#F7D619',
						boxShadow: '0 1px 4px 0 #474747',
						position: 'relative'
					}}>
					<div style={{
						textAlign: 'center',
						color: '#333333',
						fontSize: '14px',
						display: 'fixed'
					}}>
						{l('product.integration.maintenance.header')}
					</div>
				</div>
			</div >
		);
	}
}

ProductIntegrationMaintenanceBanner.propTypes = {
	l: PropTypes.func.isRequired
};

export default ProductIntegrationMaintenanceBanner;
