/**
 * Convert a BCP47 locale string format to a Java locale string format
 * e.g en-US -> en_US
 * @param {string} locale - BCP47 locale string format
 */
export const convertToJavaLocale = (locale) => {
	const regex = /-/gi;
	return locale.replace(regex, '_');
};

/**
 * Convert a Java locale string format to a BCP47 IETF locale string format
 * e.g. en_US -> en-US
 * @param {string} locale - Java locale string format
 */
export const convertToBcp47Locale = (locale) => {
	const regex = /_/gi;
	return locale.replace(regex, '-');
};
