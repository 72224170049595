import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import urlUtils from '../utils/url.utils';
import { CircleSolid } from './Icons';

export default class IconLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			href: props.href,
			isActive: props.isActive,
			count: props.count || '',
			textAfter: props.textAfter || '',
			isCart: props.isCart || false,
		};
	}

	setIconCount(counts) {
		this.setState({ count: counts[this.props.countType] });
	}

	setIconDefault() {
		this.setState({ isActive: false });
	}

	setIconActive(iconName) {
		if (iconName === this.props.type) {
			this.setState({ isActive: true });
		}
	}

	internalClickHandler(event) {
		if (event) {
			event.preventDefault();
		}
		let isOverwritten = false;
		// this override icon links so you can provide your own functions
		// AppWise use this to override icon links to use their own router.
		Object.keys(this.props.links || {}).forEach((key) => {
			if (this.props.type === key) {
				this.props.links[key]();
				isOverwritten = true;
			}
		});
		if (isOverwritten) {
			return;
		}
		// If values are not overwritten, return the current value
		var url = /^http/.test(this.state.href) ? this.state.href : this.props.marketUrl + this.state.href;

		let samePage;
		if (this.props.route) {
			const regex = new RegExp(this.props.route, 'g');
			samePage = regex.test(window.location.href);
		}

		if (!samePage && this.props.openInNewTab) {
			urlUtils.openURLInNewTab(url);
		} else {
			window.location.assign(url);
		}
	}

	render() {
		const count = (Number(this.props.count) >= 0 || this.props.count === '•') ? this.props.count : this.state.count;
		const displayCountNotification = this.props.allowNotifications && count && (count > 0 || count === '•');
		const iconClasses = classNames({
			'ad-icon-link': true,
			'js-link-active': this.state.isActive,
		});
		const listClassName = classNames({
			'ad-universalnav-icons': true,
			'unav-cart-width': this.props.isCart,
		});
		return (
			<li className={listClassName} data-adu-tooltip={this.props.label}>
				<a className={iconClasses} id={this.props.id} onClick={this.internalClickHandler.bind(this)}>
					<i className={this.props.iconClass}>
						{displayCountNotification ? (
							<div className={this.props.isCart ? 'ad-cart-notification ad-is-active' : 'ad-team-notification ad-is-active'}>
								<span className="ad-notification-count">
									{count === '•' ? <CircleSolid /> : count}
								</span>
							</div>
						) : null}
					</i>
					<span className="ad-universalnav-icons-text">{this.props.textAfter}</span>
				</a>
			</li>
		);
	}
}

IconLink.propTypes = {
	id: PropTypes.string,
	isActive: PropTypes.bool,
	href: PropTypes.string.isRequired,
	iconClass: PropTypes.string.isRequired,
	onClickHandler: PropTypes.func,
	allowNotifications: PropTypes.bool,
	marketUrl: PropTypes.string.isRequired,
	label: PropTypes.string,
	countType: PropTypes.string,
	type: PropTypes.string,
	links: PropTypes.object,
	count: PropTypes.string,
	textAfter: PropTypes.string,
	isCart: PropTypes.bool,
	openInNewTab: PropTypes.bool,
	route: PropTypes.string,
};
