import Cookies from 'js-cookie';
import { getConfigs } from '../confs/store.conf';

function getTplKey(widget, item) {
	var userUUID = getConfigs('userUUID');
	var marketUrl = getConfigs('marketUrl') || '';
	var UrlKey = marketUrl.match(/[a-zA-Z]+/g);
	var textUrlKey = UrlKey ? UrlKey.join('') : '';
	return `${textUrlKey}_${userUUID}_${widget}_${item}`;
}

function getDefaultCookie() {
	return Object.assign({}, { widgets: {} });
}

function isWidgetCacheExpired(widget) { // eslint-disable-line
	var cookieStr = Cookies.get('ad_tag_m');
	return !cookieStr;
}

function setWidgetCookieCacheBust(widget) {
	var tagCookieStr = Cookies.get('ad_tag_m');
	var tagCookie = tagCookieStr ? JSON.parse(tagCookieStr) : getDefaultCookie();
	if (!tagCookieStr) {
		tagCookie.widgets[widget] = true;
		var sixHours = new Date(new Date().getTime() + 10 * 60 * 1000 * 40); // eslint-disable-line
		Cookies.set('ad_tag_m', JSON.stringify(tagCookie), {
			expires: sixHours,
			secure:	true,
		});
	}
}

export function setCachedItem(widget, item, data) {
	try {
		localStorage.setItem(getTplKey(widget, item), data);
		setWidgetCookieCacheBust(widget);
	} catch (e) {
		console.error(e); // eslint-disable-line
	}
}

function clearExpiredJSONCachedItems() {
	Object.keys(localStorage).forEach(item => /header_token/.test(item) && localStorage.removeItem(item));
	Object.keys(localStorage).forEach(item => /header_failedToken/.test(item) && localStorage.removeItem(item));
}

export function getJSONCachedItem(widget, item) {
	if (window.localStorage) {
		if (isWidgetCacheExpired(widget)) {
			clearExpiredJSONCachedItems();
		}
		var data = localStorage.getItem(getTplKey(widget, item));
		return data && data !== 'undefined' ? JSON.parse(data) : undefined;
	}
	return undefined;
}

export function setJSONCachedItem(widget, item, data) {
	try {
		localStorage.setItem(getTplKey(widget, item), JSON.stringify(data));
		setWidgetCookieCacheBust(widget);
	} catch (e) {
		console.error(e); // eslint-disable-line
	}
}

export function clearJSONCachedItems() {
	Object.keys(localStorage).forEach(item => /header_token|header_icons_same_tab|header_myapps|header_locale|header_bootstrap|header_templates|header_isCustomTemplate|header_globalStyles|header_themeHeaderSettings|header_addonStoreConfiguration|header_translations|header_sub|header_flags|header_version/.test(item) && localStorage.removeItem(item));
}

