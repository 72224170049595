export default {
	'My.Apps': 'My Apps',
	'List.all.apps': 'See all my apps',
	'return.to.mobile.website': 'Switch to Mobile Site',
	Marketplace: 'Marketplace',
	channel: 'Marketplace',
	corporate: 'Network',
	reseller: 'Reseller',
	appdirectadmin: 'Superuser',
	'My.Profile': 'My Profile',
	search: 'Search',
	Home: 'Home',
	'Activity Feed': 'Activity Feed',
	Messenger: 'Messenger',
	'Sales and Tech Support': 'Sales and Tech Support',
	Insights: 'Insights',
	'My.Company': 'My Company',
	'my.settings': 'My Settings',
	'my.reports': 'My Reports',
	logout: 'Logout',
	BillingAndDistribution: 'Billing and Distribution',
	Account: 'Account',
	Manage: 'Manage',
	'find.applications': 'Find Applications',
	'find.product.line.applications': 'Find {0} Applications',
	'sign.in': 'Log In',
	'hide.toolbar': 'Hide Toolbar',
	'live.toolbar': 'Live',
	'sign.up': 'Register',
	'browse.apps': 'Browse Apps',
	'featured.apps': 'Featured Applications',
	'all.applications': 'All Applications',
	'back.to.main.menu': 'Back to Main Menu',
	'View.All': 'View All',
	'help.center': 'Help Center',
	'privacy.policy': 'Privacy Policy',
	'terms.and.conditions': 'Terms & Conditions',
	Contact: 'Contact',
	'date.days.ago': '{0} days ago',
	'date.day.ago': '1 day ago',
	today: 'Today',
	'last.updated': 'Last Updated:',
	'unav.cart.cartTitle': 'Cart Summary',
	'unav.cart.addToCartTitle': 'Added to Cart',
	'unav.cart.ctaLabel': 'View Cart',
	'unav.cart.itemsLabel': 'items',
	'unav.cart.emptyLabel': 'Your cart is empty.',
	'unav.cart.emptyMessage': 'Why not add something?',
	'unav.cart.vendorPrefix': 'By',
	'unav.cart.editionPrefix': 'Edition:',
	'unav.cart.message.error': 'We were unable to add this item to your cart. Please refresh the page and try again.',
	'unav.cart.domainPrefix': 'Domain:',
	'unav.cart.message.alreadyInCart': 'This item is already in your cart.',
	'impersonation.header': 'You are in impersonation mode and logged in as',
	'impersonation.header.end.session': 'End Session',
	'impersonation.modal.header': 'Confirmation',
	'impersonation.modal.content': 'Are you sure you want to end this session?',
	'impersonation.modal.end.session': 'End Session',
	'impersonation.modal.cancel': 'Cancel',
};
