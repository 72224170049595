import Cookies from 'js-cookie';
import { getWebsiteConfigs } from './confs/base.conf';
import Bootstrapper from './confs/bootstrap.conf';
import * as Widgets from './widgets/widgets';
import { getConfigs, setConfigs, resetBuilderPreviewConfigs } from './confs/store.conf';
import { getDefaultTemplates } from './confs/templates.conf';
import ProductIntegrationMaintenanceBannerContainer from './components/ProductIntegrationMaintenanceBannerContainer';
import MobileBannerContainer from './components/MobileBannerContainer';
import ImpersonateBannerContainer from './components/ImpersonateBannerContainer';
import { getPartialsData } from './utils/template.utils.js';
import { loadExternalNav } from './utils/external-nav';
import { isErrorPage } from './utils/base.utils';

global._babelPolyfill = false;
export default class UniversalHeader {
	constructor(options = {}) {
		this.widgets = {};
		setConfigs(Object.assign({}, getWebsiteConfigs(), options));
		return this;
	}

	isStorefrontUrl = (url) => {
		const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)?(\/(.*?))?\/(\bhome|\bcompare|\bhelp|\bapps\/|\bbrowse\/|\blisting|\bbundles\/|\bpages\/)(.*)/;
		return regex.test(url);
	}

	removePreviewCookiesOnAdminPages = () => {
		Cookies.remove('AppDirect-Builder-Version');
		Cookies.remove('AppDirect-Builder-Theme-Id');
		Cookies.remove('AppDirect-Builder-Version-Id');
		Cookies.remove('AppDirect-Builder-Preview-Mode');
		Cookies.remove('AppDirect-Builder-Preview-HeartBeat');
		Cookies.remove('AppDirect-Product-Preview');
	};

	configure(data, key) {
		if (key) {
			if (!getConfigs()[key]) {
				setConfigs({ [key]: data });
			} else {
				setConfigs({ [key]: data });
			}
		} else {
			setConfigs(data);
		}
		setConfigs({ marketUrlApi: (getConfigs('sub') === 'appdirect' && !getConfigs('absolutePath')) || getConfigs('isStorefrontToolkit') ? window.location.origin : getConfigs('marketUrl') });
	}

	static getDefaultTemplates() {
		return getDefaultTemplates();
	}

	init() {
		if (getConfigs('isMarketplace') !== true) {
			this.removePreviewCookiesOnAdminPages();
			resetBuilderPreviewConfigs();
		}
		if (isErrorPage()) {
			return this;
		}
		this.bootstrapper = new Bootstrapper();
		this.listenEvents();
		this.bootstrapper.setupBootData().then(() => {
			this.load();
		}).catch((e) => {
			console.warn(`bootdata promise error: ${e.message}`); // eslint-disable-line
		});
		return this;
	}

	load() {
		this.loadImpersonateBanner()
		if (loadExternalNav()) {
			return;
		}
		this.loadWidgets();
		this.loadMobileBanner();
		this.loadProductIntegrationMaintenanceBanner();
	}

	call(widget, method, data) {
		if (this.widgets[widget] && this.widgets[widget][method]) {
			this.widgets[widget][method](data);
		}
	}

	reRenderWidget(widget, options) {
		setConfigs({ cache: false });
		this.bootstrapper.setupBootData().then(() => {
			if (this.widgets[widget] && this.widgets[widget].reRender) {
				this.widgets[widget].reRender(options);
			}
		});
	}

	refreshToken(token) {
		if (token) {
			setConfigs({ token });
		}
		if (this.widgets.Header) {
			this.widgets.Header.loadSearchWidget();
		}
	}

	setIconActive(iconName) {
		document.dispatchEvent(new CustomEvent('universalnav:icon:active', {
			detail: {
				icon: iconName,
			},
		}));
	}

	renderFooter(container) {
		if (container) {
			setConfigs({ footer: container });
		}
		if (this.widgets.Header) {
			this.widgets.Header.loadFooter();
		}
	}

	listenEvents() {
		document.addEventListener('universalnav:call', ({ detail }) => this.call(detail.widget, detail.method, detail.data));
		document.addEventListener('universalnav:render', ({ detail }) => this.reRenderWidget(detail.widget, detail.options));
		document.addEventListener('universalnav:render:footer', ({ detail }) => this.renderFooter(detail.container));
		document.addEventListener('universalnav:refresh:token', ({ detail }) => this.refreshToken(detail.token));
	}

	loadWidgets() {
		getConfigs('widgets').forEach((widget) => {
			this.widgets[widget] = new Widgets[widget](getConfigs());
			this.widgets[widget].load();
		});
		// load preview banner used by theme builder
		this.loadBuilderBanner();
	}

	loadBuilderBanner() {
		this.builderBanner = new Widgets.BuilderPreviewBanner().load();
	}

	loadMobileBanner() {
		this.mobileBannerContainer = new MobileBannerContainer().load();
	}

	loadImpersonateBanner(bootstrap) {
		if (bootstrap) {
			setConfigs(bootstrap, 'bootstrap');
		}
		this.ImpersonateBannerContainer = new ImpersonateBannerContainer().load();
	}

	loadProductIntegrationMaintenanceBanner(bootstrap) {
		if (bootstrap) {
			setConfigs(bootstrap, 'bootstrap');
		}
		this.ProductIntegrationMaintenanceBannerContainer = new ProductIntegrationMaintenanceBannerContainer().load();
	}

	static getPartials() {
		return getPartialsData();
	}
}

window.AdUniversalHeader = UniversalHeader;
window.AdUniversalHeaderExportModule = UniversalHeader;