import 'element-closest';
import { getConfigs } from '../../../confs/store.conf';

export function removeDrainableDropdown() {
	var links = document.querySelectorAll('.ad-universalnav .ad-dropdown-link');
	if (links.length) {
		[...links].forEach((dropdown) => {
			var dropdownItems = dropdown.querySelectorAll('.ad-context_menu--menu li');
			if (dropdownItems.length === 1) {
				// Replace the dropdown menu with its content if there is only one item in dropdown menu
				var link = dropdownItems[0].querySelector('.ad-component--link');

				dropdown.setAttribute('href', link.attributes.href.value);
				if (dropdown.querySelector('.ad-component--link')) {
					dropdown.querySelector('.ad-component--link').innerHTML = link.innerHTML;
					dropdown.querySelector('.ad-component--link').setAttribute('href', link.attributes.href.value);
				}
				dropdown.classList.add('ad-dropdown-hide');
			} else if (dropdownItems.length < 1) {
				// Remove the entire dropdown menu item if it is empty
				dropdown.parentNode.removeChild(dropdown);
			}
		});
	}
}

export function removeEmptyMyAppsDropdown() {
	var apps = getConfigs('myapps');
	var filteredApps = apps && apps.content;
	if (filteredApps && filteredApps.length === 0) {
		var emptyAppsContainer = document.querySelector('.ad-universalnav .ad-component__myapps .ad-stack-apps');
		if (emptyAppsContainer) {
			emptyAppsContainer.closest('.ad-universalnav-icons').classList.remove('js-mouseover--trigger');
			emptyAppsContainer.parentNode.removeChild(emptyAppsContainer);
		}
	}
}
