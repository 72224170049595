import 'whatwg-fetch';
import Cookies from 'js-cookie';
import { getConfigs, setConfigs } from './store.conf';
import { getJSONCachedItem } from '../utils/cache.utils';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { getAssignmentsEndpoint } from '../utils/endpoints.utils';

const returnMockData = Cookies.get('mockUserLoggedInHeader');

function getApps() {
	const request = new Request(getAssignmentsEndpoint(returnMockData), { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store', headers: getAuthHeader() });
	return fetch(request).then(resp => resp.ok ? resp.json() : {});
}

function defaultData(resolve) {
	var myapps = getConfigs('bootstrap', 'myapps') || getJSONCachedItem('header', 'myapps') || { content: [] };
	setConfigs({ myapps });
	setConfigs({ myapps }, 'bootstrap');
	resolve({ content: [] });
}

export function setupMyApps() {
	return new Promise((resolve) => {
		if (getConfigs('userUUID') || returnMockData) {
			getApps().then((data) => {
				if (data) {
					const activeApps = returnMockData
						? data
						: data.filter(app => app.launchUrl && app.userEntitlementStatus === 'ACTIVE');
					setConfigs({ myapps: { content: activeApps } });
					setConfigs({ myapps: { content: activeApps } }, 'bootstrap');
					resolve(data);
				} else {
					setConfigs({ userState: 'static:loggedin' });
					defaultData(resolve);
				}
			}).catch(() => {
				setConfigs({ userLoggedOut: true });
				defaultData(resolve);
			});
		} else {
			setConfigs({ userLoggedOut: true });
			defaultData(resolve);
		}
	});
}
