import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { l } from '../i18n/l';
import { getConfigs } from '../confs/store.conf';
import { THEME_PREVIEW, NATIVE_PRODUCT_PREVIEW, NETWORK_PRODUCT_PREVIEW } from '../utils/constants.utils';
import { convertToBcp47Locale } from '../utils/locale';

const previewBannerHeight = '63px';

const icons = {
    tools: "M179.137 322.705c7.632 7.584 19.536 9.6 28.848 4.224 49.152-28.272 90.48-69.792 118.752-118.944 5.376-9.312 3.36-21.216-4.176-28.848l-142.176-142.704c-6.144-6.144-4.032-17.040 4.128-20.064 87.264-32.208 184.080-17.136 248.64 47.424 73.536 73.536 81.744 188.688 30.096 284.112 0 0 500.464 414.88 503.056 416.8 45.072 33.792 69.648 93.36 51.888 156.816-12.912 46.128-50.544 83.76-96.672 96.672-63.456 17.76-123.024-6.816-156.816-51.888-1.92-2.592-416.8-503.056-416.8-503.056-95.472 51.648-210.576 43.44-284.112-30.096-64.56-64.56-79.632-161.376-47.424-248.64 3.024-8.16 13.92-10.224 20.064-4.128l142.704 142.32zM864.001 928c35.346 0 64-28.654 64-64s-28.654-64-64-64c-35.346 0-64 28.654-64 64s28.654 64 64 64v0zM208.285 559.715l-159.327 159.327c-62.249 62.249-62.353 163.564 0.072 225.989l29.939 29.939c62.453 62.453 163.608 62.453 225.999 0.062l184.409-184.409c-38.626-46.6-79.188-95.529-116.031-139.969l-213.426 213.426c-18.031 18.031-46.248 17.595-63.921-0.079-17.796-17.796-17.708-46.292-0.079-63.921l219.407-219.407c-10.35-12.485-19.919-24.026-28.492-34.367-25.743 7.414-52.149 11.929-78.551 13.411zM702.098 415.522c40.216-40.213 79.622-79.619 101.848-101.851 26.967-26.988 49.782-28.45 49.782-28.45 6.679-1.678 15.655-7.783 19.374-13.981l80.165-133.6c3.619-6.030 2.848-15.108-1.946-19.901l-45.063-45.060c-4.584-4.584-13.704-5.664-19.903-1.946l-133.609 80.16c-6.031 3.618-11.96 11.891-12.869 18.254 0 0 1.646 19.688-29.565 50.897l-110.602 110.595c30.805 25.544 65.993 54.715 102.387 84.883z"
};

const ToolsIcon = () => (
  <svg width="17" height="17" viewBox="0 0 1024 1024">
    <path fill="#585858" d={icons.tools} />
  </svg>
);

class BuilderPreviewBanner extends Component {
	constructor(props = {}) {
        super(props);
        this.state = {
            bodyTopPadding: document.body.style.paddingTop,
        };
    }

    componentDidMount() {
        this.getBuilderVersionData();
        this.getPreviewMode();
        const { previewType } = this.props;
        if ([NATIVE_PRODUCT_PREVIEW, NETWORK_PRODUCT_PREVIEW].includes(previewType)) {
            this.getSelectProductData();
        }
        if (this.state.mode === 'fullscreen') {
            document.body.style.paddingTop = previewBannerHeight;
        }
        window.addEventListener('beforeunload', this.setBuilderCookiesHeartbeat);
        window.addEventListener("pagehide", this.setBuilderCookiesHeartbeat);
    }

    componentWillUnmount() {
        // you need to unbind the same listeners that were bound
        this.removeEventListeners();
    }

    removeEventListeners() {
        window.removeEventListener('beforeunload', this.setBuilderCookiesHeartbeat, false);
        window.removeEventListener('pagehide', this.setBuilderCookiesHeartbeat, false);
    }

    getBuilderVersionData() {
        const versionData = Cookies.getJSON('AppDirect-Builder-Version') || {};
        this.setState({
            themeName: versionData.name,
            publishedDate: versionData.publishedDate,
            isLive: versionData.isLive === "true",
        });
    }

    getProductDataApiUrl(previewType) {
        const configs = getConfigs();
        const { marketUrlApi, locale = 'en-US' } = configs;

        const bcp47Locale = convertToBcp47Locale(locale);

        let apiUrl = '';
        switch (previewType) {
            case NATIVE_PRODUCT_PREVIEW:
                apiUrl = `/api/marketplace/v1/products/${this.props.productId}.json`;
                break;
            case NETWORK_PRODUCT_PREVIEW:
                apiUrl = `/api/channel/v1/customizations/preview.json?applicationId=${this.props.productId}&locale=${bcp47Locale}`;
                break;
        }

        return `${marketUrlApi}${apiUrl}`;
    }

    getProductData() {
        return fetch(this.getProductDataApiUrl(this.props.previewType), {
                mode: 'cors',
                cache: 'no-store',
            })
            .then(resp => resp.ok ? resp.json() : {});
    }

    getSelectProductData() {
        this.getProductData().then(data => {
            const updateDaysAgo = this.getDateDifference(data.lastModified);
            this.setState({
                productName: data.name,
                lastModified: updateDaysAgo,
            });
        });
    }

    getDateDifference(lastModified) {
        const currentDate = Date.now();
        const lastModifiedDate = new Date(currentDate - lastModified);
        // (1000 * 60 * 60  * 24) is one day in milliseconds
        const lastModifiedDaysAgo = Math.floor(lastModifiedDate / (1000 * 60 * 60 * 24));
        switch (lastModifiedDaysAgo) {
            case 0:
                return `${l('last.updated')} ${l('today')}`;
            case 1:
                return `${l('last.updated')} ${l('date.day.ago')}`;
            default:
                return `${l('last.updated')} ${l('date.days.ago', lastModifiedDaysAgo)}`;
        }
    }

    getPreviewMode() {
        const mode = Cookies.get('AppDirect-Builder-Preview-Mode') || 'fullscreen';
        this.setState({
            mode,
        })
    }

    setPreviewMode = () => {
        const { mode } = this.state;
        const currentMode = mode === 'fullscreen' ? 'minimized' : 'fullscreen';
        this.setState({
            mode: currentMode,
        });
        Cookies.set('AppDirect-Builder-Preview-Mode', currentMode, { secure: true });
    }

    getReturnLocation() {
        const { productId } = this.props;
        const productData = Cookies.getJSON('AppDirect-Product-Preview') || {};
        switch (productData.portal) {
            case 'channel':
                return `/channel/editApp/${productId}`;
            case 'cms':
                return `/cms/editApp/${productId}`;
            case 'customize':
                return `/channel/customizeApp/${productId}`;
            default:
                return '/home';
        }
    }

    cancelPreview = () => {
        const { previewType } = this.props;
        let closeLink = '';
        if (previewType === THEME_PREVIEW) {
            closeLink = `/branding/themes`;
        } else if ([NATIVE_PRODUCT_PREVIEW, NETWORK_PRODUCT_PREVIEW].includes(previewType)) {
            closeLink = this.getReturnLocation();
        }
        this.removeBuilderCookies();
        this.removeEventListeners();
        window.location.assign(closeLink);
    }


    setBuilderCookiesHeartbeat = () =>  {
        const in1min = new Date(new Date().getTime() + 1 * 60 * 1000);
        Cookies.set('AppDirect-Builder-Preview-HeartBeat', true, { expires: in1min, secure: true });
    }

    removeBuilderCookies = () =>  {
        Cookies.remove('AppDirect-Builder-Version');
        Cookies.remove('AppDirect-Builder-Theme-Id');
        Cookies.remove('AppDirect-Builder-Version-Id');
        Cookies.remove('AppDirect-Builder-Preview-Mode');
        Cookies.remove('AppDirect-Builder-Preview-HeartBeat');
        Cookies.remove('AppDirect-Product-Preview');
    }

    setBodyTopPadding() {
        const { mode, bodyTopPadding = ''} = this.state;
        document.body.style.paddingTop = mode === 'fullscreen' ? previewBannerHeight : bodyTopPadding;
    }

    render() {
        const { themeName, publishedDate, mode, isLive, productName, lastModified } =  this.state;
        const { previewType } = this.props;
        const showFullScreen = mode === 'fullscreen';
        this.setBodyTopPadding();
        if (previewType === THEME_PREVIEW) {
            return (
                <div>
                    { showFullScreen ?
                        <div className="sfbPreviewContainer">
                            <div className="sfbCancelPreview" onClick={this.cancelPreview} data-auto-action='cancel:preview'>
                                ×
                            </div>
                            <div className="sfbThemeInformations">
                                <div className="sfbThemeTitle" data-auto-container='toolbar-preview-title'>
                                    {themeName}
                                    { isLive
                                        ? <span className="sfbLiveStatus" type="active"><span></span>{l('live.toolbar')}</span>
                                        : null
                                    }
                                </div>
                                <div className="sfbThemePublishedTime" data-auto-container='toolbar-preview-time'>{publishedDate}</div>
                            </div>
                            <div className="sfbHideToolbar" onClick={this.setPreviewMode} data-auto-action='hide:toolbar'>{l('hide.toolbar')}</div>
                        </div>
                        :
                        <button className="sfbShowPreview" onClick={this.setPreviewMode} data-auto-action='show:toolbar'>
                            <ToolsIcon />
                        </button>
                    }
                </div>
            );
        }
        if ([NATIVE_PRODUCT_PREVIEW, NETWORK_PRODUCT_PREVIEW].includes(previewType)) {
            return (
                <div className="sfbPreviewContainer">
                    <div className="sfbCancelPreview" onClick={this.cancelPreview} data-auto-action='cancel:preview'>
                        ×
                    </div>
                    <div className="sfbPreviewInformations">
                        <div className="sfbProductTitle" data-auto-container='toolbar-preview-title'>
                            {productName}
                        </div>
                        <div className="sfbPreviewLastModified" data-auto-container='toolbar-preview-time'>{lastModified}</div>
                    </div>
                </div>
            );
        }
    }
}

BuilderPreviewBanner.propTypes = {
	previewType: PropTypes.string,
    productId: PropTypes.string
};

export default BuilderPreviewBanner;
