import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Select extends Component {
	constructor(props = {}) {
		super(props);
		this.state = {
			value: props.value
		};
	}

	onChangeHandler = (event) => {
		event.preventDefault();
		this.setState({ value: event.target.value });
		if (this.props.onChangeHandler) {
			this.props.onChangeHandler(event.target.value);
		}
	}

	render() {
		const { isVisible, selectItems } = this.props;
		return (
			isVisible
				? <select className={classNames('ad-component_select', this.props.className)}
					value={this.state.value}
					onChange={this.onChangeHandler}>
					{selectItems}
				</select>
				: null
		)
	}
}

Select.propTypes = {
	isVisible: PropTypes.bool,
	className: PropTypes.string,
	onChangeHandler: PropTypes.func,
	value: PropTypes.string,
	selectItems: PropTypes.arrayOf(PropTypes.element)
};

export default Select;
