
var store = {};

export function getConfigs(key, prop) {
	if (prop && store[key]) {
		return store[key][prop];
	}
	if (key) {
		return store[key];
	}
	return store;
}

export function setConfigs(data, key) {
	if (key) {
		store = {
			...store,
			[key]: {
				...store[key],
				...data,
			},
		};
	} else {
		store = {
			...store,
			...data,
		};
	}
	return store;
}

export function clearConfigs() {
	store = {};
}

export function resetBuilderPreviewConfigs() {
	setConfigs({
		builderPreviewData: {},
		mode: 'published',
	});
}
