import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'js-cookie';

import { l } from '../i18n/l';
import MyApp from './MyApp';
import IconLink from './IconLink';


const ROUTE = 'myapps';
const returnMockData = Cookies.get('mockUserLoggedInHeader');
class MyApps extends Component {
	constructor(props = {}) {
		super(props);
		this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
		this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
		this.state = {
			isDropdownActive: false,
			href: props.userUUID ? `${props.marketUrl}/${ROUTE}` : `${props.marketUrl}`,
		};
	}

	handleOnMouseEnter() {
		this.setState({ isDropdownActive: true });
	}

	handleOnMouseLeave() {
		this.setState({ isDropdownActive: false });
	}

	isActive() {
		return window.location.pathname === '/myapps';
	}

	appsVisibilityFitler(app) {
		const hasLaunchUrl = (app.launchUrl && app.launchUrl !== '') || returnMockData;
		return hasLaunchUrl;
	}

	render() {
		const myAppsClasses = classNames({
			'ad-universalnav-icons ad-component_dropdown ad-component__myapps': true,
			'js-dropdown-active': this.state.isDropdownActive,
		});
		const { appsCollection, appsToDisplay, marketUrl, openInNewTab } = this.props;
		return (
			<div
				className={myAppsClasses}
				onMouseEnter={this.handleOnMouseEnter}
				onMouseLeave={this.handleOnMouseLeave}
			>
				<IconLink
					href={this.state.href}
					iconClass="ad-icon-grid"
					id="myapps"
					route={ROUTE}
					isActive={this.isActive()}
					marketUrl={marketUrl}
					openInNewTab={openInNewTab}
				/>
				{
					(appsCollection.length && !this.isActive()) ?
						<div className="ad-component_list">
							<div className="ad-component_list--top-hover-shim" />
							<ul className="ad-stack-apps ad-stack-apps--with-button">
								{appsCollection
									.filter(this.appsVisibilityFitler)
									.slice(0, appsToDisplay)
									.map((app, i) =>
									<MyApp
										key={`myapp${i}`}
										imageUrl={app.imageUrl}
										launchUrl={app.launchUrl}
										appName={app.name}
									/>
								)}
								<li className="ad-stack-apps--item-button">
									<a href={this.state.href}>{l('List.all.apps')}</a>
								</li>
							</ul>
						</div>
					: null

				}
			</div>
		);
	}
}

MyApps.propTypes = {
	appsCollection: PropTypes.arrayOf(PropTypes.object).isRequired,
	marketUrl: PropTypes.string.isRequired,
	appsToDisplay: PropTypes.number.isRequired,
	userUUID: PropTypes.string,
	openInNewTab: PropTypes.bool
};

export default MyApps;
