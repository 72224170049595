import { attachEvent } from '../../../utils/base.utils';
import { getConfigs } from '../../../confs/store.conf';

const OVERRIDABLE_LINKS = [{
	cssClass: '.ad-component_list-item--logout',
	func: 'logout-link',
}, {
	cssClass: '.ad-component_list-item--login',
	func: 'login-link',
}];

function overrideLink(links = [], el) {
	let override = false;
	// check if there is an override when init
	links.forEach((link) => {
		// look for link parent class
		if (el.closest(link.cssClass)) {
			// get custom function if available
			const url = (el.attributes.href && el.attributes.href.value) ? el.attributes.href.value : '';
			const func = getConfigs('header', 'links') && getConfigs('header', 'links')[link.func];
			const currentLink = url.startsWith('http') ? url : getConfigs('marketUrl') + url;
			if (func) {
				// we pass the current link since 3rd parties (AppHelp, etc) may want to follow throught after their custom logic
				func(currentLink, el);
				override = true;
			}
		}
	});
	return override;
}

export function loadLinksEvents() {
	attachEvent('click', '.ad-universalnav .ad-component_list-item .ad-component--link', (evt, el) => {
		// add logout override, used by 3rd party to logout from their apps
		const destination = el.attributes.href ? el.attributes.href.value : '';
		const target = el.attributes.target;
		const isRelative = !!el.attributes['data-relative'];
		const baseUrl = !isRelative && !destination.startsWith('http') ? getConfigs('marketUrl') : '';

		if (overrideLink(OVERRIDABLE_LINKS, el)) {
			return;
		}
		if (destination && destination !== '#') {
			if (target && target.value === '_blank') {
				window.open(`${baseUrl}${destination}`);
			} else {
				window.location = `${baseUrl}${destination}`;
			}
		}
	});
}
