import { setCompanyDropdown, changeCompanyContext } from './behaviors/company';
import { removeDrainableDropdown, removeEmptyMyAppsDropdown } from './behaviors/drainable-dropdown';
import { loadDropddownEvents, loadMouseOverEvents } from './behaviors/dropdown';
import { loadLinksEvents } from './behaviors/links';

export default class HeaderNotifications {

	constructor() {
		this.setStatesUI();
		this.loadEvents();
	}

	setStatesUI() {
		setCompanyDropdown();
		removeDrainableDropdown();
		removeEmptyMyAppsDropdown();
		changeCompanyContext();
	}

	loadEvents() {
		loadDropddownEvents();
		loadLinksEvents();
		loadMouseOverEvents();
	}
}
