import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { l } from '../i18n/l';
import IconLink from './IconLink';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { convertToJavaLocale } from '../utils/locale';
import CartTooltip from './CartTooltip';
import { alertTypes } from './CartAlert';

const CHECKOUT_URL = '/checkout/cart';
const CACHE_ITEMS_KEY = 'cartItems';
const CACHE_ITEMSCOUNT_KEY = 'itemsCount';
const CACHE_SUBTOTAL_KEY = 'subtotal';
const CACHED_KEYS = [CACHE_ITEMS_KEY, CACHE_ITEMSCOUNT_KEY, CACHE_SUBTOTAL_KEY];

class Cart extends Component {
	constructor(props = {}) {
		super(props);
		this.showCart = this.showCart.bind(this);
		this.hideCart = this.hideCart.bind(this);
		this.fetchCart = this.fetchCart.bind(this);
		this.redirectCheckout = this.redirectCheckout.bind(this);
		this.clearCache = this.clearCache.bind(this);
		this.parseCartData = this.parseCartData.bind(this);
		const cachedCartItems = this.getCachedCartItems();
		const cachedItemsCount = this.getCachedItemsCount();

		this.state = {
			isDropdownActive: false,
			isPageMaskActive: false,
			isAddToCartActive: false,
			alertType: null,
			cartItems: cachedCartItems,
			count: cachedItemsCount,
			isCartReady: cachedCartItems.length > 0,
		};
	}

	componentDidMount() {
		this.listenEvents();
		if (!this.state.isAddToCartActive) {
			this.getCart(true);
		}
	}

	componentDidUpdate() {
		if (this.state.isDropdownActive) {
			this.hideOtherDropdowns();
			this.handleOverflow();
		}
	}

	getCachedCartItems() {
		try {
			const cartItemsString = sessionStorage.getItem(CACHE_ITEMS_KEY);
			return JSON.parse(cartItemsString) || [];
		} catch (e) {
			return [];
		}
	}

	getCachedItemsCount() {
		try {
			const cartItemsCountString = sessionStorage.getItem(CACHE_ITEMSCOUNT_KEY);
			return JSON.parse(cartItemsCountString) || 0;
		} catch (e) {
			return 0;
		}
	}

	getCart(forceBackground = false) {
		if (this.abortController) {
			this.abortController.abort();
		}
		if (!forceBackground) {
			this.setState({
				isCartReady: false,
			}, this.fetchCart);
		} else {
			this.fetchCart();
		}
	}


	setCache(type, data) {
		sessionStorage.setItem(type, data);
	}

	getSelectedEdition(selectedEditionId, editions) {
		for (let i = 0; i < editions.length; i += 1) {
			if (editions[i].id === selectedEditionId) {
				return editions[i].name;
			}
		}
		return null;
	}

	getProductListHeight() {
		const products = document.querySelectorAll('.cart-product');
		let totalProductHeight = 0;
		products.forEach((element) => {
			totalProductHeight += element.offsetHeight;
		});
		return totalProductHeight;
	}

	fetchCart() {
		const { marketUrlApi, locale } = this.props;
		const url = locale
			? `${marketUrlApi}/api/checkout/v1/shoppingCarts/itemDetails?forceReturnAllPricingPlans=false&locale=${convertToJavaLocale(locale)}`
			: `${marketUrlApi}/api/checkout/v1/shoppingCarts/itemDetails?forceReturnAllPricingPlans=false&locale=en_US`;
		this.abortController = new AbortController();
		const signal = this.abortController.signal;
		return fetch(url, { credentials: getCredentialsHeader(), cache: 'no-store', headers: getAuthHeader(), signal })
			.then((resp) => {
				if (resp.ok) {
					return resp.json();
				}
				return {};
			})
			.then((data = {}) => {
				if (data.items && data.itemDetails) {
					// pickup most recent cart
					const cartItems = data.items.map(item => this.parseCartData(item, data.itemDetails, data.itemEditionsMap));
					const count = data.itemsCount || 0;
					this.setCache(CACHE_ITEMS_KEY, JSON.stringify(cartItems));
					this.setCache(CACHE_ITEMSCOUNT_KEY, JSON.stringify(count));
					this.setState({
						cartItems,
						count,
						isCartReady: true,
					});
				} else {
					const isCartEmpty = Object.entries(data).length === 0;
					this.clearCache();
					this.setState({
						cartItems: [],
						count: 0,
						isCartReady: true,
						isAddToCartActive: false,
						// Do not return error on empty cart
						alertType: isCartEmpty ? null : alertTypes.GENERIC_ERROR,
						isDropdownActive: !isCartEmpty,
					});
				}
			});
	}

	handleAddons(addons, itemDetails) {
		const addonsData = [];
		if (addons && itemDetails) {
			addons.forEach((addon) => {
				const { branding } = itemDetails[addon.id];
				const { name, iconUrl, storefrontUrl } = branding;
				const addonObj = {
					addonName: name,
					iconSrc: iconUrl || null,
					addonUrl: storefrontUrl,
				};
				addonsData.push(addonObj);
			});
		}
		return addonsData;
	}

	handleDomains(item, itemDetails) {
		const { id, customAttributes = {} } = item;
		const { branding } = itemDetails[id];
		const { name, iconUrl } = branding;
		const { vendorRequiredFields = {} } = customAttributes;
		const { DOMAIN = [] } = vendorRequiredFields;
		return {
			productName: name,
			isDomain: true,
			productData: {
				subHeader1: `${l('unav.cart.domainPrefix')} ${DOMAIN[0]}`,
				iconSrc: iconUrl,
			},
		};
	}

	handleEditions(item, itemDetails, itemEditionsMap) {
		const { id, childItems } = item;
		const { branding, editions } = itemDetails[id];
		const { name, iconUrl, vendor, storefrontUrl } = branding;
		const selectedEditionId = itemEditionsMap[id].selectedEditionId;
		const selectedEditionName = this.getSelectedEdition(selectedEditionId, editions);
		return {
			productName: name,
			productData: {
				subHeader1: `${l('unav.cart.vendorPrefix')} ${vendor}`,
				subHeader2: `${l('unav.cart.editionPrefix')} ${selectedEditionName}`,
				iconSrc: iconUrl,
				productUrl: storefrontUrl,
			},
			addons: childItems && childItems.length ? this.handleAddons(childItems, itemDetails) : null,
		};
	}

	isDomainProduct(item) {
		try {
			return item.customAttributes.vendorRequiredFields.DOMAIN;
		} catch (e) {
			return false;
		}
	}

	parseCartData(item, itemDetails, itemEditionMap) {
		return this.isDomainProduct(item) ? this.handleDomains(item, itemDetails) : this.handleEditions(item, itemDetails, itemEditionMap);
	}

	clearCache() {
		CACHED_KEYS.forEach(key => sessionStorage.removeItem(key));
	}

	redirectCheckout() {
		this.clearCache();
		window.location = `${this.props.marketUrl}${CHECKOUT_URL}`;
	}

	listenEvents() {
		document.addEventListener('universalnav:update:cart', this.getCart);
		document.addEventListener('universalnav:hide:cart', this.hideCart);
		document.addEventListener('universalnav:error:cart', () => this.setState({
			alertType: alertTypes.GENERIC_ERROR,
			isAddToCartActive: false,
			isDropdownActive: true,
		}));
		document.addEventListener('universalnav:add:cart', () => this.setState({
			isDropdownActive: true,
			isAddToCartActive: true,
			alertType: null,
		}, this.getCart));
		document.addEventListener('universalnav:alreadyInCart:cart', () => this.setState({
			alertType: alertTypes.ALREADY_INCART,
			isAddToCartActive: false,
			isDropdownActive: true,
		}));
	}

	hideOtherDropdowns() {
		const activeManageDropdown = document.querySelector('.ad-component_dropdown.manage-dropdown');
		const activeUserDropdown = document.querySelector('.user-dropdown.js-primary-nav-user-menu');
		if (activeManageDropdown) activeManageDropdown.classList.remove('js-dropdown-active');
		if (activeUserDropdown) activeUserDropdown.classList.remove('js-dropdown-active');
	}

	showCart() {
		if (!this.state.isDropdownActive) {
			this.setState({ isDropdownActive: true });
		}
	}

	isOverflowActive() {
		const cartContentViewHeight = document.querySelector('.cart-content').offsetHeight;
		return this.getProductListHeight() > cartContentViewHeight;
	}

	handleOverflow() {
		const ctaContainer = document.querySelector('.cart-action-container');
		if (!ctaContainer) return;
		if (this.isOverflowActive()) {
			ctaContainer.classList.add('js-shadowbox-active');
		} else {
			ctaContainer.classList.remove('js-shadowbox-active');
		}
	}

	hideCart() {
		this.setState({
			isPageMaskActive: false,
			isDropdownActive: false,
			isAddToCartActive: false,
			alertType: null,
		});
	}

	hideCartOnMouseLeave(e) {
		const tooltip = document.querySelector('.cart-tooltip');

		const isCursorInTooltipBound = () => {
			const tooltipRect = tooltip.getBoundingClientRect();
			const threshold = 15;
			const tooltipTop = tooltipRect.top - threshold;
			const tooltipRight = tooltipRect.right - threshold;
			return e.clientX >= tooltipRect.left && e.clientX <= tooltipRight && e.clientY >= tooltipTop && e.clientY <= tooltipRect.bottom;
		};

		if (tooltip && !isCursorInTooltipBound()) {
			document.dispatchEvent(new CustomEvent('universalnav:hide:cart'));
		}
	}

	showPageMask() {
		this.setState({ isPageMaskActive: true });
	}

	render() {
		const myClasses = classNames({
			'ad-component_dropdown ad-component__cart': true,
			'js-dropdown-active': this.state.isDropdownActive,
		});
		const tooltipDataFormat = {
			items: this.state.cartItems,
			i18n: {
				cartTitle: l('unav.cart.cartTitle'),
				addToCartTitle: l('unav.cart.addToCartTitle'),
				ctaLabel: l('unav.cart.ctaLabel'),
				itemsLabel: l('unav.cart.itemsLabel'),
				emptyLabel: l('unav.cart.emptyLabel'),
				emptyMessage: l('unav.cart.emptyMessage'),
				errorMessage: l('unav.cart.message.error'),
				alreadyInCartMessage: l('unav.cart.message.alreadyInCart'),
			},
		};

		return (
			<div
				className={myClasses}
				onMouseEnter={this.showCart}
				onMouseLeave={this.hideCartOnMouseLeave}
			>
				{this.state.isPageMaskActive && <div className="page-mask" />}
				<IconLink
					href={`${this.props.marketUrl}${CHECKOUT_URL}`}
					iconClass="ad-icon-add-to-cart"
					id="cart"
					type="cartIcon"
					allowNotifications
					links={{ cartIcon: this.redirectCheckout }}
					count={String(this.state.count)}
					textAfter={l('Cart')}
					marketUrl={this.props.marketUrl}
					isCart
				/>
				{this.state.isDropdownActive &&
					<CartTooltip
						isCTA={this.state.isAddToCartActive}
						alertType={this.state.alertType}
						isCartDataReady={this.state.isCartReady}
						count={String(this.state.count)}
						tooltipData={tooltipDataFormat}
						marketUrl={this.props.marketUrl}
						clearCacheFn={this.clearCache}
						closeCartHandler={this.hideCart}
					/>
				}
			</div>
		);
	}
}

Cart.propTypes = {
	marketUrl: PropTypes.string.isRequired,
	marketUrlApi: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
};

export default Cart;
