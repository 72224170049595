/**
 * Language values, same across all languages so bringing them in here, they are sometimes customized by marketplace so they can be easily extended here.
 */
/* eslint-disable */
export default {
	'cs': 'čeština',
	'cs-CZ': 'čeština (Czechia)',
	'id': 'Indonesian',
	'id-ID': 'Indonesian (Indonesia)',
	'da': 'Dansk',
	'da-DK': 'Dansk (Danmark)',
	'de': 'Deutsch',
	'de-AT': 'Deutsch (Österreich)',
	'de-CH': 'Deutsch (Schweiz)',
	'de-DE': 'Deutsch (Deutschland)',
	'de-GR': 'Deutsch (Griechenland)',
	'de-LU': 'Deutsch (Luxemburg)',
	'el-GR': 'Ελληνικά (Ελλάδα)',
	'en': 'English',
	'en-AU': 'English (Australia)',
	'en-CA': 'English (Canada)',
	'en-GB': 'English (United Kingdom)',
	'en-IE': 'English (Ireland)',
	'en-IN': 'English (India)',
	'en-MT': 'English (Malta)',
	'en-NZ': 'English (New Zealand)',
	'en-PH': 'English (Philippines)',
	'en-SG': 'English (Singapore)',
	'en-US': 'English (United States)',
	'en-ZA': 'English (South Africa)',
	'es': 'Español',
	'es-419': 'Español (Latinoamérica)',
	'es-AR': 'Español (Argentina)',
	'es-BO': 'Español (Bolivia)',
	'es-CL': 'Español (Chile)',
	'es-CO': 'Español (Colombia)',
	'es-CR': 'Español (Costa Rica)',
	'es-CU': 'Español (Cuba)',
	'es-DO': 'Español (República Dominicana)',
	'es-EC': 'Español (Ecuador)',
	'es-SV': 'Español (El Salvador)',
	'es-ES': 'Español (España)',
	'es-GT': 'Español (Guatemala)',
	'es-HN': 'Español (Honduras)',
	'es-MX': 'Español (México)',
	'es-NI': 'Español (Nicaragua)',
	'es-PA': 'Español (Panamá)',
	'es-PE': 'Español (Perú)',
	'es-PR': 'Español (Puerto Rico)',
	'es-PY': 'Español (Paraguay)',
	'es-US': 'Español (Estados Unidos)',
	'es-UY': 'Español (Uruguay)',
	'es-VE': 'Español (Venezuela)',
	'fi': 'Suomi',
	'fi-FI': 'Suomi (Suomi)',
	'fr': 'Français',
	'fr-BE': 'Français (Belgique)',
	'fr-CA': 'Français (Canada)',
	'fr-CH': 'Français (Suisse)',
	'fr-FR': 'Français (France)',
	'fr-LU': 'Français (Luxembourg)',
	'hu-HU': 'Magyar',
	'it': 'Italiano',
	'it-CH': 'Italiano (Svizzera)',
	'it-IT': 'Italiano (Italia)',
	'ja': '日本語',
	'ja-JP': '日本語 (日本)',
	'ja-JP-x-lvariant-JP': '日本語 (日本,JP)',
	'ko': '한국어',
	'ko-KR': '한국어 (대한민국)',
	'lt': 'Lietuvių',
	'lt-LT': 'Lietuvių (Lietuva)',
	'nn-NO': 'Norsk (Norge, nynorsk)',
	'no': 'Norsk',
	'no-NO': 'Norsk (Norge)',
	'nl-NL': 'Nederlands (Nederland)',
	'nl-BE': 'Nederlands (Belgium)',
	'nl': 'Nederlands',
	'pt': 'Português',
	'pt-BR': 'Português (Brasil)',
	'pt-PT': 'Português (Portugal)',
	'ru': 'Русский',
	'ru-RU': 'Pусский (Россия)',
	'sq': 'Shqip',
	'sq-AL': 'Shqip (Shqipëria)',
	'sv': 'Svenska',
	'sv-SE': 'Svenska',
	'th': 'ไทย',
	'th-TH': 'ไทย (ประเทศไทย)',
	'zh': '中文',
	'zh-CN': '中文 (大陆简体)',
	'zh-HK': '中文 (香港繁體)',
	'zh-SG': '中文 (马新简体)',
	'zh-TW': '中文 (台灣正體)',
	'hr': 'Hrvatski',
	'tlh': 'Klingon',
	'tr': 'Türkçe',
	'tr-TR': 'Türkçe (Türkiye)',
	'ro': 'Romanian'
};
