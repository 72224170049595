import 'element-closest';
import 'whatwg-fetch';
import 'custom-event-polyfill';
import initTranslations from '../../confs/translations.conf';
import { setupUnavConfigurations } from '../../confs/unav.conf';
import { setupTemplates } from '../../confs/templates.conf';
import { setupMyApps } from '../../confs/myapps.conf';
import { setupCSSproperties } from '../../confs/properties.conf';
import setupTheme from '../../confs/theme.conf';
import { setupMemberships } from '../../confs/memberships.conf';
import { setupToken } from '../../confs/token.conf';
import { appendCSS, executeScript, isJQueryNeeded, parseProperties } from '../../utils/base.utils';
import { parseTemplateWithReactTags, parseTemplateWithTc5kComponents, parseTemplateWithAssets } from '../../utils/template.utils';
import SearchWidget from '../search/search.widget';
import HeaderNotifications from './header.notifications';
import { getJSONCachedItem, setJSONCachedItem, clearJSONCachedItems } from '../../utils/cache.utils';
import { getConfigs, setConfigs } from '../../confs/store.conf';
import HeaderBehaviors from './header.behaviors';
import Footer from '../footer/footer.widget';
import { instanceReactComponents } from '../../widgets/component-helper';
import { addGlobalStyling } from '../../utils/globalstyles.utils';
import loadScript from '../../utils/load-script';
import initFlag from '../../confs/flags.conf';

const CSS_NORMALIZER = '.ad-universalnav { font-size: 14px; line-height:1.6em; } .ad-universalnav p:first-child { margin-top: 0; }';
const ssoLoginPages = {
	'/twofactor/setup': true,
	'/twofactor/login': true,
	'/login': true
}

export default class Header {
	constructor() {
		window.ad_header_rendered = false;
		this.clearVersionCache();
		if (getConfigs('mode') !== 'preview') {
			this.cachedTemplates = getConfigs('cache') ? getJSONCachedItem('header', 'templates') : null;
		}
		setConfigs({ isCustomTemplate: getJSONCachedItem('header', 'isCustomTemplate') });
	}

	load() {
		if (this.cachedTemplates && this.cachedTemplates.headerIn && getConfigs('cache') && this.isSameLocale()) {
			this.loadContent(this.cachedTemplates);
		}
		Promise.all([
			initFlag(),
			initTranslations(),
			setupUnavConfigurations(),
			setupToken(),
			setupMemberships(),
			setupTemplates(),
			setupCSSproperties(),
			setupMyApps(),
			setupTheme(),
		]).then(() => {
			this.loadContent();
			if ((getConfigs('userUUID') && getConfigs('userState') !== 'static:loggedin') || getConfigs('userLoggedOut')) {
				this.saveToCache();
			}
		}).catch(e => console.warn(`handled myapps and props promise error: ${e.message}`)); // eslint-disable-line
	}

	isSameLocale() {
		var savedLocale = getJSONCachedItem('header', 'locale');
		if (!savedLocale || (savedLocale === getConfigs('locale'))) {
			return true;
		}
		return false;
	}

	reRender(options = {}) {
		this.cachedTemplates = null;
		window.ad_header_rendered = false;
		setConfigs({ cache: false });
		setConfigs({ useTemplate: options.template });
		this.load();
	}

	loadContent(templates) {
		this.setTranslations();
		this.templates = this.parseTpl(templates || getConfigs('templates'));
		var cssHeader = templates ? templates.cssHeader : getConfigs('templates', 'cssHeader');
		this.currentTemplate = this.getCurrentHeaderTpl(this.templates);
		this.currentParsedCSS = cssHeader ? this.parseCSS(cssHeader) : cssHeader;
		this.bodyCSS = templates ? templates.css : getConfigs('templates', 'css');
		if (!window.ad_header_rendered) {
			window.ad_header_rendered = true;
			this.loadGlobalStyles();
			this.loadCSS();
			this.loadTemplates(this.currentTemplate, this.templates.footer);
		}
	}

	loadHeaderFooterTemplates(currentTemplate) {
		this.appendHtml(currentTemplate);
		this.loadFooter();
		instanceReactComponents();
		this.loadSearchWidget();
		this.loadNotifications();
		this.behaviors = new HeaderBehaviors();
		this.triggerLoadedEvent();
	}

	loadTemplates(currentTemplate, footerTemplate) {
		// Some customer use jQuery in their footer template
		// If jQuery is not injected in the page and there is jQuery usage in the footer template,
		// we inject the zepto lib as a replacement.
		if (isJQueryNeeded(footerTemplate)) {
			var zeptoURL = 'https://cdnjs.cloudflare.com/ajax/libs/zepto/1.2.0/zepto.min.js';
			loadScript(zeptoURL, () => {
				window.jQuery = $;
				this.loadHeaderFooterTemplates(currentTemplate);
			});
		} else {
			this.loadHeaderFooterTemplates(currentTemplate);
		}
	}

	setTranslations() {
		const base = getConfigs('translations') || {};
		const legacy = getConfigs('legacyTranslations') || {};
		const translations = Object.assign({}, legacy, base);
		setConfigs({ translations });
		return translations;
	}

	clearVersionCache() {
		// if version diverge or you switch context (appwise/appdirect/appinsight)
		// clear cache to remove the risk of cache conflicts
		if (getConfigs('version') !== getJSONCachedItem('header', 'version') ||
			getConfigs('sub') !== getJSONCachedItem('header', 'sub')) {
			this.clearStorage();
		}
	}

	clearStorage() {
		clearJSONCachedItems();
	}

	setCachedTemplates() {
		var templates = { ...this.templates };
		templates.cssHeader = this.currentParsedCSS;
		templates.css = this.bodyCSS;
		setJSONCachedItem('header', 'templates', templates);
	}

	setCachedItems() {
		setJSONCachedItem('header', 'version', getConfigs('version'));
		setJSONCachedItem('header', 'flags', getConfigs('flags'));
		setJSONCachedItem('header', 'sub', getConfigs('sub'));
		setJSONCachedItem('header', 'locale', getConfigs('locale'));
		setJSONCachedItem('header', 'myapps', getConfigs('myapps'));
		setJSONCachedItem('header', 'bootstrap', getConfigs('bootstrap'));
		setJSONCachedItem('header', 'isCustomTemplate', getConfigs('isCustomTemplate'));
		setJSONCachedItem('header', 'addonStoreConfiguration', getConfigs('addonStoreConfiguration'));
		setJSONCachedItem('header', 'translations', getConfigs('translations'));
		setJSONCachedItem('header', 'globalStyles', getConfigs('globalStyles'));
		setJSONCachedItem('header', 'themeHeaderSettings', getConfigs('themeHeaderSettings'));
	}

	saveToCache() {
		if (getConfigs('mode') !== 'preview') {
			this.clearStorage();
			this.setCachedItems();
			this.setCachedTemplates();
		}
	}

	parseCSS(css) {
		return parseProperties(css, getConfigs('cssProperties'));
	}

	loadCSS() {
		if (getConfigs('isCustomTemplate')) {
			appendCSS(CSS_NORMALIZER, 'adUniversalHeaderNormalize', false);
		}
		if (this.bodyCSS) {
			appendCSS(this.bodyCSS, 'adUniversalbodyStyleAppdirectApp', false);
		}

		if (this.currentParsedCSS) {
			appendCSS(this.currentParsedCSS, 'adUniversalHeaderStyle', true);
		}
	}

	loadSearchWidget() {
		// Logged out search should only appear on the marketplace
		if ((this.currentTemplateType === 'headerOut' && getConfigs('sub') === 'appdirect') || this.currentTemplateType !== 'headerOut') {
			this.searchWidget = new SearchWidget();
		}
	}

	loadNotifications() {
		this.notifications = new HeaderNotifications();
	}

	getCurrentHeaderTpl(templates = {}) {
		const { pathname } = window.location;
		if (getConfigs('isSsoLoginPage') || ssoLoginPages[pathname]) {
			this.currentTemplateType = templates.headerSso ? 'headerSso' : this.getDefaultTplType();
		} else if (getConfigs('isCheckoutPage')) {
			this.currentTemplateType = templates.headerCheckout ? 'headerCheckout' : this.getDefaultTplType();
		} else if (getConfigs('useTemplate')) {
			this.currentTemplateType = templates[getConfigs('useTemplate')] ? getConfigs('useTemplate') : this.getDefaultTplType();
		} else {
			this.currentTemplateType = this.getDefaultTplType();
		}

		return templates[this.currentTemplateType];
	}

	getDefaultTplType() {
		return getConfigs('userUUID') ? 'headerIn' : 'headerOut';
	}

	parseTpl(templates) {
		// First round of parsing replaces {{*-REACT}} tags
		// with DOM elements to mount React components.

		Object.keys(templates).forEach((key) => {
			try {
				templates[key] = parseTemplateWithReactTags(templates[key]);
				templates[key] = parseTemplateWithAssets(templates[key]);
				templates[key] = parseTemplateWithTc5kComponents(templates[key]);
			} catch (e) {
				console.error('Unable to parseTpl', e); // eslint-disable-line
			}
		});

		return templates;
	}

	loadFooter() {
		if (getConfigs('footer')) {
			new Footer().insert(this.templates);
		}
	}

	appendHtml(template) {
		var el = document.querySelector(getConfigs('header', 'renderTo'));
		if (el && template) {
			el.classList.add('ad-universalnav');
			el.innerHTML = template;
			// This is needed as we need to execute header scripts after TC5K react components are instatiated
			// There are few header templates in customizer-themes(e.g SAP) which have scripts that manipulate those components
			// e.g. remove company list if empty
			document.addEventListener('universalnav:reactcomponent:instantiated', () => {
				executeScript(el);
			});
		} else {
			console.warn('header selector undefined', getConfigs('header', 'renderTo')); // eslint-disable-line
		}
	}

	loadGlobalStyles() {
		if (getConfigs('globalStyles')) {
			addGlobalStyling(getConfigs('globalStyles'));
		}
	}

	triggerLoadedEvent() {
		document.dispatchEvent(new CustomEvent('universalnav:header:loaded', {
			detail: {
				template: this.currentTemplateType,
			},
		}));
	}
}
