import { get } from './base.utils';
import { getGlobalStylesPropValue } from './globalstyles.utils';

const GLOBAL_STYLES = 'globalStyles';
const HEADER_COMPONENTS = 'headerComponents';
const HEADER_HOME_BAR = 'HeaderHomeBar';
const HEADER_SEARCH_BAR = 'HeaderSearchBar';

// see sfb-theme-components src/constants/logo.js
export const LIGHT_LOGO = 'logoImage';
export const DARK_LOGO = 'darkLogo';
export const NO_LOGO = 'noLogo';

export const LOGO_THEME = 'logoTheme';

// see sfb-theme-components src/constants/components/header.js
export const LOGO_TARGET_TYPE = 'logoTargetType';
export const LOGO_TARGET_HOMEPAGE = 'home_page';
export const LOGO_TARGET_CUSTOM_URL = 'custom_url';
export const LOGO_TARGET_CUSTOM_URL_VALUE = 'logoTargetCustomUrl';

/*
	sample structure of themeHeaderSettings:

	{
		globalStyles
		headerComponents {
			HeaderSearchBar
			HeaderHomeBar:orderable:a34bf07d-1553-4e7d-86aa-c9ed2df75687"
		}
	}
*/

export function getHomeBarSettingsFromHeaderSettings(themeHeaderSettings = {}) {
	const headerComponents = themeHeaderSettings[HEADER_COMPONENTS] || {};
	// e.g. HeaderHomeBar:orderable:a34bf07d-1553-4e7d-86aa-c9ed2df75687"
	const headerHomeBarKey = Object.keys(headerComponents).find(headerComponent => headerComponent.includes(HEADER_HOME_BAR));
	return headerHomeBarKey && headerComponents[headerHomeBarKey];
}

export function getGlobalStylesFromHeaderSettings(themeHeaderSettings = {}) {
	return themeHeaderSettings[GLOBAL_STYLES];
}

export function getSearchBarSettingsFromHeaderSettings(themeHeaderSettings = {}) {
	return get(themeHeaderSettings, `${HEADER_COMPONENTS}.${HEADER_SEARCH_BAR}`);
}

export function getThemeLogo() {
	// default image if not set
	const themeLogoType = getGlobalStylesPropValue(LOGO_THEME);
	// if NO_LOGO or falsy, no logo returned so not rendered
	return getGlobalStylesPropValue(themeLogoType, 'value.url');
}

export function getThemeLogoTargetUrl() {
	const themeLogoTargetType = getGlobalStylesPropValue(LOGO_TARGET_TYPE);

	return (themeLogoTargetType === LOGO_TARGET_CUSTOM_URL) ?
		getGlobalStylesPropValue(LOGO_TARGET_CUSTOM_URL_VALUE) :
		'/';
}
