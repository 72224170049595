import 'whatwg-fetch';
import { getConfigs, setConfigs } from './store.conf';
import { getCredentialsHeader } from '../utils/base.utils';
import { convertToBcp47Locale } from '../utils/locale';

const getTranslations = () => {
	const { marketUrlApi, partner, locale } = getConfigs();
	const bcp47Locale = convertToBcp47Locale(locale);
	const apiUrl = `${marketUrlApi}/api/v1/translation/${partner}/${bcp47Locale}?namespace=universal-header`;
	return fetch(apiUrl, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store' }).then(resp => resp.ok ? resp.json() : undefined);
};

export default function initTranslations() {
	const bootstrapI18n = window.I18N || {};
	return new Promise((resolve) => {
		getTranslations().then((translations = {}) => {
			const strings = { ...bootstrapI18n, ...translations };
			setConfigs({ translations: strings });
			resolve();
		})
		.catch(() => {
			setConfigs({ translations: bootstrapI18n }); // fallback to monolith translations
			resolve();
		});
	});
}
