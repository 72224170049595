import React from 'react';
import PropTypes from 'prop-types';
import CartAlert, { alertTypes } from './CartAlert';
import { ProductIcon, AddonIcon } from './Icons';

const renderAddons = addons => (
	addons.map((addon, index) => (
		<div className="cart-tooltip-addon-container" key={`${addon.addonName}_${index}`}>
			<a href={`${addon.addonUrl}`} className="product-addon">
				<div className="cart-tooltip-addon">
					<div className="addon-icon">
						{addon.iconSrc ?
							<img className="icon-img" alt="stock" height="32" width="32" src={addon.iconSrc} /> :
							<AddonIcon />
						}
					</div>
					<div className="addon-information">
						<span className="addon-information-title">{addon.addonName}</span>
					</div>
				</div>
			</a>
		</div>
	))
);

const getTooltipProductContainerClass = (isDomain) => {
	if (isDomain) {
		return 'cart-tooltip-product-container-domain';
	}
	return 'cart-tooltip-product-container';
};


const renderProducts = products => (
	products.map((product, index) => {
		const { productData, productName, addons, isDomain } = product;
		const { subHeader1, subHeader2, productUrl, iconSrc } = productData;
		return (
			<div className="cart-product" key={`${productName}_${index}`}>
				{index > 0 && <div className="product-tooltip-divider" />}
				<div className={getTooltipProductContainerClass(isDomain)}>
					<a href={productUrl} className="product-link-container">
						<div className="cart-tooltip-product">
							<div className="product-icon">
								{iconSrc ?
									<img className="icon-img" alt="stock" height="32" width="32" src={iconSrc} /> :
									<ProductIcon />
								}
							</div>
							<div className="product-information">
								<span className="product-information-title">{productName}</span>
								<span className="product-information-subtext1">{subHeader1}</span>
								{subHeader2 ?
									<span className="product-information-subtext2">{subHeader2}</span> : null}
							</div>
						</div>
					</a>
				</div>
				{addons && addons.length && renderAddons(addons)}
			</div>
		);
	})
);

const renderNoCartItemsUI = emptyCartLabel => (
	<div className="cart-empty">
		<div className="cart-empty-label">{emptyCartLabel}</div>
	</div>
);

const renderLoadSpinner = closeCartHandler => (
	<div className="cart-header">
		<div className="cart-loader-container">
			<div className="cart-loader" />
		</div>
		<a className="cart-close-icon" onClick={closeCartHandler}>
			<i className="ad-icon-close-medium" />
		</a>
	</div>
);

const handleAlertMessages = (alertType, i18n) => {
	const { errorMessage, alreadyInCartMessage } = i18n;
	if (alertType === alertTypes.ALREADY_INCART) {
		return alreadyInCartMessage;
	}
	return errorMessage;
};

const CartTooltip = ({
	isCTA,
	alertType,
	isCartDataReady,
	tooltipData,
	count,
	marketUrl,
	clearCacheFn,
	closeCartHandler,
}) => {
	const { items, i18n } = tooltipData;
	const { addToCartTitle, cartTitle, emptyLabel, emptyMessage, ctaLabel, itemsLabel } = i18n;
	return (
		<div className="cart-tooltip" style={{ display: 'none' }}>
			<div className="arrow_box" />
			{!isCartDataReady ?
				renderLoadSpinner(closeCartHandler) :
				<div>
					<div className="cart-header">
						{isCTA ?
							<div className="added-to-cart-title">
								<div className="added-to-cart-icon">
									<i className="ad-icon-success" />
								</div>
								{addToCartTitle}
							</div> :
							<div className="cart-title">
								{cartTitle}
							</div>}
						<a className="cart-close-icon" onClick={closeCartHandler}>
							<i className="ad-icon-close-medium" />
						</a>
					</div>
					<div className="cart-header-divider" />
					<div className="cart-content">
						{alertType ?
							<CartAlert
								status={alertType}
								messageString={handleAlertMessages(alertType, i18n)}
							/> :
							null}
						{items && items.length ?
							renderProducts(items) :
							renderNoCartItemsUI(emptyLabel, emptyMessage)}
					</div>
					{count > 0 ?
						<div className="cart-action-container">
							<div className="cart-action">
								<div className="cart-button-container">
									<a href={`${marketUrl}/checkout/cart`} className="cart-button" aria-label="view-cart" onClick={clearCacheFn}>
										{count === 1 ?
											<span>{ctaLabel}</span> :
											<span>{ctaLabel} {`(${count} ${itemsLabel})`}</span>
										}
									</a>
								</div>
							</div>
						</div>
						: null}
				</div>
			}
		</div>
	);
};

CartTooltip.propTypes = {
	isCTA: PropTypes.bool.isRequired,
	alertType: PropTypes.oneOf(['error', 'alreadyInCart']),
	isCartDataReady: PropTypes.bool.isRequired,
	tooltipData: PropTypes.shape({
		items: PropTypes.arrayOf(PropTypes.shape({
			productName: PropTypes.string.isRequired,
			isDomain: PropTypes.bool,
			productData: PropTypes.shape({
				subHeader1: PropTypes.string,
				subHeader2: PropTypes.string,
				iconSrc: PropTypes.string,
				productUrl: PropTypes.string,
			}),
			addons: PropTypes.arrayOf(PropTypes.shape({
				addonName: PropTypes.string,
				iconSrc: PropTypes.string,
				addonUrl: PropTypes.string,
			})),
		})
		),
		i18n: PropTypes.shape({
			cartTitle: PropTypes.string.isRequired,
			addToCartTitle: PropTypes.string.isRequired,
			ctaLabel: PropTypes.string.isRequired,
			itemsLabel: PropTypes.string.isRequired,
			emptyLabel: PropTypes.string.isRequired,
			emptyMessage: PropTypes.string.isRequired,
			alreadyInCartMessage: PropTypes.string.isRequired,
		}),
	}),
	count: PropTypes.number.isRequired,
	marketUrl: PropTypes.string.isRequired,
	clearCacheFn: PropTypes.func.isRequired,
	closeCartHandler: PropTypes.func.isRequired,
};

CartTooltip.defaultProps = {
	alertType: null,
	tooltipData: {
		items: [
			{
				isDomain: false,
				productData: [{
					subHeader1: '',
					subHeader2: '',
					iconSrc: '',
					productUrl: '',
				}],
				addons: [{
					addonName: '',
					iconSrc: '',
					addonUrl: '',
				}],
			},
		],
	},
};

export default CartTooltip;
