// shader constants based on the design in MP-34606
// to match the home bar styles in sfb-theme-components HeaderHomeBar.scss
const SHADER_STEP_HOVER = 17;
const SHADER_STEP_NAV_ITEM_ALPHA_HEX = 30;
const SHADER_STEP_ACTIVE = 9;

function limitRgbRange(num) {
	if (num > 255)	{ return 255; }
	if (num < 0)	{ return 0; }
	return num;
}

/**
 * Convert hex to number
 * If not a hex, return 0
 * If outside of range, return 0 or 255
 *
 * @param {*} hex with `0x` prefix, e.g. `0xA`
 * @returns {Number} range 0-255
 */
function hexToNumber(hex) {
	const num = Number(hex);

	if (isNaN(num)) {
		return 0;
	}
	if (num > 255) {
		return 255;
	}
	if (num < 0) {
		return 0;
	}

	return num;
}

function numberToHex(num) {
	const hex = (num || 0).toString(16);

	return hex.length === 1 ? `0${hex}` : hex;
}

/**
 * Verify if hex
 * 3-char hex currently not accepted, like #fff
 *
 * @param {String} 6-char hex, e.g. #ffffff
 * @returns {Boolean}
 */
function isHex(string = '') {
	return string && string[0] === '#' && string.length === 7;
}

/**
 * Convert hex to rgb object
 * Non-hex strings return a default { r: 0, g: 0, b: 0 }
 *
 * @param {String} string, e.g. #ffffff
 * @returns {Object} e.g. { r: 255, g: 255, b: 255 }
 */
export function hexToRgb(string) {
	if (isHex(string)) {
		const r = `0x${string[1]}${string[2]}`;
		const g = `0x${string[3]}${string[4]}`;
		const b = `0x${string[5]}${string[6]}`;

		return {
			r: hexToNumber(r),
			g: hexToNumber(g),
			b: hexToNumber(b),
		};
	}

	return { r: 0, g: 0, b: 0 };
}

/**
 * Convert rgb object to hex
 *
 * @param {Object} rgb, e.g. { r: 255, g: 255, b: 255 }
 * @returns {String} hex. e.g. #ffffff
 */
export function rgbToHex(rgb) {
	let _rgb = rgb;
	if (!rgb || typeof rgb === 'string') {
		_rgb = { r: 0, g: 0, b: 0 };
	}

	const { r, g, b } = _rgb;

	return `#${numberToHex(r)}${numberToHex(g)}${numberToHex(b)}`;
}

/**
 * Brightness value: 0 black, 255 white
 * http://24ways.org/2010/calculating-color-contrast
 *
 * @param {*} colorString
 * @returns
 */
function lightness(hex) {
	const { r, g, b } = hexToRgb(hex);

	return ((r * 299) + (g * 587) + (b * 114)) / 1000;
}

export function isLight(hex) {
	return lightness(hex) >= 128;
}

export function isDark(hex) {
	return !isLight(hex);
}

/**
 * Simple lighten/darken aka tint/shade functions
 * This just adds or subtracts amount from individual r,g,b values
 * Based on https://css-tricks.com/snippets/javascript/lighten-darken-color/
 *
 * @param {String} hex
 * @param {Number} amount, can be + or -
 * @returns String - tint or shade depending on amount
 */
function lightenDarken(hex, amount = 0) {
	const { r, g, b } = hexToRgb(hex);

	const rgb = {
		r: limitRgbRange(r + amount),
		g: limitRgbRange(g + amount),
		b: limitRgbRange(b + amount),
	};

	return rgbToHex(rgb);
}

export function lighten(hex, amount) {
	return lightenDarken(hex, amount);
}

export function darken(hex, amount) {
	return lightenDarken(hex, -amount);
}

export function addAlpha(hex, alphaHex) {
	return isHex(hex) ? `${hex}${alphaHex}` : `#000000${alphaHex}`;
}

/**
 * Generate item bg hover color based on container bg color
 * Lighten/tint if it's dark, darken/shade if it's light
 * Lighten and darken functions are simple +/- on r,g,b values
 *
 * @param {String} containerBgColor e.g. #2d2a27 rgb(219, 202, 224)
 * @returns e.g. #3e3b38 rgb(62,59,56)
 */
export function generateItemBackgroundHoverColor(containerBgColor) {
	let color;
	if (isDark(containerBgColor)) {
		color = lighten(containerBgColor, SHADER_STEP_HOVER);
	} else {
		color = darken(containerBgColor, SHADER_STEP_HOVER);
	}
	return color;
}

/**
 * Generate item bg active color based on container bg color
 * Lighten/tint if it's lighten, darken/shade if it's dark
 * Lighten and darken functions are simple +/- on r,g,b values
 *
 * @param {String} containerBgColor e.g. #dbcae0 rgb(219, 202, 224)
 * @returns e.g. #e4d3e9 rgb(228, 211, 233)
 */
export function generateItemBackgroundActiveColor(containerBgColor) {
	let color;
	if (isDark(containerBgColor)) {
		color = darken(containerBgColor, SHADER_STEP_ACTIVE);
	} else {
		color = lighten(containerBgColor, SHADER_STEP_ACTIVE);
	}
	return color;
}

/**
 * Generate dropdown item bg hover color based on primary color
 * Add alpha channel to hex to produce a tint
 *
 * @param {*} primaryColor e.g. #015575
 * @returns tint color e.g. #01557530
 */
export function generateDropdownItemBackgroundHoverColor(primaryColor) {
	return addAlpha(primaryColor, SHADER_STEP_NAV_ITEM_ALPHA_HEX);
}
