import 'whatwg-fetch';
import headerCssTpl from '../widgets/header/templates/css.txt';
import headerIn from '../widgets/header/templates/headerIn.html';
import headerOut from '../widgets/header/templates/headerOut.html';
import headerSso from '../widgets/header/templates/headerSso.html';
import footer from '../widgets/footer/templates/footer.html';
import headerCheckout from '../widgets/header/templates/headerCheckout.html';
import { getConfigs, setConfigs } from './store.conf';
import { getCredentialsHeader, isUniversal, getPreviewVersion } from '../utils/base.utils';
import { convertToBcp47Locale } from '../utils/locale';

export function getDefaultTemplates() {
	const cssHeader = headerCssTpl;
	return { cssHeader, headerIn, headerOut, headerCheckout, headerSso, footer };
}

function useDefaultTemplate(resolve) {
	setConfigs({ templates: getDefaultTemplates() });
	setConfigs({ isCustomTemplate: false });
	if (getConfigs('sub') !== 'appdirect') {
		// get translations
		getTemplates(resolve, false); // eslint-disable-line
	} else {
		resolve(getDefaultTemplates());
	}
}

function shouldSetCustomTemplates(data = {}, setTemplates) {
	return data.headerIn && setTemplates;
}

function getTemplates(resolve, setTemplates = true) {
	var configs = getConfigs();
	const bcp47Locale = convertToBcp47Locale(configs.locale);
	var url = `${configs.marketUrlApi}/api/channel/v1/styles/${configs.partner}/templates/${configs.mode}?locale=${bcp47Locale}${getPreviewVersion()}`;
	return fetch(url, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store' }).then(resp => resp.json()).then((data) => {
		setConfigs({ legacyTranslations: data.translations || {} });
		setConfigs({ assets: data.assets || {} });
		if (shouldSetCustomTemplates(data, setTemplates)) {
			setConfigs({ isCustomTemplate: true });
			if (isUniversal()) {
				data.cssHeader = `${headerCssTpl} ${data.cssHeader}`;
			}
			setConfigs({ templates: {
				css: data.css,
				cssHeader: data.cssHeader,
				headerIn: data.headerIn,
				headerOut: data.headerOut,
				headerSso: data.headerSso,
				headerCheckout: data.headerCheckout,
				footer: data.footer,
			} });

			resolve(data);
		// if there is no tc5k theme
		} else if (setTemplates) {
			useDefaultTemplate(resolve);
		} else {
			resolve();
		}
	});
}

export function setupTemplates() {
	var configs = getConfigs();
	return new Promise((resolve) => {
		// Used mainly by Storefront Builder
		if (getConfigs('defaultTemplates')) {
			setConfigs(getConfigs('defaultTemplates'), 'templates');
			resolve(getConfigs('defaultTemplates'));
		} else if (getConfigs('static') || getConfigs('forceDefaultTemplates')) {
			useDefaultTemplate(resolve);
		// When on a sub, user must enable option to get tc5k themes in channel settings
		} else if (configs.sub !== 'appdirect' && !configs.useUniversalThemeOnSub) {
			useDefaultTemplate(resolve);
		// if bootstrap data does not contain templates
		} else if (configs.storefrontBuilderEnabled || configs.mode === 'preview' || !Object.keys(configs.templates).length || !configs.cache) {
			getTemplates(resolve);
		// bootstrap data or cache
		} else if (configs.templates && Object.keys(configs.templates).length) {
			setConfigs({ isCustomTemplate: true });
			if (isUniversal() && (!configs.templates.cssHeader || configs.templatesFromBootData)) {
				setConfigs({ cssHeader: headerCssTpl + ' ' + (configs.templates.cssHeader || '') }, 'templates'); // eslint-disable-line
			}
			resolve(configs.templates);
		} else {
			useDefaultTemplate(resolve);
		}
	});
}
