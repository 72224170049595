import Cookies from 'js-cookie';
import { getUrlParameter } from '../utils/base.utils';

const THEME_ID_PARAM = 'AppDirect-Builder-Theme-Id';
const VERSION_ID_PARAM = 'AppDirect-Builder-Version-Id';

const DEV_PARAM = 'dev';
const CMS = 'CMS';
const CHANNEL = 'CHANNEL';
const CUSTOMIZE = 'CUSTOMIZE';
const PRODUCT_PREVIEW_DATA = 'AppDirect-Product-Preview';
const portals = [CMS, CHANNEL, CUSTOMIZE];

export function getWebsiteConfigs() {
	const buildPreviewData = {
		themeId: Cookies.get(THEME_ID_PARAM) || getUrlParameter(THEME_ID_PARAM),
		versionId: Cookies.get(VERSION_ID_PARAM) || getUrlParameter(VERSION_ID_PARAM),
	};
	const isThemeBuilderPreview = Cookies.get('AppDirect-Builder-Preview-HeartBeat') === 'true' && typeof buildPreviewData.themeId !== 'undefined';
	const isUIeditorPreview = !!getUrlParameter(THEME_ID_PARAM);
	const isProductPreview = portals.includes(getUrlParameter(DEV_PARAM)) || Cookies.get(PRODUCT_PREVIEW_DATA);
	const isPreviewMode = !isProductPreview && (isThemeBuilderPreview || isUIeditorPreview);

	var defaults = {
		version: '2.7.15', // update this to clear cache for version bump
		userUUID: undefined,
		token: undefined,
		cache: true,
		static: false,
		forceAdLogo: false,
		logoImage: undefined,
		logoWidth: undefined,
		staticData: {
			CHANNEL_SETTINGS: {},
			currentUser: {},
			myapps: {},
		},
		header: {
			links: {},
		},
		companyInfo: {},
		assets: {},
		CHANNEL_SETTINGS: {},
		capabilities: ['CUSTOMER_APPLICATION_MANAGEMENT'],
		bootstrap: {},
		flags: {},
		absolutePath: false,
		currentUser: {},
		marketUrl: 'https://marketplace.appdirect.com',
		channel: 'AppDirect',
		partner: 'APPDIRECT',
		forceDefaultTemplates: false,
		sub: 'appdirect',
		forceUseProperties: false,
		isDev: false,
		mode: isPreviewMode ? 'preview' : 'published',
		previewBannerOnPreview: !isUIeditorPreview,
		builderPreviewData: isPreviewMode ? buildPreviewData : {},
		useUniversalThemeOnSub: false,
		isMarketplace: false,
		widgets: [],
		templates: {},
		translations: {},
		legacyTranslations: {},
		CurrentUser: {},
		CompanyInfo: {},
		myapps: {},
		reactComponents: {
			icons: {},
			general: {},
		},
		onChangeCompanyContext: () => {},
	};

	return Object.assign({}, defaults);
}
