import { generateItemBackgroundHoverColor,
		generateItemBackgroundActiveColor,
		generateDropdownItemBackgroundHoverColor } from './color.utils';

const NAV_ITEM_FONT_SIZE_OFFSET_VALUES = {
	small: '-4px',
	medium: '-2px',
	large: '0px',
};

export const themeStylesToUnavStylesKeyMapping = {
	// home bar
	homeBarBackgroundColor: 'headerNavBg',
	homeBarBorderColor: 'headerNavBorderColor',
	homeBarTextColor: 'headerNavFontColor',
	homeBarTextHoverColor: 'headerNavFontHoverColor',
	homeBarTextActiveColor: 'headerNavFontSelectedColor',
	homeBarPadding: 'headerNavPadding',
	// global styles
	bodyText: 'headerNavDropdownFontColor',
};

export const themeStylesToUnavStyleParsers = {
	homeBarTextSize: size => size ? [{
		unavKey: 'headerNavItemFontSizeOffset',
		unavValue: NAV_ITEM_FONT_SIZE_OFFSET_VALUES[size],
	}] : [],
	homeBarBackgroundColor: (containerBgColor) => {
		if (!containerBgColor) {
			return [];
		}

		const itemBgHoverColor = generateItemBackgroundHoverColor(containerBgColor);
		const itemBgActiveColor = generateItemBackgroundActiveColor(containerBgColor);

		return [{
			unavKey: 'headerNavItemBgHover',
			unavValue: itemBgHoverColor,
		}, {
			unavKey: 'headerNavItemBgSelected',
			unavValue: itemBgActiveColor,
		}];
	},
	primaryColor: (primaryColor) => {
		if (!primaryColor) {
			return [];
		}

		const dropdownItemBackgroundHoverColor = generateDropdownItemBackgroundHoverColor(primaryColor);

		return [{
			unavKey: 'headerNavDropdownBgHover',
			unavValue: dropdownItemBackgroundHoverColor,
		}];
	},
};
