import React from 'react';
import PropTypes from 'prop-types';
import { ErrorIcon, SuccessIcon } from './Icons';

export const alertTypes = {
    GENERIC_ERROR: 'error',
    ALREADY_INCART: 'alreadyInCart',
};

const CartAlert = ({
    status,
    messageString,
}) => (
        <div className="cart-alert">
            {status === alertTypes.GENERIC_ERROR &&
                <div className="alert-error-wrapper">
                    <div className="alert-error-container">
                        <div className="alert-icon-container">
                            <ErrorIcon />
                        </div>
                        <span className="alert-error-message">{messageString}</span>
                    </div>
                </div>
            }
            {status === alertTypes.ALREADY_INCART &&
                <div className="alert-wrapper">
                    <div className="alert-container">
                        <div className="alert-icon-container">
                            <SuccessIcon />
                        </div>
                        <span className="alert-message">{messageString}</span>
                    </div>
                </div>
            }
        </div>
    );

CartAlert.propTypes = {
    status: PropTypes.oneOf(['error', 'alreadyInCart']),
    messageString: PropTypes.string.isRequired,
};

export default CartAlert;
