import React from 'react';
import PropTypes from 'prop-types';

const IconUserImage = (props) => {
	const getInitials = (string) => {
		const names = string.split(' ');
		const initials = [names[0].substring(0, 1).toUpperCase()];
		if (names.length > 1) {
			initials.push(names[names.length - 1].substring(0, 1).toUpperCase());
		}
		return initials.join('');
	};
	const handleDisplayAvatar = ({ isVisible, userImg, name }) => {
		if (!isVisible) {
			return null;
		}
		if (userImg) {
			return <img className="ad-img-profile-pic" src={userImg} />;
		}
		const avatarInitials = name === '' ? '' : getInitials(name);
		return (<div className="avatar_Container">
			<span className="avatar_Icon">{avatarInitials}</span>
		</div>);
	};
	return handleDisplayAvatar(props);
};

IconUserImage.propTypes = {
	isVisible: PropTypes.bool,
	userImg: PropTypes.string,
	name: PropTypes.string,
};

IconUserImage.defaultProps = {
	name: '',
};

export default IconUserImage;
