import { getConfigs } from '../../confs/store.conf';
/* eslint-disable */
export default function getDefaultCSSProps() {
	const partner = getConfigs('partner');
	const useAppDirectLogo = partner === 'APPDIRECT' || getConfigs('forceAdLogo');
	const defaultLogoUrl = useAppDirectLogo ?
		'/wicket/resource/com.appdirect.wicket.resources.Resource/spa/themes/appdirectapp/images/logo_white_2x.png'
		:
		'/wicket/resource/com.appdirect.wicket.resources.Resource/spa/themes/acme/images/acme_logo.png';
	const defaultLogoWidth = useAppDirectLogo ? '31px' : '60px';
	const customChannelSettingsLogo = getConfigs('CHANNEL_SETTINGS').customLogo;
	return {
		headerNavBg: '#2d2a27',
		headerNavBorderColor: '#242424',
		headerNavItemBgHover: '#3e3b37',
		headerNavItemBgSelected: '#24221e',
		headerNavFontColor: '#999',
		headerNavFontHoverColor: '#d2d5d9',
		headerNavFontSelectedColor: '#fff',
		headerNavTooltipBg: '#2d2a27',
		headerNavTooltipFontColor: '#fff',
		headerNavDropdownFontColor: '#575757',
		headerNavDropdownBg: '#fff',
		headerNavDropdownBorderColor: '#eaeaea',
		headerNavDropdownBorderColorHover: '#007b99',
		headerNavDropdownBgHover: '#009abf',
		headerNavDropdownFontColorHover: '#fff',
		headerNavDropdownMarginRight: '0',
		customChannelSettingsLogo,
		logoImage: getConfigs('logoImage') || getConfigs('marketUrl') + defaultLogoUrl,
		logoWidth: getConfigs('logoWidth') || defaultLogoWidth
	};
}
