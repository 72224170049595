import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

class MobileBanner extends Component {
	redirectToMobileWebsite(evt) {
		evt.preventDefault();
		Cookies.remove('AppDirect-Desktop-Version', {path: '/'});
		window.location.reload();
	}

	render() {
		return (
			<div className="adb-global_alert adb-global_alert__info" style={{minWidth:'1032px'}}>
				<div className="adb-global_alert--content" style={{textAlign:'center'}}>
					<a
						style={{textDecoration:'underline'}}
						className="ad-mobile-banner-desktop"
						onClick={this.redirectToMobileWebsite.bind(this)}
					>
						{this.props.label}
					</a>
				</div>
			</div>
		);
	}
}

MobileBanner.propTypes = {
	label: PropTypes.string.isRequired,
};

export default MobileBanner;
