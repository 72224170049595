import ReactDOM from 'react-dom';
import React from 'react';
import { l } from '../i18n/l';
import { getConfigs } from '../confs/store.conf';
import { getAppWiseApp, getAppInsightsApp } from './conf.utils';
import urlUtils from './url.utils';
import dropDownUtils from './dropdown.utils';
import { getGlobalStylesPropValue } from './globalstyles.utils';


// Render MyApps only if MP is NOT listingOnly
const hasMyApps = () => {
	const capabilities = getConfigs('capabilities');
	return capabilities.includes('CUSTOMER_APPLICATION_MANAGEMENT');
};

// only hide if explicitly hidden in theme, all other cases show
export const isCartVisible = () => {
	const themeDisplayCart = getGlobalStylesPropValue('displayCart');
	return themeDisplayCart !== false;
};

/**
 * Rules dictating when to show a component,
 * returning false will replace the the tag for an empty string
 */
const showTag = {
	'ICON-HOME-COMPONENT': () => getAppWiseApp(),
	'ICON-APPWISE-COMPONENT': () => getAppWiseApp(),
	'ICON-APPINSIGHT-COMPONENT': () => getAppInsightsApp(),
	'MYAPPS-DROPDOWN-COMPONENT': () => hasMyApps(),
	'CART-DROPDOWN-COMPONENT': () => isCartVisible(),
};

const getTag = (id, tag) => (showTag[id] && showTag[id]()) || !showTag[id] ? tag : '<span class="ad-hidden"></span>';

const tagsToReactMountEl = {
	'MPLOGO-COMPONENT': '<li class="unav-mp-logo ad-component_list-item ad-uniheader__logo-container"></li>',
	'MPLOGO-COMPONENT-HOME': '<li class="unav-mp-logo redirect-home ad-component_list-item ad-uniheader__logo-container"></li>',
	'MYAPPS-DROPDOWN-COMPONENT': '<li class="ad-universalnav-icons ad-component_dropdown ad-component__myapps js-mouseover--trigger"></li>',
	'CART-DROPDOWN-COMPONENT': '<li class="ad-universalnav-icons ad-component__cart"></li>',
	'ICON-HOME-COMPONENT': '<li class="unav-icon-home ad-universalnav-icons"></li>',
	'ICON-APPWISE-COMPONENT': '<li class="unav-icon-appwise ad-universalnav-icons"></li>',
	'MOBILE-MENU': '<li class="unav-icon-mobile ad-universalnav-icons"></li>',
	'ICON-APPINSIGHT-COMPONENT': '<li class="unav-icon-appinsight ad-universalnav-icons"></li>',
	'ICON-MARKETPLACE-COMPONENT': '<li class="unav-icon-marketplace ad-universalnav-icons"></li>',
	// Following are the tc5k migrated components
	ACCOUNT_LINK: '<a class="component-account-link" href="/account/home"></a>',
	CHANNEL_LINK: '<a class="component-channel-link"></a>',
	COMPANY_LINK: '<a class="component-company-link"></a>',
	CORPORATE_LINK: '<a class="component-corporate-link"></a>',
	DEVELOPER_LINK: '<a class="component-developer-link"></a>',
	HOME_LINK: '<a class="component-home-link"></a>',
	INSIGHTS_LINK: '<a class="component-insights-link"></a>',
	LOGIN_LINK: '<a class="component-login-link"></a>',
	LOGOUT_LINK: '<a class="component-logout-link"></a>',
	// MOBILE_SWITCH_LINK: '<a class="component-mobile-switch-link"></a>',
	MYAPPS_LINK: '<a class="component-myapps-link"></a>',
	PROFILE_LINK: '<a class="component-profile-link"></a>',
	RESELLER_LINK: '<a class="component-reseller-link"></a>',
	INTERNAL_REPORTS_LINK: '<a class="component-reports-link"></a>',
	SETTINGS_LINK: '<a class="component-settings-link"></a>',
	SIGNUP_LINK: '<a class="component-signup-link"></a>',
	SUBSCRIPTION_LINK: '<a class="component-subscription-link"></a>',
	SUPERUSER_LINK: '<a class="component-superuser-link"></a>',
	ADDONS_LINK: '<a class="component-addons-link"></a>',
	CONNECTORS_LINK: '<a class="component-connectors-link"></a>',
	ACCOUNT_LI: '<li class="component-account-list-item"></li>',
	CHANNEL_LI: '<li class="component-channel-list-item"></li>',
	CORPORATE_LI: '<li class="component-corporate-list-item"></li>',
	DEVELOPER_LI: '<li class="component-developer-list-item"></li>',
	RESELLER_LI: '<li class="component-reseller-list-item"></li>',
	SUPERUSER_LI: '<li class="component-superuser-list-item"></li>',
	ADDONS_LI: '<li class="component-addons-list-item"></li>',
	CONNECTORS_LI: '<li class="component-connectors-list-item"></li>',
	HOME_LI: '<li class="component-home-list-item"></li>',
	LOGIN_LI: '<li class="component-login-list-item"></li>',
	LOGOUT_LI: '<li class="component-logout-list-item"></li>',
	MYAPPS_LI: '<li class="component-myapps-list-item"></li>',
	SIGNUP_LI: '<li class="component-signup-list-item"></li>',
	SUBSCRIPTION_LI: '<li class="component-subscription-list-item"></li>',
	COMPANY_LI: '<li class="component-company-list-item"></li>',
	PROFILE_LI: '<li class="component-profile-list-item"></li>',
	INTERNAL_REPORTS_LI: '<li class="component-reports-list-item"></li>',
	SETTINGS_LI: '<li class="component-settings-list-item"></li>',
	// MOBILE_SWITCH_LI: '<li class="component-mobile-switch-list-item"></li>',
	SEARCH_FORM: '<li class="component-search-form"></li>',
	SEARCH_BOX: '<li class="component-search-box"></li>',
	ICON_HOME: '<li class="unav-icon-home ad-universalnav-icons"></li>',
	ICON_APPWISE: '<li class="unav-icon-appwise ad-universalnav-icons"></li>',
	ICON_APPINSIGHT: '<li class="unav-icon-appinsight ad-universalnav-icons"></li>',
	ICON_MARKETPLACE: '<li class="unav-icon-marketplace ad-universalnav-icons"></li>',
	ICON_USER_IMG: '<img class="component-icon-user-img" />',
	LANGUAGE_LINKS: '<ul class="component-language-links"></ul>',
	USER_LIST: '<ul class="component-user-list"></ul>',
	MANAGE_LIST: '<ul class="component-manage-list"></ul>',
	MANAGE_LINKS: '<ul class="component-manage-list-old"></ul>', // Deprecated component
	COMPANY_LIST: '<ul class="component-company-list"></ul>',
	LANGUAGE_DROPDOWN: '<li class="component-language-dropdown"></li>',
	MANAGE_DROPDOWN: '<li class="component-manage-dropdown"></li>',
	USER_DROPDOWN: '<li class="component-user-dropdown"></li>',
	COMPANY_DROPDOWN: '<li class="component-company-dropdown"></li>',
	COMPANIES_DROPDOWN: '<div class="component-companies-dropdown"></div>', // Deprecated component
	CURRENCY_DROPDOWN: '<li class="component-currency-dropdown"></li>',
	/* MYAPPS_DROPDOWN is already implemented with different name MYAPPS-DROPDOWN-COMPONENT */
	MYAPPS_DROPDOWN: '<li class="ad-universalnav-icons ad-component_dropdown ad-component__myapps js-mouseover--trigger"></li>',
	LANGUAGE_SELECT: '<select class="component-language-select"></select>',
	WRAPPED_LANGUAGE_SELECT: '<div class="component-language-select-wrapped"></div>',
};

const reactTagRegexp = new RegExp(/\{\{(\S+-COMPONENT)\}\}/g);

const tc5kComponentRegex = new RegExp(/\{\{([\s\S]+?)\}\}/g);

const assetTagRegexp = new RegExp(/\{\{[\s]?r\((.+)\}\}/g);

const tc5kMigratedBasicComponents = {
	ACCOUNT_HREF: urlUtils.getAccountUrl,
	CHANNEL_HREF: urlUtils.getChannelUrl,
	COMPANY_HREF: urlUtils.getCompanyUrl,
	COPYRIGHT_YEAR: urlUtils.getCopyrightYear,
	COMPANY_NAME: urlUtils.getCompanyName,
	CORPORATE_HREF: urlUtils.getCorporateUrl,
	CURRENT_LANGUAGE_TAG: urlUtils.getCurrentLanguageTag,
	CURRENT_LANGUAGE: urlUtils.getCurrentLanguage,
	DEVELOPER_HREF: urlUtils.getDeveloperUrl,
	HOME_HREF: urlUtils.getHomeUrl,
	LOGIN_HREF: urlUtils.getLogInUrl,
	LOGOUT_HREF: urlUtils.getLogOutUrl,
	MARKETPLACE_HREF: urlUtils.getMarketplaceUrl,
	// MOBILE_SWITCH_HREF: urlUtils.getMobileSwitchUrl,
	MYAPPS_HREF: urlUtils.getMyAppsUrl,
	PRIVACY_POLICY_URL: urlUtils.getPrivacyPolicyUrl,
	PROFILE_HREF: urlUtils.getProfileUrl,
	RESELLER_HREF: urlUtils.getResellerUrl,
	INTERNAL_REPORTS_HREF: urlUtils.getInternalReportsUrl,
	SETTINGS_HREF: urlUtils.getSettingsUrl,
	SIGNUP_HREF: urlUtils.getSignUpUrl,
	SUBSCRIPTION_HREF: urlUtils.getSubscriptionUrl,
	SUPERUSER_HREF: urlUtils.getSuperUserUrl,
	SUPPORT_CONTACT_URL: urlUtils.getSupportContactUrl,
	SUPPORT_EMAIL: urlUtils.getSupportEmail,
	SUPPORT_PHONE: urlUtils.getSupportPhone,
	SUPPORT_URL: urlUtils.getSupportUrl,
	TERMS_URL: urlUtils.getTermsUrl,
	USER_EMAIL: urlUtils.getUserEmail,
	USER_FIRSTNAME: urlUtils.getUserFirstName,
	USER_IMG: urlUtils.getUserImg,
	USER_NAME: urlUtils.getUserName,
	JS_DROPDOWN_CLICK: dropDownUtils.replaceJsDropDownClick,
};

const getAssetUrl = (assetType, assetKey, fallback) => {
	const { assets: { [assetType]: assets } } = getConfigs();
	if (assets && assets[assetKey] && assets[assetKey].url) {
		return assets[assetKey].url;
	}
	return fallback;
};

export const getPartialsData = () => Object.assign(tagsToReactMountEl, tc5kMigratedBasicComponents);

/**
 * Parses *-REACT tags replacing them for a react mount dom element
 * @param {String} tpl
 */
export const parseTemplateWithReactTags = (tpl = "") => tpl.replace(reactTagRegexp, (s) => {
	const key = s.slice(2, -2);
	// return the tag value or original key if no match
	return getTag(key, tagsToReactMountEl[key]) || s;
});

/**
 * Parses TC5k components replacing them  with string, or a react mount dom element
 * @param {String} tpl
 */
export const parseTemplateWithTc5kComponents = (tpl = "") => tpl.replace(tc5kComponentRegex, (s) => {
	const key = s.slice(2, -2).trim();
	if (tc5kMigratedBasicComponents && tc5kMigratedBasicComponents[key]) {
		return tc5kMigratedBasicComponents[key]();
	} else if (tagsToReactMountEl && tagsToReactMountEl[key]) {
		// return the tag value or original key if no match
		return getTag(key, tagsToReactMountEl[key]) || s;
	}
	// if the key is the property key in internalization tab
	return l(key) || key;
});

export const parseTemplateWithAssets = (tpl = "") => tpl.replace(assetTagRegexp, (s) => {
	const assetPathArray = s.replace(/\s/g, '').split('/');
	if (assetPathArray.length !== 4) {
		return s;
	}
	const assetType = assetPathArray[2];
	// find ending quote index by substracting one from ) to account for single and double quotes.
	const indexOfEndingQuote = assetPathArray[3].indexOf(')') - 1;
	const assetKey = assetPathArray[3].slice(0, indexOfEndingQuote);
	return getAssetUrl(assetType, assetKey, s);
});

export function getAppLink(id, defaultId, myapps) {
	return myapps.find(app => id === app.applicationId || defaultId === app.applicationId).launchUrl;
}

// render nodes in place of container
// example: <header><div class="containerComponent"><Component /></div></header>
// would become:
// <header><Component /></header>
// This removes DOM boilerplate nodes.
export function renderInPlaceOfNode(reactElement, targetNode) {
	var container = targetNode.parentNode;
	var prevSibs = [];
	var nextSibs = [];
	var sibs = prevSibs;
	var child = container.firstChild;

	while (child) {
		if (child === targetNode) {
			sibs = nextSibs;
		} else {
			sibs.push(child);
		}
		var next = child.nextSibling;
		container.removeChild(child);
		child = next;
	}
	function PortalContainer() {
		// createPortal does not exist in preact
		// Fiber crash on re-render when not used in conjoinction of portals
		var renderFunction = ReactDOM.createPortal ? ReactDOM.createPortal : ReactDOM.render;
		return (<div>{renderFunction(reactElement, container)}</div>);
	}

	var containerComponent = document.createElement('div');
	ReactDOM.render(<PortalContainer />, containerComponent);

	var rendered = container.firstChild;

	if (prevSibs.length > 0) {
		prevSibs.forEach((sib) => {
			container.insertBefore(sib, rendered);
		});
	}

	if (nextSibs.length > 0) {
		nextSibs.forEach((sib) => {
			container.appendChild(sib);
		});
	}
}

export const renderReactComponent = (cssSelector, component) => {
	if (!component) {
		return;
	}
	[...document.querySelectorAll(cssSelector)].forEach((node) => {
		renderInPlaceOfNode(component, node);
	});
};
