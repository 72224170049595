import { getConfigs } from '../confs/store.conf';
import languages from '../i18n/languages';
import { getChannelSetting, getUserInfo, userHasRole, getCurrentLocale, getFlag } from './conf.utils';
import { convertToBcp47Locale } from '../utils/locale';
import { FLAGS } from '../confs/flags.conf';

// Dummy function definition as currently there is no implementaion provided for this
// Need to discuss on this
const switchMobileLinkFunction = () => '';

function getValidUrl(targetUrl) {
    try {
        return new URL(targetUrl);
    } catch (err) {
        return false;
    }
}

function isSameDomain(targetHostName) {
    try {
        const currentDomain = window.location.hostname.replace('www.', '');
        const targetDomain = targetHostName.replace('www.', '');
        return currentDomain === targetDomain;
    } catch {
        return false;
    }
}

function getStorefrontUrl(locale) {
	const { CHANNEL_SETTINGS } = getConfigs();
    const storefrontPathName = window.location.pathname + window.location.search;
    const newLocale = [locale];
	const pathnameList = storefrontPathName.split('/');
	const currentUrlLocale = pathnameList?.[1];
	if(currentUrlLocale && CHANNEL_SETTINGS?.localeTags.includes(currentUrlLocale)) {
		const pathNames = pathnameList.slice(2);
		return `/${newLocale.concat(pathNames).join('/')}`;
	} else {
		return window.location.href;
	}
}

function navigateTo(targetUrl) {
    const validUrl = getValidUrl(targetUrl);
    const targetHostname = validUrl ? validUrl.hostname : null;
    if (validUrl && !isSameDomain(targetHostname)) {
        window.open(targetUrl);
    } else {
        window.location.href = targetUrl;
    }
}

export default {
	getHomeUrl: () => '/home',

	getLogInUrl: () => '/login',

	getLogOutUrl: () => '/logout',

	getMyAppsUrl: () => '/myapps',

	getSignUpUrl: () => '/signup',

	getListingUrl: () => '/listing',

	// There was no implementation for this in ad-theme-customizer-client
	getMarketplaceUrl: () => '',

	getPrivacyPolicyUrl: () => getChannelSetting('privacyPolicyUrl'),

	getSupportUrl: () => getChannelSetting('supportUrl'),

	getSupportEmail: () => getChannelSetting('supportEmail'),

	getCompanyName: () => getChannelSetting('companyName'),

	getSupportPhone: () => getChannelSetting('supportPhone'),

	getSupportContactUrl: () => getChannelSetting('supportContactUrl'),

	getTermsUrl: () => getChannelSetting('termsUrl'),

	getCopyrightYear: () => `\u00A9 ${new Date(Date.now()).getFullYear()}`,

	getCurrentLanguage: () => languages[convertToBcp47Locale(getCurrentLocale())] || '',

	getCurrentLanguageTag: () => convertToBcp47Locale(getCurrentLocale()),

	getSubscriptionUrl: () => '/subscription',

	getInternalReportsUrl: () => '/reports',

	getSettingsUrl: () => '/settings',

	getProfileUrl: () => {
		const userId = getUserInfo('user_id');
		return userId ? `/profile/${userId}` : '';
	},

	getCompanyUrl: () => {
		const companyId = getUserInfo('company_id');
		return companyId ? `/companies/${companyId}` : '';
	},

	getCategoryUrl: ({ id }) => getConfigs('isSFBenabled') ? `/listing?cat=${id}` : `/home#!category/${id}`,

	getUserEmail: () => getUserInfo('email'),

	getUserName: () => getUserInfo('name'),

	getUserFirstName: () => getUserInfo('given_name'),

	getUserImg: () => `${getConfigs('bootstrap', 'IMG_BASE')}/profilePics/${getUserInfo('profilePic')}`,

	// Currently there is no usage of this component(verified in customizer-themes, monolith, ad-mobile-storefront repo),
	// in ad-theme-customizer-client it was expecting switchMobileLinkFunction as dependecy,
	// but nowhere, it is passed as dependecy, for now using dummy function declared at the start of the module
	// Need to discuss on this
	getMobileSwitchUrl: () => `javascript:${switchMobileLinkFunction || ''}`,

	getAccountUrl: () => '/account/home',

	getCorporateUrl: () => '/corporate/home',
	
	getDevelopersHome: () => '/vendor/admin',

	getDeveloperUrl: () => '/cms/home',

	getResellerUrl: () => '/reseller',

	getInsightUrl: app => app ? app.launchUrl : '',

	getChannelUrl: () => {
		const roles = getUserInfo('roles');
		if (!roles) {
			return '';
		}
		const isChannelProductSupportOnly = !userHasRole('CHANNEL_ADMIN') && userHasRole('CHANNEL_PRODUCT_SUPPORT');
		const isReportsChanneAdminOnly = !userHasRole('CHANNEL_ADMIN') && userHasRole('REPORTS_CHANNEL_ADMIN');
		const CHANNEL_PROGRESS_PAGES = 6; // keep in sync with monolith
		const guideProgresses = getChannelSetting('guideProgresses') || {};
		const isChannelGuideInProgress = getChannelSetting('walkMeEnabled') && guideProgresses.CHANNEL < CHANNEL_PROGRESS_PAGES;

		const isReplaceOldDashboardEnabled =
			(!!getChannelSetting('selfserveMarketplace') && !!getFlag(FLAGS.REPLACE_MP_MANAGER_DASHBOARD)) ||
			(!getChannelSetting('selfserveMarketplace') && !!getFlag(FLAGS.REPLACE_MP_MANAGER_DASHBOARD_NON_SELFSERVE));

		if (isChannelProductSupportOnly && !getChannelSetting('baas')) {
			return '/channel/products';
		} else if (isReportsChanneAdminOnly) {
			return '/channel/reports/history';
		} else if (userHasRole('CHANNEL_ADMIN') && isChannelGuideInProgress) {
			return '/channel/guide';
		} else if (userHasRole('CHANNEL_ADMIN') && isReplaceOldDashboardEnabled) {
			return '/admin';
		}
		return '/channel/marketplace';
	},

	getSuperUserUrl: () => {
		const roles = getUserInfo('roles');
		if (!roles) {
			return '';
		}
		return userHasRole('SUPERUSER') ? '/superuser/dashboard' : '/superuser/support';
	},

	removeWicketCache: (url = '') => (url.replace(/(\?\d*&)|(\?\d+$)/g, '?')),

	openURLInNewTab: (url) => {
		const link = document.createElement('a');
		link.target = '_blank';
		link.href = url;
		link.rel = 'noopener noreferrer';
		document.body.appendChild(link);
		link.click();
		link.parentNode.removeChild(link);
	},
	navigateTo,
	getStorefrontUrl
};
