import React from 'react';
import { l } from '../i18n/l';
import { getConfigs } from '../confs/store.conf';
import { renderReactComponent } from './template.utils';
import urlUtils from './url.utils';
import {
	getChannelSetting,
	getCompanyInfo,
	userHasRole,
	userHasOneOfRoles,
	channelHasCapability,
	hideMySubscriptionLink,
	getFlag,
} from './conf.utils';
import { clearJSONCachedItems } from './cache.utils';
import Link from '../components/Link';
import { FLAGS } from '../confs/flags.conf';

const onLogout = () => {
	clearJSONCachedItems();
	window.location.assign(urlUtils.getLogOutUrl());
};

const getAccountLink = () => React.createElement(Link, {
	id: 'account', // For MyAppsController.js
	href: urlUtils.getAccountUrl(),
	isVisible: !getCompanyInfo('onlyAdminsCanBuy') || userHasRole('BILLING_ADMIN'),
	label: l('Account'),
	className: 'account-link admin-item', // For MyAppsController.js,
});

const getChannelLink = () => React.createElement(Link, {
	href: urlUtils.getChannelUrl(),
	isVisible: (channelHasCapability('CHANNEL_MANAGEMENT') && userHasOneOfRoles(['CHANNEL_ADMIN', 'CHANNEL_SUPPORT', 'CHANNEL_PRODUCT_SUPPORT', 'SALES_SUPPORT']))
		|| userHasRole('REPORTS_CHANNEL_ADMIN'),
	label: l('channel'),
});

const getCorporateLink = () => React.createElement(Link, {
	href: urlUtils.getCorporateUrl(),
	isVisible: userHasRole('CORPORATE_ADMIN') || (userHasRole('NETWORK_CATALOG_MANAGER') && channelHasCapability('NETWORK_CATALOG_MANAGEMENT')),
	label: l('corporate'),
});

const getDeveloperLink = () => React.createElement(Link, {
	id: 'developer', // For MyAppsController.js
	href: getFlag(FLAGS.PROGRAM_MANAGER_ENABLED) ? urlUtils.getDevelopersHome() : urlUtils.getDeveloperUrl(),
	isVisible: channelHasCapability('DEVELOPER') && getChannelSetting('cmsEnabled') && getCompanyInfo('vendor') && userHasRole('DEVELOPER'),
	label: getFlag(FLAGS.PROGRAM_MANAGER_ENABLED) ? l('developers') : userHasRole('CHANNEL_ADMIN') && getCompanyInfo('vendor') ? l('BillingAndDistribution') : l('Developer'),
});

const getResellerLink = () => React.createElement(Link, {
	href: urlUtils.getResellerUrl(),
	isVisible: userHasOneOfRoles(['RESELLER_MANAGER', 'RESELLER', 'REFERRAL']),
	label: l('reseller'),
});

const getSuperUserLink = () => React.createElement(Link, {
	href: urlUtils.getSuperUserUrl(),
	isVisible: userHasOneOfRoles(['SUPERUSER', 'SUPER_SUPPORT']),
	label: l('appdirectadmin'),
	className: 'superuser-link',
});

const getHomeLink = () => React.createElement(Link, {
	id: 'shop',
	href: urlUtils.getHomeUrl(),
	isVisible: true,
	label: l('Marketplace'),
});

const getLoginLink = () => React.createElement(Link, {
	href: urlUtils.getLogInUrl(),
	isVisible: true,
	label: l('sign.in'),
});

const getLogoutLink = () => React.createElement(Link, {
	isVisible: true,
	label: l('logout'),
	onClickHandler: () => {
		if (getConfigs('onBeforeLogout')) {
			Promise.resolve(getConfigs('onBeforeLogout')()).finally(() => {
				onLogout();
			});
		} else {
			onLogout();
		}
	},
});

const getMyAppsLink = () => React.createElement(Link, {
	id: 'myapps', // For MyAppsController.js
	href: urlUtils.getMyAppsUrl(),
	isVisible: channelHasCapability('CUSTOMER_APPLICATION_MANAGEMENT'),
	label: l('My.Apps'),
});

const getSignUpLink = () => React.createElement(Link, {
	href: urlUtils.getSignUpUrl(),
	isVisible: !getChannelSetting('hideSignupLinks'),
	label: l('sign.up'),
});

const getSubscriptionLink = () => React.createElement(Link, {
	href: urlUtils.getSubscriptionUrl(),
	isVisible: !!getChannelSetting('accountEdition') && userHasRole('CHANNEL_ADMIN') && !hideMySubscriptionLink(),
	label: l('My.Subscription'),
});

const getCompanyLink = () => React.createElement(Link, {
	href: urlUtils.getCompanyUrl(),
	isVisible: getChannelSetting('showProfiles'),
	label: l('My.Company'),
});

const getProfileLink = () => React.createElement(Link, {
	href: urlUtils.getProfileUrl(),
	isVisible: getChannelSetting('showProfiles'),
	label: l('My.Profile'),
});

const getInternalReportsLink = () => React.createElement(Link, {
	href: urlUtils.getInternalReportsUrl(),
	isVisible: userHasRole('INTERNAL_REPORTS_USER'),
	label: l('my.reports'),
});

const getSettingsLink = () => React.createElement(Link, {
	href: urlUtils.getSettingsUrl(),
	isVisible: !getChannelSetting('identityProvider') && !getChannelSetting('enableMySettingsUnderMyProfile'),
	label: l('my.settings'),
});

// Mobile Switch Link expects this function in depencies passed to ad-theme-customizer-client
// But currently there is instance found where it is passed as depenedency (verified in customizer-themes, monolith, ad-mobile-storefront repo)
// This is the dummy function defintion for it
const shouldDisplayGoToMobileLink = () => false;

const getMobileSwitchLink = () => React.createElement(Link, {
	href: urlUtils.getMobileSwitchUrl(),
	isVisible: getConfigs('isAdMobileWebApp') || (typeof shouldDisplayGoToMobileLink === 'function' && shouldDisplayGoToMobileLink()),
	label: getConfigs('isAdMobileWebApp') ? l('Go.to.Desktop.Site') : l('Go.to.Mobile.Site'),
});

const getAddonsLink = () => {
	const addonStoreConf = getConfigs('addonStoreConfiguration') || {};

	return React.createElement(Link, {
		href: addonStoreConf.addonStoreUrl,
		label: l('Addons'),
		isVisible: addonStoreConf.showAddonStoreLink,
		className: 'addons-link',
		target: '_blank',
	});
};

const getConnectorsLink = () => {
	const addonStoreConf = getConfigs('addonStoreConfiguration') || {};

	return React.createElement(Link, {
		href: addonStoreConf.integrationsUrl,
		label: l('integrations'),
		isVisible: addonStoreConf.showIntegrationsLink,
		className: 'connectors-link',
		target: '_self',
	});
};

const renderAllLinks = () => {
	// render manage links
	renderReactComponent('.component-account-link', getAccountLink());
	renderReactComponent('.component-channel-link', getChannelLink());
	renderReactComponent('.component-corporate-link', getCorporateLink());
	renderReactComponent('.component-developer-link', getDeveloperLink());
	renderReactComponent('.component-reseller-link', getResellerLink());
	renderReactComponent('.component-superuser-link', getSuperUserLink());
	renderReactComponent('.component-addons-link', getAddonsLink());
	renderReactComponent('.component-connectors-link', getConnectorsLink());

	// render marketplace links
	renderReactComponent('.component-home-link', getHomeLink());
	renderReactComponent('.component-login-link', getLoginLink());
	renderReactComponent('.component-logout-link', getLogoutLink());
	renderReactComponent('.component-myapps-link', getMyAppsLink());
	renderReactComponent('.component-signup-link', getSignUpLink());

	// render user links
	renderReactComponent('.component-subscription-link', getSubscriptionLink());
	renderReactComponent('.component-company-link', getCompanyLink());
	renderReactComponent('.component-profile-link', getProfileLink());
	renderReactComponent('.component-reports-link', getInternalReportsLink());
	renderReactComponent('.component-settings-link', getSettingsLink());
	renderReactComponent('.component-mobile-switch-link', getMobileSwitchLink());
};

export default {
	getAccountLink,
	getChannelLink,
	getCorporateLink,
	getDeveloperLink,
	getResellerLink,
	getSuperUserLink,
	getHomeLink,
	getLoginLink,
	getLogoutLink,
	getMyAppsLink,
	getSignUpLink,
	getSubscriptionLink,
	getCompanyLink,
	getProfileLink,
	getSettingsLink,
	getInternalReportsLink,
	getMobileSwitchLink,
	getAddonsLink,
	getConnectorsLink,
	renderAllLinks,
};
