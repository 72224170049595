/**
 * This is deprecated component. Its implementation is different from other dropdowns
 */
import React from 'react';
import PropTypes from 'prop-types';
import { changeCompanyContext } from '../utils/event.utils';

const CompaniesDropDown = (props) => {
	const { companies, userId, isVisible, currentCompany } = props;
	return (
		isVisible
			? <div className='companies-dropdown'>
				<a className='adb-context_menu--trigger'>{currentCompany}</a>
				<ul className='adb-context_menu--menu'>
					{
						companies && companies.length > 0
							? companies.map(company => (
								<li key={company.uuid}>
									<a id={company.uuid} onClick={() => changeCompanyContext(userId, company.uuid)}>
										{company.name}
									</a>
								</li>
							))
							: null
					}
				</ul>
			</div>
			: null
	);
};

CompaniesDropDown.propTypes = {
	userId: PropTypes.string.isRequired,
	currentCompany: PropTypes.string.isRequired,
	companies: PropTypes.arrayOf(PropTypes.object),
	isVisible: PropTypes.bool.isRequired,
};

export default CompaniesDropDown;
