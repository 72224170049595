import React from 'react';
import PropTypes from 'prop-types';

const ListItem = (props) => {
	const { linkComponent, className, e2eId } = props;
	const classNameAttribute = className ? { className: `ad-component_list-item ${className}` } : {};
	const dataTestIdAttribute = e2eId ? { 'data-testid': e2eId } : {};
	return (
		linkComponent && linkComponent.props && linkComponent.props.isVisible
			? <li {...classNameAttribute} {...dataTestIdAttribute}> {linkComponent} </li>
			: null
	);
};
ListItem.propTypes = {
	className: PropTypes.string,
	linkComponent: PropTypes.element,
	e2eId: PropTypes.string,
};
export default ListItem;
