const loadScript = (url, onDone, useHead = false) => {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.onload = () => {
		if (onDone) {
			onDone();
		}
	};
	script.src = url;
	if (useHead) {
		document.head.appendChild(script);
	} else {
		document.body.appendChild(script);
	}
};

export default loadScript;
