import { getConfigs } from '../confs/store.conf';
import defaultEnglish from './default.en';

/**
 * Localization
 *
 * @param phrase {String} corresponds to a *.properties entry.
 *
 * Additional argument(s) can be provided that correspond to interpolation.
 *
 * For positional interpolation, pass values as additional arguments.  For example:
 * window.I18N['a.translation.key'] = 'Test for {0} and {1}.';
 * l('a.translation.key', 'foo', 'bar') === 'Test for foo and bar.';
 *
 * For property interpolation, pass an object mapping to variables.  For example:
 * window.I18N['a.translation.key'] = 'Test for {var1} and {var2}.';
 * l('a.translation.key', {var1: 'foo', var2: 'bar'}) === 'Test for foo and bar.';
 *
 * @returns {String}
 */
export function l(phrase, ...args) {
	let translation = (getConfigs('translations') && getConfigs('translations')[phrase]) || defaultEnglish[phrase] || phrase;
	const isObjectLike = value => typeof value === 'object' && value !== null;
	const isObject = value => isObjectLike(value) && Object.prototype.toString.call(value) === '[object Object]';

	if (isObject(args[0])) {
		Object.entries(args[0]).forEach(([key, value]) => {
			translation = translation.replace(`\${${key}}`, value);
		});
	} else {
		for (let i = 0; i < args.length; i += 1) {
			const regEx = new RegExp(`\\{${i}\\}`, 'gi');
			const replacerFn = () => args[i];
			translation = translation.replace(regEx, replacerFn);
		}
	}

	return translation;
}
