import 'whatwg-fetch';
import { getConfigs, setConfigs } from './store.conf';
import { getCredentialsHeader } from '../utils/base.utils';
import { convertToBcp47Locale } from '../utils/locale';
import { getHomeBarSettingsFromHeaderSettings, getGlobalStylesFromHeaderSettings, getSearchBarSettingsFromHeaderSettings } from '../utils/theme.utils';

const getTheme = () => {
	const { marketUrlApi, locale = 'en-US' } = getConfigs();
	const bcp47Locale = convertToBcp47Locale(locale);
	const apiUrl = `${marketUrlApi}/api/theme/v1/publishedVersion/settings/header?locale=${bcp47Locale}`;

	return fetch(apiUrl, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store' })
		.then(resp => resp.ok ? resp.json() : undefined);
};

const setGlobalStylesConfig = (themeHeaderSettings) => {
	const globalStyles = getGlobalStylesFromHeaderSettings(themeHeaderSettings);
	const homeBarSettings = getHomeBarSettingsFromHeaderSettings(themeHeaderSettings);
	const searchBarSettings = getSearchBarSettingsFromHeaderSettings(themeHeaderSettings);

	setConfigs({ globalStyles: {
		...globalStyles,
		...homeBarSettings,
		...searchBarSettings,
	} });
};

export default function setupTheme() {
	return new Promise((resolve) => {
		const cachedThemeHeaderSettings = getConfigs('themeHeaderSettings');

		// {} is not a valid theme setting
		if (cachedThemeHeaderSettings && Object.keys(cachedThemeHeaderSettings).length) {
			setGlobalStylesConfig(cachedThemeHeaderSettings);
			resolve();
		} else {
			getTheme().then((themeHeaderSettings = {}) => {
				setConfigs({ themeHeaderSettings });
				setGlobalStylesConfig(themeHeaderSettings);
				resolve();
			}).catch(() => {
				resolve();
			});
		}
	});
}
