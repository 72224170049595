import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SelectItem = (props) => (
	<option className={classNames('ad-component_select--option', props.className)}
		id={props.id}
		value={props.value}
		selected={props.selected}>
		{props.label}
	</option>
);

SelectItem.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	selected: PropTypes.string,
	label: PropTypes.string
};

export default SelectItem;
