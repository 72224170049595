import { attachEvent } from '../../../utils/base.utils';
import { getConfigs } from '../../../confs/store.conf';
import { clearJSONCachedItems } from '../../../utils/cache.utils';

export function setCompanyDropdown() {
	var companyDropdown = document.querySelector('.ad-company-dropdown');
	var hasCompanyDrodown = companyDropdown && !!companyDropdown.innerHTML.trim();
	if (hasCompanyDrodown) {
		var currentLabelEl = document.querySelector('.ad-company-dropdown .ad-component_dropdown--trigger');
		var currentLabel = currentLabelEl.innerHTML;
		currentLabelEl.classList.add('ad-hide');
		document.querySelector('.ad-company-label').innerHTML = currentLabel;
		document.querySelector('.ad-company-container').classList.add('ad-show');
	}
}

export function changeCompanyContext() {
	attachEvent('click', '.ad-universalnav .ad-component_list--company-list .ad-component--link', (evt, activeElement) => {
		clearJSONCachedItems();
		var companyId = activeElement.attributes['attr-value'].value;
		fetch(`${getConfigs('marketUrl')}/api/account/v2/users/${getConfigs('userUUID')}/memberships/${companyId}/current`, { method: 'PUT', mode: 'cors', credentials: 'include', cache: 'no-store' }).then(resp => resp.text()).then(() => {
			getConfigs('onChangeCompanyContext')();
			window.location = `${getConfigs('marketUrl')}/home`;
		});
	});
}
