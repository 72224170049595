import React from 'react';
import { l } from '../i18n/l';
import { renderReactComponent } from './template.utils';
import { getConfigs } from '../confs/store.conf';
import ListUtils from './list.utils';
import urlUtils from './url.utils';
import DropDown from '../components/DropDown';
import CompaniesDropDown from '../components/CompaniesDropDown';

const visible = true;

const getManageDropDown = () => (
	<DropDown
		isVisible={visible}
		className="manage-dropdown"
		linkClassName="manage-link"
		label={l('Manage')}
		listComponent={ListUtils.getManageList()}
	/>
);

const getLanguageDropDown = () => (
	<DropDown
		isVisible={visible}
		className="language-dropdown"
		label={l(urlUtils.getCurrentLanguage())}
		listComponent={ListUtils.getLanguageList()}
	/>
);

const getProfileDropDown = () => (
	<DropDown
		isVisible={visible}
		className="user-dropdown"
		label={l(urlUtils.getUserName())}
		listComponent={ListUtils.getUserList()}
	/>
);

const getCurrencyDropDown = () => (
	<DropDown
		isVisible={visible}
		className="currency-dropdown"
		label={l(getConfigs('bootstrap', 'defaultCurrency'))}
		listComponent={ListUtils.getCurrencyList()}
	/>
);

const getCompanyDropDown = () => {
	const user = getConfigs('bootstrap', 'UserInfo');
	const memberships = getConfigs('bootstrap', 'memberships') || [];
	const currentMembership = memberships.find(membership => membership.company && membership.company.uuid === user.company_id) || {};
	const currentCompany = (currentMembership.company && currentMembership.company.name) || null;
	return (
		<DropDown
			isVisible={user && memberships.length > 1}
			className="company-dropdown"
			label={l(currentCompany)}
			listComponent={ListUtils.getCompanyList()}
			ellipsizeLabel
		/>
	);
};

const getCompaniesDropDown = () => {
	const user = getConfigs('bootstrap', 'UserInfo') || {};
	const memberships = getConfigs('bootstrap', 'memberships') || [];
	const currentMembership = memberships.find(membership => membership.company && membership.company.uuid === user.company_id) || {};
	const currentCompany = (currentMembership.company && currentMembership.company.name) || null;
	const companies = memberships.map(membership => membership.company);
	return (
		<CompaniesDropDown
			isVisible={currentCompany && companies.length > 1}
			userId={user.user_id}
			currentCompany={l(currentCompany)}
			companies={companies}
		/>
	);
};

const toggleDropDown = () => {
	document.querySelector('body').addEventListener('click', (event) => {
		const activeDropDowns = document.querySelector('.ad-component_dropdown .ad-component_dropdown--is-active');
		if (event.target && event.target.classList.contains('ad-component_dropdown') && event.target.classList.contains('ad-component_dropdown--trigger')) {
			if (event.target.classList.contains('ad-component_dropdown--is-active')) {
				// mark clicked dropdown as inactive as it was already active we need to hide it
				event.target.classList.remove('ad-component_dropdown--is-active');
			} else {
				// mark other active dropdowns if any as inactive and clicked one as active
				if (activeDropDowns) {
					activeDropDowns.classList.remove('ad-component_dropdown--is-active');
				}
				event.target.classList.add('ad-component_dropdown--is-active');
			}
		} else if (activeDropDowns) {
			// hide all active dropDown if there are any, when clicked elsewhere in the body
			activeDropDowns.classList.remove('ad-component_dropdown--is-active');
		}
	});
};

// This is for JS_DROPDOWN_CLICK component
const replaceJsDropDownClick = () => `<style>
	.ad-component_dropdown > .ad-component_list {
		display: none;
	}
	.ad-component_dropdown .ad-component_dropdown--is-active ~ .ad-component_list {
		display: block;
	}
	</style>
	<script>
		(${toggleDropDown.toString()})();
	</script>`;

const renderAllDropDowns = () => {
	renderReactComponent('.component-manage-dropdown', getManageDropDown());
	renderReactComponent('.component-language-dropdown', getLanguageDropDown());
	renderReactComponent('.component-user-dropdown', getProfileDropDown());
	renderReactComponent('.component-currency-dropdown', getCurrencyDropDown());
	renderReactComponent('.component-company-dropdown', getCompanyDropDown());
	renderReactComponent('.component-companies-dropdown', getCompaniesDropDown());
};

export default {
	getManageDropDown,
	getProfileDropDown,
	getLanguageDropDown,
	getCompanyDropDown,
	getCompaniesDropDown,
	getCurrencyDropDown,
	renderAllDropDowns,
	replaceJsDropDownClick,
};
