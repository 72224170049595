import 'whatwg-fetch';
import Cookies from 'js-cookie';
import { getConfigs, setConfigs } from './store.conf';
import { getAuthHeader, getCredentialsHeader } from '../utils/base.utils';
import { getMembershipsEndpoint } from '../utils/endpoints.utils';
import { getJSONCachedItem } from '../utils/cache.utils';

const returnMockData = Cookies.get('mockUserLoggedInHeader');

function getMemberships() {
	const apiURL = getMembershipsEndpoint(returnMockData);
	return fetch(apiURL, { mode: 'cors', credentials: getCredentialsHeader(), cache: 'no-store', headers: getAuthHeader() }).then(resp => resp.ok ? resp.json() : { content: [] });
}

export function setupMemberships() {
	const cachedMemberships = getJSONCachedItem('header', 'memberships');
	return new Promise((resolve) => {
		if (cachedMemberships) {
			setConfigs({ memberships: cachedMemberships }, 'bootstrap');
			resolve();
		} else if (getConfigs('userUUID') || returnMockData) {
			getMemberships().then((data) => {
				var memberships = data ? data.content : {};
				setConfigs({ memberships }, 'bootstrap');
				resolve(data);
			}).catch(() => resolve());
		} else {
			var memberships = getConfigs('bootstrap', 'memberships') || [];
			setConfigs({ memberships }, 'bootstrap');
			resolve({ content: [] });
		}
	});
}
