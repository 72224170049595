import { getConfigs, setConfigs } from '../../confs/store.conf';
import { setupToken } from '../../confs/token.conf';
import { setupZendesk } from '../../confs/zendesk.conf';
import { isSelfServeMarketplace } from '../../utils/conf.utils';

const ALLOWED_PARTNERS = ['APPDIRECT', 'APPSMART', 'DES'];
const MAX_SHOWN_NOTIFICATIONS = 9;

let pingInterval = 35000;

export default class HeaderNotifications {
	totalUnreadCount = ''
	totalUpdatedCount = ''
	signalsUnread = 0
	signalsHasUnread = false
	zenUnread = 0

	constructor(uuid) {
		try {
			if (this.isNotificationAllowed()) {
				setConfigs({ enableNotifications: true });
			}

			if (getConfigs('enableNotifications')) {
				this.userUUID = uuid;
				this.signalsSocketEndpoint = getConfigs('signalsSocketEndpoint');
				this.initSocketConnection();
				setupZendesk(this);
			}
		} catch (e) {
			console.warn(e); // eslint-disable-line
		}
	}

	isNotificationAllowed() {
		return getConfigs('userUUID') && (isSelfServeMarketplace() || ALLOWED_PARTNERS.includes(getConfigs('partner')) || getConfigs('theme') === 'universal');
	}

	initSocketConnection() { // eslint-disable-line
		if (window.WebSocket && this.signalsSocketEndpoint) {
			this.socketEnabled = true;
			return setupToken().then(() => {
				if (getConfigs('marketUrl')) {
					const marketUrl = getConfigs('marketUrl');
					const connectionInfo = btoa(JSON.stringify({
						token: getConfigs('token'),
						marketUrl,
					}));
					this.socket = new WebSocket(`${marketUrl.replace('http', 'ws')}/api/appwise/v1/signals/?d=${connectionInfo}`);
					this.socket.onopen = this.open.bind(this);
					this.socket.onclose = this.close.bind(this);
					this.socket.onmessage = this.update.bind(this);
				}
			});
		}
	}

	open() {
		this.socket.isAlive = true;
		this.startConnectionCheck();
	}

	close() {
		this.socket.isAlive = false;
	}

	update(message) {
		const data = message ? JSON.parse(message.data) : {};

		if (data.type === 'PING') {
			this.socket.send(JSON.stringify({ type: 'PONG' }));
			this.socket.isAlive = true;
		} else if (data.type === 'PONG') {
			this.socket.isAlive = true;
		} else if (data.type === 'MESSAGE') {
			this.triggerCustomEvent(data.payload);
			this.setCurrentNotificationValues(data.payload);
			this.updateUI();
		}
	}

	triggerCustomEvent(payload) {
		Object.keys(payload).forEach((event) => {
			document.dispatchEvent(new CustomEvent(`universalnav:signals:${event}`, {
				detail: payload[event],
			}));
		});
	}

	updateUnreadValue() {
		const total = this.zenUnread + this.signalsUnread;
		if (total === 0) {
			this.totalUnreadCount = this.signalsHasUnread ? '•' : '';
		} else if (total > MAX_SHOWN_NOTIFICATIONS) {
			this.totalUnreadCount = `${MAX_SHOWN_NOTIFICATIONS}+`;
		} else {
			this.totalUnreadCount = String(total);
		}
	}

	setZenUnread(unreadCount) {
		this.zenUnread = unreadCount;
		this.updateUnreadValue();
		this.updateUI();
	}

	setCurrentNotificationValues(payload) {
		const teamNotifications = payload['appwise-team'] ? payload['appwise-team'].self : undefined;
		if (teamNotifications) {
			this.signalsUnread = teamNotifications.unreadCount;
			this.signalsHasUnread = teamNotifications.hasUnreadMessages;
		}
		const feedsNotifications = payload['appwise-feeds']
			? Object.values(payload['appwise-feeds']).find(notification => notification.type === 'HOME')
			: undefined;
		if (feedsNotifications) {
			const { updatedResourceCount } = feedsNotifications;
			if (updatedResourceCount > 0) {
				this.totalUpdatedCount = updatedResourceCount > MAX_SHOWN_NOTIFICATIONS
					? `${MAX_SHOWN_NOTIFICATIONS}+`
					: String(updatedResourceCount);
			} else {
				this.totalUpdatedCount = '';
			}
		}
		this.updateUnreadValue();
	}

	updateUI() {
		document.dispatchEvent(new CustomEvent('universalnav:signals:count', {
			detail: {
				counts: {
					totalUnreadCount: this.totalUnreadCount,
					totalUpdatedCount: this.totalUpdatedCount,
				},
			},
		}));
	}

	startConnectionCheck() {
		if (this.connectionCheckId) {
			clearInterval(this.connectionCheckId);
			pingInterval += pingInterval;
		}

		this.connectionCheckId = setInterval(() => {
			if (!this.socket.isAlive) {
				this.socket.close();
				this.initSocketConnection();
			} else {
				this.socket.isAlive = false;
				this.socket.send(JSON.stringify({ type: 'PING' }));
			}
		}, pingInterval);
	}
}
