import React from 'react';
import PropTypes from 'prop-types';

import { getThemeLogo, getThemeLogoTargetUrl } from '../utils/theme.utils';

const logoTarget = ({ capabilities, marketUrl, userUUID, redirectHome }) => (!redirectHome && userUUID && capabilities.includes('CUSTOMER_APPLICATION_MANAGEMENT')) ? `${marketUrl}/myapps` : `${marketUrl}/home`;

const MPLogo = (props) => {
	const themeLogoImage = getThemeLogo();
	const themeLogoTargetUrl = getThemeLogoTargetUrl();

	// On Plaza theme, allow logo size to flow, but without changing the existing CSS classes
	// for backwards compatibility
	// unav _MPLogo.scss hides #plaza-theme-logo
	// Plaza _MPLogo.scss displays #plaza-theme-logo and hides unav logo
	// see getTemplates in templates.conf for the template override behavior
	return (
		<li className="ad-component_list-item ad-uniheader__logo-container">
			<a className="ad-uniheader__logo-container--link" href={logoTarget(props)}>
				<span className="ad-uniheader__logo-container--image" />
			</a>
				{
					themeLogoImage && (
						<a id="plaza-theme-logo" href={themeLogoTargetUrl} style={{ display: 'none' }}>
							<img src={themeLogoImage} alt={'logo'} />
						</a>
					)
				}
		</li>
	);
};


MPLogo.propTypes = {
	capabilities: PropTypes.arrayOf(PropTypes.string),
	marketUrl: PropTypes.string.isRequired,
	userUUID: PropTypes.string,
};

export default MPLogo;
