import React from 'react';
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import { getConfigs } from '../../confs/store.conf';
import { appendCSS } from '../../utils/base.utils';
import BuilderPreviewBanner from '../../components/PreviewBanner';
import previewCSS from './previewBanner.css.txt';
import { THEME_PREVIEW, NATIVE_PRODUCT_PREVIEW, NETWORK_PRODUCT_PREVIEW } from '../../utils/constants.utils';

export default class BuilderPreviewBannerWidget {
	load() {
		const isSFBenabled = getConfigs('isSFBenabled');
		const previewBannerOnPreview = getConfigs('previewBannerOnPreview');
		if (!previewBannerOnPreview) {
			return;
		}

		if (!this.isSessionValid()) {
			this.removeBuilderCookies();
			return;
		}
		if (this.isThemePreview() && isSFBenabled) {
			this.render(THEME_PREVIEW, '');
			return;
		}

		if (this.isNativePreview() && isSFBenabled) {
			this.render(NATIVE_PRODUCT_PREVIEW, this.getProductId());
			return;
		}

		if (this.isNetworkPreview() && isSFBenabled) {
			this.render(NETWORK_PRODUCT_PREVIEW, this.getProductId());
			return;
		}
	}

	render(previewType, productId) {
		this.setHeartbeatBeforeUnload();
		appendCSS(previewCSS, 'adUniversalPreviewBannercss', false);
		const previewBannerContainer = document.createElement('div');
		previewBannerContainer.classList.add('previewBannerContainer');
		document.body.appendChild(previewBannerContainer);
		ReactDOM.render(<BuilderPreviewBanner previewType={previewType} productId={productId} />, document.querySelector('.previewBannerContainer'));
	}

	setHeartbeatBeforeUnload() {
		function setHeartBeat() {
			const in1min = new Date(new Date().getTime() + 1 * 60 * 1000); // eslint-disable-line
			Cookies.set('AppDirect-Builder-Preview-HeartBeat', true, { expires: in1min, secure:	true });
		}
		window.addEventListener('pagehide', setHeartBeat, false);
		window.addEventListener('beforeunload', setHeartBeat);
	}

	getProductId() {
		const pageURL = window.location.href;
		if (/\/[0-9][0-9]*(\/|\?|&)/.test(pageURL)) {
			const idPathArray = (pageURL.match(/\/[0-9][0-9]*(\/|\?|&)/));
			const productId = idPathArray[0];
			const id = productId.substring(1, productId.length - 1);
			return id;
		}
		return null;
	}

	isThemePreview() {
		const sub = getConfigs('sub');
		const versionsData = Cookies.get('AppDirect-Builder-Version');
		return sub === 'appdirect' && versionsData;
	}

	isNativePreview() {
		return this.isInitialNativePreview() || this.isContinueProductPreview();
	}

	isInitialNativePreview() {
		const check = this.isDevQueryEqual('CHANNEL') || this.isDevQueryEqual('CMS');
		this.setProductCookies(check);
		return check;
	}

	isNetworkPreview() {
		return this.isInitialNetworkPreview() || this.isContinueProductPreview();
	}

	isInitialNetworkPreview() {
		const check = this.isDevQueryEqual('CUSTOMIZE');
		this.setProductCookies(check);
		return check;
	}

	isContinueProductPreview() {
		const productData = Cookies.getJSON('AppDirect-Product-Preview') || {};
		const isSameProduct = productData.productId === this.getProductId();
		if (!isSameProduct) {
			this.removeBuilderCookies();
		}
		return isSameProduct;
	}

	setProductCookies(isInitialProductPreview) {
		if (isInitialProductPreview) {
			Cookies.set('AppDirect-Builder-Preview-HeartBeat', 'true', { secure: true });
			Cookies.set('AppDirect-Product-Preview', JSON.stringify({
				productId: this.getProductId(),
				portal: this.getProductPortal(),
			}, { secure: true }));
		}
	}

	getProductPortal() {
		const portals = ['CHANNEL', 'CMS', 'CUSTOMIZE'];
		for (const portal of portals) {
			if (this.isDevQueryEqual(portal)) {
				return portal.toLowerCase();
			}
		}
		return '';
	}

	isDevQueryEqual(query) {
		const pattern = new RegExp(`[?|&]dev=${query}`, 'g');
		return pattern.test(window.location.href);
	}

	isSessionValid() {
		const hasDevQuery = /(\?|&)dev/.test(window.location.href);
		const hasProductId = /\/[0-9][0-9]*(\/|\?|&)/.test(window.location.href);
		const isProfilePreview = hasDevQuery && hasProductId;
		const isThemePreview = this.isThemePreview();
		return Cookies.get('AppDirect-Builder-Preview-HeartBeat') === 'true' || isProfilePreview || isThemePreview;
	}

	removeBuilderCookies() {
		Cookies.remove('AppDirect-Builder-Version');
		Cookies.remove('AppDirect-Builder-Theme-Id');
		Cookies.remove('AppDirect-Builder-Version-Id');
		Cookies.remove('AppDirect-Builder-Preview-Mode');
		Cookies.remove('AppDirect-Builder-Preview-HeartBeat');
		Cookies.remove('AppDirect-Product-Preview');
	}
}
