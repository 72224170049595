import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getAuthHeader, getCredentialsHeader, getXsrfToken } from '../utils/base.utils';
import urlUtils from '../utils/url.utils';

const TERMINATE_SESSION_API_URL = '/api/iam/v1/sessions/endImpersonation';

class ImpersonateBanner extends Component {
	state = {
		isSubmitted: false
	}

	terminateImpersonate = () => {
		this.setState({ isSubmitted: true });
		const headers = getAuthHeader();
		const xsrfToken = getXsrfToken();
		if (xsrfToken) {
			headers['X-XSRF-TOKEN'] = xsrfToken;
		}
		fetch(TERMINATE_SESSION_API_URL, {
			method: 'POST',
			credentials: getCredentialsHeader(),
			cache: 'no-store',
			headers,
		}).then((response) => {
			if (!response.ok) {
				this.setState({ isSubmitted: false });
				console.warn('End impersonation failed');
				return;
			}
			const originalUrl = urlUtils.removeWicketCache(sessionStorage.getItem('ImpersonationOriginalState')) || '';
			sessionStorage.removeItem('ImpersonationOriginalState');
			location.assign((originalUrl.includes('/superuser') ? '/superuser/dashboard#users' : originalUrl));
		});
	};

	render() {
		const { name, email, l } = this.props;
		const { isSubmitted } = this.state;
		return (
			<div className="ImpersonateBanner"
				style={{
					width: '100%',
					minWidth: '760px',
					height: '48px',
					position: 'relative'
				}}>
				<div
					style={{
						width: '100%',
						minWidth: '760px',
						height: '48px',
						lineHeight: '48px',
						backgroundColor: '#F7D619',
						boxShadow: '0 1px 4px 0 #474747',
						position: 'fixed',
						top: 0,
						left: 0,
						zIndex: 999
					}}>
					<div style={{
						textAlign: 'center',
						color: '#333333',
						fontSize: '14px',
						display: 'fixed'
					}}>
						{l('impersonation.header')}<b> {name}</b> ({email})
					<button onClick={this.terminateImpersonate}
							disabled={isSubmitted}
							style={{
								margin: '10px 14px 0px 0px',
								float: 'right',
								fontWeight: 'bold',
								border: '1px solid #2D2D2D',
								borderRadius: '3px',
								background: 'none',
								color: 'black',
								boxShadow: 'inset 0 1px 0 0 #606060, 0 1px 0 0 #606060',
								height: '28px',
								padding: '0px 12px',
								fontSize: '14px',
								cursor: 'pointer',
								position: 'absolute',
								right: '14px'
							}}>
							{l('impersonation.header.end.session')}
						</button>
					</div>
				</div>
			</div >
		);
	}
}

ImpersonateBanner.propTypes = {
	name: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	l: PropTypes.func.isRequired
};

export default ImpersonateBanner;
